import { Fragment, useMemo, ReactNode, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Card, Col, Collapse, CollapseProps, Divider, Flex, Row, Spin, Typography } from "antd";
import { ArrowRightOutlined, PlusOutlined } from "@ant-design/icons";

import MainPageContent from "../../components/Layout/MainPageContent";
import { getResourceTextStatus, ResourceTextStatus } from "../../utils/inventoryJob";
import { useRouterPaths } from "../../hooks/useRouterPaths";
import { useInventory } from "../../queries/entities/inventory.queries";
import { useInventoryDeliveries } from "../../queries/entities/inventoryDeliveries.query";
import { FileLocation, SelfServiceExtractor } from "../../types/entities";

import "./JobDetails.scss";

const { Text } = Typography;

interface FBReport {
  reportInfo: {
    createdAt: string;
  };

  groups: {
    all: {
      uniqueAssets: {
        totalOverall: number;
        totalActive: number;
        totalArchived: number;
      };
    };
  }[];
}

interface DCMReport {
  reportInfo: {
    createdat: string;
  };
  uniqueAssetAdsCount?: number;
}

interface DV360Report {
  reportInfo: {
    createdAt: string;
  };
  uniqueAssetAdsCount?: number;
}

interface TIKTOKReport {
  reportInfo: {
    createdAt: string;
  };
  uniqueAssetAdsCount?: number;
}

interface LINKEDINReport {
  reportInfo: {
    createdAt: string;
  };
  uniqueAssetAdsCount?: number;
}

const DetailsDivider = () => <Divider style={{ margin: "8px 0" }} />;

export const MainDivider = ({ children }: { children: ReactNode }) => <Divider orientation="left">{children}</Divider>;

export const DetailsTable = ({
  loading,
  rows,
}: {
  loading?: boolean;
  rows: { label: string; details: string | number; to?: string }[] | undefined;
}) => {
  return (
    <Card>
      {loading ? (
        <Spin />
      ) : (
        rows?.map(({ label, details, to }, i) => {
          const row = (
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <Text strong>{label}</Text>
              </Col>
              <Col span={12}>
                <Text className={`job-table-success-${details}`}>{details}</Text>
              </Col>
            </Row>
          );

          return (
            <Fragment key={label}>
              {to ? <Link to={to}>{row}</Link> : row}
              {i < rows.length - 1 && <DetailsDivider />}
            </Fragment>
          );
        })
      )}
    </Card>
  );
};

const JobDetails = () => {
  const { inventoryId } = useParams();
  const inventory = useInventory(inventoryId);
  const inventoryJobStatus = useMemo(() => getResourceTextStatus(inventory.data), [inventory.data]);

  const { jobDeliveryIndexPath } = useRouterPaths();
  const deliveries = useInventoryDeliveries(inventoryId);

  const [adStatsPending, setAdStatsPending] = useState(true);
  const [adStats, setAdStats] = useState<{
    totalOverall: number;
    totalActive?: number;
    totalArchived?: number;
    reportDate: string;
  }>();

  const inventorySignedUrl = useMemo(() => {
    return (
      inventory.data?.resourceOutput?.output?.success?.inventoryFileLocation as FileLocation | undefined
    )?.signedUrl;
  }, [inventory.data]);

  useEffect(() => {
    if (!inventorySignedUrl) {
      setAdStatsPending(false);

      return;
    }

    const fetchInventoryData = async () => {
      try {
        setAdStatsPending(true);

        const response = await fetch(inventorySignedUrl);

        if (inventory.data?.resourceType === SelfServiceExtractor.EXTFB_INVENTORY) {
          const data = await response.json() as FBReport;

          setAdStats({
            totalOverall: data?.groups?.[0]?.all?.uniqueAssets?.totalOverall ?? 0,
            totalActive: data?.groups?.[0]?.all?.uniqueAssets?.totalActive ?? 0,
            totalArchived: data?.groups?.[0]?.all?.uniqueAssets?.totalArchived ?? 0,
            reportDate: new Date(data?.reportInfo.createdAt).toDateString(),
          });
        } else if (inventory.data?.resourceType === SelfServiceExtractor.EXTDCM_INVENTORY) {
          const data = await response.json() as DCMReport;

          setAdStats({
            totalOverall: data?.uniqueAssetAdsCount ?? 0,
            reportDate: new Date(data?.reportInfo.createdat).toDateString(),
          });
        } else if (inventory.data?.resourceType === SelfServiceExtractor.EXTDV360_INVENTORY) {
          const data = await response.json() as DV360Report;

          setAdStats({
            totalOverall: data?.uniqueAssetAdsCount ?? 0,
            reportDate: new Date(data?.reportInfo.createdAt).toDateString(),
          });
        } else if (inventory.data?.resourceType === SelfServiceExtractor.EXTTIKTOK_INVENTORY) {
          const data = await response.json() as TIKTOKReport;

          setAdStats({
            totalOverall: data?.uniqueAssetAdsCount ?? 0,
            reportDate: new Date(data?.reportInfo.createdAt).toDateString(),
          });
        } else if (inventory.data?.resourceType === SelfServiceExtractor.EXTLINKEDIN_INVENTORY) {
          const data = await response.json() as LINKEDINReport;

          setAdStats({
            totalOverall: data?.uniqueAssetAdsCount ?? 0,
            reportDate: new Date(data?.reportInfo.createdAt).toDateString(),
          });
        }

        setAdStatsPending(false);
      } catch (error) {
        console.error("Error fetching inventory data:", error);

        setAdStatsPending(false);
      }
    };

    void fetchInventoryData();
  }, [inventory.data, inventorySignedUrl]);

  const collapseItems: CollapseProps["items"] = useMemo(() => {
    const items = [
      {
        key: "1",
        label: "Inventory resource",
        children: <pre>{JSON.stringify(inventory.data, null, 2)}</pre>,
      },
    ];

    if (deliveries.data?.items.length) {
      items.push({
        key: "2",
        label: "Delivery resource",
        children: <pre>{JSON.stringify(deliveries.data, null, 2)}</pre>,
      });
    }

    return items;
  }, [inventory.data, deliveries.data]);

  const inventoryTableData = useMemo(() => {
    if (!inventory.data) {
      return [];
    }

    return [
      { label: "Resource type", details: inventory.data.resourceType },
      { label: "Status", details: inventoryJobStatus },
      {
        label: "Percentage done",
        details:
          inventory.data?.resourceOutput?.percentage || inventory.data?.resourceOutput?.percentage === 0
            ? inventory.data?.resourceOutput?.percentage + "%"
            : "n/a",
      },
    ];
  }, [inventory.data, inventoryJobStatus]);

  const adCountTableData = useMemo(() => {
    if (!adStats) {
      if (adStatsPending) {
        return [];
      }

      return [{ label: "Total overall", details: "n/a" }];
    }

    const tableData = [];

    const resourceType = inventory?.data?.resourceType;

    if (resourceType === SelfServiceExtractor.EXTFB_INVENTORY) {
      tableData.push({
        label: "Total overall",
        details: adStats.totalOverall,
      });

      if (adStats.totalActive !== undefined) {
        tableData.push({
          label: "Total active",
          details: adStats.totalActive,
        });
      }

      if (adStats.totalArchived !== undefined) {
        tableData.push({
          label: "Total archived",
          details: adStats.totalArchived,
        });
      }
    } else if (
      resourceType
      && [
        SelfServiceExtractor.EXTDCM_INVENTORY,
        SelfServiceExtractor.EXTDV360_INVENTORY,
        SelfServiceExtractor.EXTTIKTOK_INVENTORY,
        SelfServiceExtractor.EXTLINKEDIN_INVENTORY,
      ].includes(resourceType)
    ) {
      tableData.push({
        label: "Total overall",
        details: adStats.totalOverall,
      });
    }

    return tableData;
  }, [adStats, adStatsPending, inventory?.data?.resourceType]);

  const deliveriesTableData = useMemo(() => {
    return deliveries.data?.items.map((delivery) => ({
      label: delivery.id,
      details: getResourceTextStatus(delivery),
      to: `${jobDeliveryIndexPath}/${delivery.id}`,
    }));
  }, [deliveries.data, jobDeliveryIndexPath]);

  if (inventory.isPending || deliveries.isPending) {
    return <Spin />;
  }

  if (!inventory.data) {
    return <div>Failed to load the job</div>;
  }

  return (
    <>
      <Typography.Title level={3}>{inventory.data?.extractorInput.jobName}</Typography.Title>

      <MainDivider>Job details</MainDivider>
      <DetailsTable rows={inventoryTableData} />

      <MainDivider>Unique asset ads {adStats?.reportDate ? `@${adStats?.reportDate}` : ""}</MainDivider>
      <DetailsTable loading={adStatsPending} rows={adCountTableData} />

      {inventorySignedUrl && (
        <Flex justify="flex-end">
          <a href={inventorySignedUrl} target="_blank" rel="noreferrer">
            Open full inventory report <ArrowRightOutlined />
          </a>
        </Flex>
      )}

      <MainDivider>Deliveries</MainDivider>
      {deliveriesTableData && deliveriesTableData.length > 0 && <DetailsTable rows={deliveriesTableData} />}

      {deliveriesTableData?.length === 0 && adStats?.totalOverall !== 0 && <Text>No deliveries yet</Text>}

      {adStats?.totalOverall === 0 && (
        <Text>{`Unfortunately, we can't start a new delivery job as there are no available ads.`}</Text>
      )}

      {adStats && adStats.totalOverall > 0 && (
        <Flex justify="flex-end">
          <Link className="job-details__action" to="add-delivery">
            <Button disabled={inventoryJobStatus !== ResourceTextStatus.success} type="primary" icon={<PlusOutlined />}>
              Start new delivery job
            </Button>
          </Link>
        </Flex>
      )}

      <MainDivider>Raw data</MainDivider>
      <Collapse items={collapseItems} />
    </>
  );
};

const JobDetailsPage = () => {
  return (
    <MainPageContent>
      <JobDetails />
    </MainPageContent>
  );
};

export default JobDetailsPage;
