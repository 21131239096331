import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

const REPORT_ID_INDEX = "reportIdIndex";
const REPORT_IDS = "reportIds";

export const useReportIdsSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { reportIdIndex, reportIds } = useMemo(() => {
    const ids = searchParams.get(REPORT_IDS)?.split(",") ?? [];

    if (!ids?.length) {
      return {
        reportIdIndex: 1,
        reportIds: [],
      };
    }

    const index = Number(searchParams.get(REPORT_ID_INDEX));

    if (isNaN(index) || index < 1 || index > ids.length) {
      return {
        reportIdIndex: 1,
        reportIds: ids,
      };
    }

    return {
      reportIdIndex: index,
      reportIds: ids,
    };
  }, [searchParams]);

  return {
    reportIdIndex,
    reportIds,

    setReportIds: (newReportIds: string[]) => {
      if (!newReportIds.length) {
        if (searchParams.has(REPORT_IDS)) {
          searchParams.delete(REPORT_IDS);
        }

        if (searchParams.has(REPORT_ID_INDEX)) {
          searchParams.delete(REPORT_ID_INDEX);
        }
      } else {
        searchParams.set(REPORT_ID_INDEX, "1");
        searchParams.set(REPORT_IDS, newReportIds.join(","));
      }

      setSearchParams(searchParams);
    },

    setReportIdIndex: (index: number) => {
      if (!searchParams.has(REPORT_IDS)) {
        return;
      }

      searchParams.set(REPORT_ID_INDEX, String(index));
      setSearchParams(searchParams);
    },
  };
};
