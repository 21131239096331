import { Button, Form, InputNumber, Select, Typography } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DeleteFilled, PlusOutlined } from "@ant-design/icons";

import { ReportKpis } from "../../../../../../pages/DashboardPage/queries/reportKPIs.query";
import { CreativeIntelligenceMetric } from "../../../../../../pages/DashboardPage/queries/utils";
import { useConfigStore } from "../../../../../../store/config/config.store";

import { FormRow, FormValues } from "./Benchmarks";
import { filterDiscardOptions, mapMetricsToDiscardMetricsOptions } from "./utils";

const { Text } = Typography;

const DiscardThresholds = ({ reportKPIs }: { reportKPIs: ReportKpis | undefined }) => {
  const form = Form.useFormInstance();
  const [prevDiscardThresholds, setPrevDiscardThresholds] = useState<string[]>([]);

  const discardAiServices = useConfigStore((state) => state.config?.discardAiServices);

  const discardOptions = useMemo(() => mapMetricsToDiscardMetricsOptions(reportKPIs), [reportKPIs]);

  const aiServiceOptions = useMemo(() => {
    return discardAiServices?.map(({ name, value }) => ({
      label: name,
      value,
    }));
  }, [discardAiServices]);

  const formDiscardThresholds = Form.useWatch<FormValues["discardThresholds"]>(["discardThresholds"], form);

  const handleAddDiscardThreshold = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const formDiscard: FormValues["discardThresholds"] = form.getFieldValue(["discardThresholds"]);

    const notUsed = discardOptions.find(
      (option) =>
        !formDiscard?.find((d) => {
          // we can't have aiService and confidence together
          if (d.metric === "aiService" && option.value === "confidence") {
            return true;
          }

          if (d.metric === "confidence" && option.value === "aiService") {
            return true;
          }

          return d.metric === option.value;
        })
    );

    if (!notUsed) {
      console.error("No more discard options");

      return;
    }

    form.setFieldsValue({
      discardThresholds: [
        ...(formDiscard ?? []),
        {
          metric: notUsed.value,
          value: notUsed.metricValue,
        },
      ],
    });
  }, [discardOptions, form]);

  const handleRemoveDiscardThreshold = useCallback(
    (index: number) => () => {
      form.setFieldsValue({
        discardThresholds: (form.getFieldValue(["discardThresholds"]) as FormValues["discardThresholds"])?.filter(
          (_, i) => i !== index
        ),
      });
    },
    [form]
  );

  // when the discard metric changes, we need to reset to the initial value since not all values are valid for all metrics
  useEffect(() => {
    if (!formDiscardThresholds || !reportKPIs) {
      return;
    }

    const currentDiscardThresholds = formDiscardThresholds.map((d) => d.metric);

    if (!prevDiscardThresholds?.length && currentDiscardThresholds.length) {
      setPrevDiscardThresholds(currentDiscardThresholds);

      return;
    }

    let changed = false;

    currentDiscardThresholds.forEach((metric, index) => {
      if (metric === prevDiscardThresholds[index] || !reportKPIs) {
        return;
      }

      changed = true;

      if (metric === "aiService") {
        form.setFieldValue(["discardThresholds", index, "value"], aiServiceOptions?.[0]?.value);

        return;
      }

      if (metric === "confidence") {
        form.setFieldValue(["discardThresholds", index, "value"], 0);

        return;
      }

      const reportMetric = reportKPIs[metric as CreativeIntelligenceMetric];

      if (reportMetric) {
        form.setFieldValue(["discardThresholds", index, "value"], reportMetric?.avg);
      }
    });

    if (changed) {
      setPrevDiscardThresholds(currentDiscardThresholds);
    }
  }, [aiServiceOptions, form, formDiscardThresholds, prevDiscardThresholds, reportKPIs]);

  return (
    <>
      <FormRow align="center" justify="space-between">
        <Text strong>Discard Metric</Text>
        <Button
          type="text"
          onClick={handleAddDiscardThreshold}
          icon={<PlusOutlined />}
          data-testid="add-discard-metric-button"
        >
          Add
        </Button>
      </FormRow>

      <Form.List name="discardThresholds">
        {(fields) => {
          return fields.map((field) => {
            const options =
              discardOptions.map((option) => ({
                label: option.label,
                value: option.value,
                disabled: filterDiscardOptions(formDiscardThresholds, field, option),
              })) ?? discardOptions;

            return (
              <FormRow key={field.key} gap="small">
                <DeleteFilled onClick={handleRemoveDiscardThreshold(field.key)} />
                <Form.Item name={[field.name, "metric"]} noStyle>
                  <Select className="discard-metric-field" options={options} />
                </Form.Item>

                {formDiscardThresholds?.[field.name]?.metric === "aiService" ? (
                  <Form.Item name={[field.name, "value"]} noStyle>
                    <Select className="discard-metric-field" options={aiServiceOptions} />
                  </Form.Item>
                ) : (
                  <Form.Item name={[field.name, "value"]} noStyle>
                    <InputNumber
                      className="discard-metric-field"
                      min={0}
                      data-testid={`input-discard-metric-${field.name}`}
                    />
                  </Form.Item>
                )}
              </FormRow>
            );
          });
        }}
      </Form.List>
    </>
  );
};

export default DiscardThresholds;
