import { useCallback, useState, useEffect } from "react";
import { Carousel, Spin } from "antd";
import type { CustomArrowProps } from "@ant-design/react-slick";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import UniqueCreativeDeliverable from "../UniqueCreative/UniqueCreativeDeliverable";
import UniqueCreativeDeliverableSummaryStats from "../UniqueCreative/UniqueCreativeDeliverableSummaryStats";

import { useKeyTakeawayInsight } from "./keyTakeaway.query";

import "./KeyTakeawayContent.scss";

const ArrowLeft = ({ currentSlide, slideCount, ...props }: CustomArrowProps) => <LeftOutlined {...props} />;
const ArrowRight = ({ currentSlide, slideCount, ...props }: CustomArrowProps) => <RightOutlined {...props} />;

interface KeyTakeawayContentProps {
  advancedSearch: string;
  notMatchQuery?: boolean;
}

const KeyTakeawayContent = ({ advancedSearch, notMatchQuery = false }: KeyTakeawayContentProps) => {
  const { data, fetchNextPage, isPending, isLoading } = useKeyTakeawayInsight(advancedSearch, notMatchQuery);
  const [initialSlide, setInitialSlide] = useState(0);

  useEffect(() => {
    if (isLoading) {
      setInitialSlide(0);
    }
  }, [isLoading]);

  const fetchMore = useCallback(
    async (indexArray: number[]) => {
      const totalItems = data?.items.length ?? 0;
      const upperBoundary = indexArray[indexArray.length - 1] + 1;

      if (totalItems <= upperBoundary) {
        await fetchNextPage();
      }

      if (!totalItems) {
        await fetchNextPage();

        return;
      }

      setInitialSlide(upperBoundary);
    },
    [fetchNextPage, data]
  );

  return (
    <Spin spinning={isPending}>
      <Carousel
        arrows
        dots={false}
        infinite={false}
        slidesToShow={4}
        slidesToScroll={4}
        lazyLoad="ondemand"
        onLazyLoad={fetchMore}
        initialSlide={initialSlide}
        className="custom-carousel"
        prevArrow={<ArrowLeft />}
        nextArrow={<ArrowRight />}
        key={isLoading ? "re-init" : "loaded"}
        responsive={[
          { breakpoint: 1600, settings: { slidesToShow: 3, slidesToScroll: 3 } },
          { breakpoint: 1200, settings: { slidesToShow: 2, slidesToScroll: 2 } },
          { breakpoint: 800, settings: { slidesToShow: 1, slidesToScroll: 1 } },
        ]}
      >
        {data?.items.map(({ creativeHash, summaryStats, deliverables }) => (
          <UniqueCreativeDeliverableSummaryStats
            summaryStats={summaryStats}
            deliverable={deliverables[0]}
            key={creativeHash}
          >
            <UniqueCreativeDeliverable deliverable={deliverables[0]} />
          </UniqueCreativeDeliverableSummaryStats>
        ))}
      </Carousel>
    </Spin>
  );
};

export default KeyTakeawayContent;
