import { EntityRelation, EntityListResponse, RELATIONS } from "../types/entities";

import { Method, getDefaultHeaders, request } from "./api";
import { getAuthHeader } from "./jwt";

export async function entityRequest<ResponseData, Body = undefined>(url: string, method: Method = "GET", body?: Body) {
  const authHeader = await getAuthHeader();
  const headers = getDefaultHeaders();

  const { data } = await request<ResponseData, Body>(
    "/v1/entity/okta" + url,
    { ...authHeader, ...headers },
    method,
    body
  );

  return data;
}

export async function domoDashboardRequest<ResponseData, Body = undefined>(url: string, method: Method = "GET", body?: Body) {
  const authHeader = await getAuthHeader();
  const headers = getDefaultHeaders();

  const { data } = await request<ResponseData, Body>(
    "/v1/domo/" + url,
    { ...authHeader, ...headers },
    method,
    body
  );

  return data;
}

export async function entityReverseRelationRequest<O, S>({
  url,
  relationName,
  limit,
  nextPageToken,
}: {
  url: string;
  relationName: (typeof RELATIONS)[keyof typeof RELATIONS];
  limit: number;
  nextPageToken: string;
}) {
  const q = new URLSearchParams({
    relationName,
    limit: limit.toString(),
    nextPageToken: nextPageToken ?? "",
  });

  const apiUrl = `${url}/list-reverse-relations?${q.toString()}`;
  const resp = await entityRequest<EntityListResponse<EntityRelation<O, S>>>(apiUrl);

  return {
    ...resp,
    items: resp.items.map((rel) => ({ ...rel.subjectEntityListing, id: rel.subjectEntityId })),
  };
}
