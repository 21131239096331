import { getTickValue, getUtilizationTickValue } from "./getTickValues";

export function getBarScales({ isUtilization, metricLabel }: { isUtilization: boolean; metricLabel: string }) {
  return {
    grid: {
      drawOnChartArea: false, // only want the grid lines for one axis to show up
      drawBorder: false,
      drawTicks: true,
    },
    ticks: {
      display: true,
      callback: (value: number | string) => (isUtilization ? getUtilizationTickValue(value) : getTickValue(value)),
    },
    title: {
      display: true,
      text: isUtilization ? "% of Ads" : metricLabel,
    },
  };
}
