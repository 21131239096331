import { useMemo } from "react";
import { Alert, Typography } from "antd";
import { useSearchParams } from "react-router-dom";

import { useAppearanceInsights } from "../../../../queries/insights/group.queries";
import { useKpiMetricName } from "../../../../queries/metrics.queries";
import { getBestAttributeValue } from "../../utils";

const { Text, Title } = Typography;

const title = "Appearance Time";

interface AppearanceTitleProps {
  name: string;
  advancedSearch: string;
}

const AppearanceTitle = ({ name, advancedSearch }: AppearanceTitleProps) => {
  const insights = useAppearanceInsights(advancedSearch);
  const kpiMetricName = useKpiMetricName();
  const [searchParams] = useSearchParams();
  const searchTerm = searchParams.get("term");

  const bestAttribute = useMemo(() => {
    if (insights.data && kpiMetricName) {
      const attributeText = getBestAttributeValue(insights.data, kpiMetricName);

      if (attributeText === "0-2") {
        return "First";
      }

      if (attributeText === "0-5") {
        return "Middle";
      }

      if (attributeText === "0-200") {
        return "End";
      }
    }
  }, [kpiMetricName, insights.data]);

  if (insights.isPending) {
    return <Text>Loading {title}...</Text>;
  }

  if (!bestAttribute) {
    return (
      <>
        <Text>{title}</Text>
        <Alert type="info" message="No data for this metric" />
      </>
    );
  }

  const text = searchTerm
    ? `Ads matching the query perform better when they appear in the ${bestAttribute} frame.`
    : `${name} performs better when it appears in the ${bestAttribute} frame.`;

  return (
    <div>
      <Text>{title}</Text>
      <Title level={4} className="insight-report__title">
        {text}
      </Title>
    </div>
  );
};

export default AppearanceTitle;
