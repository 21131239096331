import { API_KEY, getDefaultHeaders, request } from "./api";
import { getAuthHeader } from "./jwt";

interface ResourceManagerInProgressResponse {
  percentage: number;
  resourceId: string;
  status: "IN_PROGRESS";
}

interface ResourceManagerSuccessResponse<Output> {
  percentage: 100;
  resourceId: string;
  status: "AVAILABLE";
  output: { success: Output };
}

interface ResourceManagerFailedResponse {
  percentage: 100;
  resourceId: string;
  status: "FAILED";
  output: { fail: string };
}

type ResourceManagerResponse<SuccessOutput> =
  | ResourceManagerInProgressResponse
  | ResourceManagerSuccessResponse<SuccessOutput>
  | ResourceManagerFailedResponse;

type ResourceManagerInputType = "DELIVERY_DOWNLOAD_ASSETS";

interface ResourceManagerInput<T = unknown> {
  type: ResourceManagerInputType;
  input: T;
}

const RESOURCE_MANAGER_STATUS = {
  IN_PROGRESS: "IN_PROGRESS",
  AVAILABLE: "AVAILABLE",
  FAILED: "FAILED",
} as const;

async function resourceManagerRequest<ResponseData, Input>(id: string, type: ResourceManagerInputType, input: Input) {
  const authHeader = await getAuthHeader();
  const headers = getDefaultHeaders(API_KEY);

  return request<ResponseData, ResourceManagerInput<Input>>(
    "/v1/resource/okta/request/" + id,
    { ...authHeader, ...headers },
    "POST",
    { type, input }
  );
}

const POLL_INTERVAL = 2000;

async function fetchResource<Output, Input>(
  id: string,
  type: ResourceManagerInputType,
  input: Input
): Promise<ResourceManagerSuccessResponse<Output>> {
  return new Promise((resolve, reject) => {
    async function pollApi() {
      const { data } = await resourceManagerRequest<ResourceManagerResponse<Output>, Input>(id, type, input);

      switch (data.status) {
        case RESOURCE_MANAGER_STATUS.IN_PROGRESS:
          setTimeout(pollApi, POLL_INTERVAL);

          return;

        case RESOURCE_MANAGER_STATUS.AVAILABLE:
          resolve(data);

          return;

        case RESOURCE_MANAGER_STATUS.FAILED:
        default:
          reject(new Error(data?.output?.fail ?? "Failed to fetch resource"));

          return;
      }
    }

    void pollApi();
  });
}

interface DeliveryAssetSuccessOutput {
  fileLocation: {
    bucket: string;
    key: string;
    signedUrl: string;
  };
}

interface DeliveryAssetInput {
  resourceGroupIdUsed: string;
  resourceIdUsed: string;
}

export async function getDeliveryAssets(deliveryId: string, resourceGroupIdUsed: string, resourceIdUsed: string) {
  const resp = await fetchResource<DeliveryAssetSuccessOutput, DeliveryAssetInput>(
    deliveryId,
    "DELIVERY_DOWNLOAD_ASSETS",
    { resourceGroupIdUsed, resourceIdUsed }
  );

  return resp.output.success.fileLocation.signedUrl;
}
