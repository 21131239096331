import { useMemo } from "react";
import { Form, Select } from "antd";

interface Props {
  brandResourceGroupIds: string[];
  loading: boolean;
}

const BrandDeliveriesFormSelect = ({ brandResourceGroupIds, loading }: Props) => {
  const resourceGroupIdOptions = useMemo(() => {
    return (
      brandResourceGroupIds.map((resourceGroupId) => ({
        value: resourceGroupId,
        label: resourceGroupId,
      })) ?? []
    );
  }, [brandResourceGroupIds]);

  return (
    <Form.Item label="Resource Group IDs" name="resourceGroupIds">
      <Select
        data-testid="create-dashboard-modal-resource-group-id-input"
        mode="multiple"
        allowClear
        options={resourceGroupIdOptions}
        loading={loading}
      />
    </Form.Item>
  );
};

export default BrandDeliveriesFormSelect;
