import { useState, useCallback } from "react";
import { Button, Select, Space, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import MainPageContentHeader from "../../../components/Layout/MainPageContentHeader";

import { BrandsSortType } from "../BrandListPage";

import { CreateBrandModal } from "./CreateBrandModal";

const { Text } = Typography;

interface Props {
  handleSort: (value: BrandsSortType) => void;
}

const BrandsListHeader = ({ handleSort }: Props) => {
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const handleCreateModalOpen = useCallback(() => {
    setCreateModalOpen(true);
  }, []);

  const handleCreateModalClose = useCallback(() => {
    setCreateModalOpen(false);
  }, []);

  return (
    <>
      <MainPageContentHeader>
        <Text strong>Brands</Text>
        <Space>
          <Text>Sort by:</Text>

          <Select
            defaultValue={BrandsSortType.NAME_AZ}
            onChange={handleSort}
            options={[
              { label: "Name A to Z", value: BrandsSortType.NAME_AZ },
              { label: "Name Z to A", value: BrandsSortType.NAME_ZA },
            ]}
          />

          <Button
            type="text"
            icon={<PlusOutlined />}
            onClick={handleCreateModalOpen}
            data-testid="add-new-brand-button"
          >
            Add new brand
          </Button>
        </Space>
      </MainPageContentHeader>

      <CreateBrandModal isOpen={createModalOpen} onClose={handleCreateModalClose} />
    </>
  );
};

export default BrandsListHeader;
