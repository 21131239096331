import { useCallback, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Select, Space, Typography } from "antd";
import { Link } from "react-router-dom";

import MainPageContentHeader from "../../../components/Layout/MainPageContentHeader";
import { useRouterPaths } from "../../../hooks/useRouterPaths";

import { DashboardsSortType } from "../DashboardListPage";

import { CreateDashboardModal } from "./CreateDashboardModal";

const { Text } = Typography;

interface Props {
  handleSort: (value: DashboardsSortType) => void;
}

const DashboardListHeader = ({ handleSort }: Props) => {
  const { brandPath } = useRouterPaths();
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const handleCreateModalOpen = useCallback(() => {
    setCreateModalOpen(true);
  }, []);

  const handleCreateModalClose = useCallback(() => {
    setCreateModalOpen(false);
  }, []);

  return (
    <>
      <MainPageContentHeader>
        <Text strong>Dashboards</Text>
        <Space>
          <Text>Sort by:</Text>

          <Select
            defaultValue={DashboardsSortType.NAME_AZ}
            onChange={handleSort}
            options={[
              { label: "Name A to Z", value: DashboardsSortType.NAME_AZ },
              { label: "Name Z to A", value: DashboardsSortType.NAME_ZA },
            ]}
          />

          <Link to={`${brandPath}/inventories`}>
            <Button type="text" onClick={handleCreateModalOpen} data-testid="inventories-button">
              Inventories
            </Button>
          </Link>

          <Button
            type="text"
            icon={<PlusOutlined />}
            onClick={handleCreateModalOpen}
            data-testid="create-new-dashboard-button"
          >
            Create new dashboard
          </Button>
        </Space>
      </MainPageContentHeader>

      <CreateDashboardModal isOpen={createModalOpen} onClose={handleCreateModalClose} />
    </>
  );
};

export default DashboardListHeader;
