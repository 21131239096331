import { useLayoutEffect, useMemo, useState, useRef } from "react";

interface Size {
  width: number;
  height: number;
}

function useElementSize<T extends HTMLElement = HTMLDivElement>(): [React.RefObject<T>, Size] {
  const ref = useRef<T>(null);
  const [size, setSize] = useState<Size>({ width: 0, height: 0 });

  const observer = useMemo(() => {
    return new ResizeObserver((entities) => {
      setSize((prev) => {
        const width = entities[0].target.clientWidth;
        const height = entities[0].target.clientHeight;

        if (prev.width === width && prev.height === height) {
          return prev;
        }

        return { width, height };
      });
    });
  }, []);

  useLayoutEffect(() => {
    const element = ref?.current;

    if (!element) {
      return;
    }

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [observer, ref]);

  return [ref, size] as const;
}

export default useElementSize;
