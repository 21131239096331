import { Element } from "chart.js";
import { _isBetween } from "chart.js/helpers";

import { GanttProps, type GanttOptions } from "./controller.gantt";

function getBarBounds(bar: RoundedRect, useFinalPosition: boolean) {
  const props = bar.getProps(["x", "x2", "y", "height"], useFinalPosition);

  const { options } = bar;
  const { x, x2, y, height } = props;

  const h = height ?? options.height;
  const half = h / 2;
  const left = x;
  const right = x2;
  const top = y - half;
  const bottom = y + half;

  return { left, top, right, bottom };
}

function inRange(bar: RoundedRect, x: number | null, y: number | null, useFinalPosition: boolean) {
  const skipX = x === null;
  const skipY = y === null;
  const skipBoth = skipX && skipY;
  const bounds = bar && !skipBoth && getBarBounds(bar, useFinalPosition);

  return (
    bounds && (skipX || _isBetween(x, bounds.left, bounds.right)) && (skipY || _isBetween(y, bounds.top, bounds.bottom))
  );
}

const roundRect = ({
  ctx,
  x,
  y,
  width,
  height,
  radius,
  fillStyle,
  strokeStyle,
}: {
  ctx: CanvasRenderingContext2D;
  x: number;
  y: number;
  width: number;
  height: number;
  radius: number;
  fillStyle: string;
  strokeStyle: string;
}) => {
  ctx.save();

  const radiusStyle: Record<string, number> = { tl: radius, tr: radius, br: radius, bl: radius };

  if (typeof radius !== "number") {
    const defaultRadius: Record<string, number> = { tl: 0, tr: 0, br: 0, bl: 0 };

    Object.keys(defaultRadius).forEach((side) => {
      radiusStyle[side] = radius[side] || defaultRadius[side];
    });
  }

  ctx.beginPath();
  ctx.moveTo(x + radiusStyle.tl, y);
  ctx.lineTo(x + width - radiusStyle.tr, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radiusStyle.tr);
  ctx.lineTo(x + width, y + height - radiusStyle.br);

  ctx.quadraticCurveTo(x + width, y + height, x + width - radiusStyle.br, y + height);

  ctx.lineTo(x + radiusStyle.bl, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radiusStyle.bl);
  ctx.lineTo(x, y + radiusStyle.tl);
  ctx.quadraticCurveTo(x, y, x + radiusStyle.tl, y);
  ctx.closePath();

  if (fillStyle) {
    ctx.fillStyle = fillStyle;
    ctx.fill();
  }

  if (strokeStyle) {
    ctx.strokeStyle = strokeStyle;
    ctx.stroke();
  }

  ctx.restore();
};

export class RoundedRect extends Element<GanttProps, GanttOptions> {
  static id = "RoundedRect";

  static defaults = {
    height: 16,
    backgroundColor: "#FF0000",
    borderStyle: "",
    radius: 8,
  };

  width: number | null = null;

  height: number | null = null;

  size(useFinalPosition: boolean) {
    const { width } = this.getProps(["width"], useFinalPosition);

    return width;
  }

  inRange(mouseX: number, mouseY: number, useFinalPosition: boolean) {
    return inRange(this, mouseX, mouseY, useFinalPosition);
  }

  inXRange(mouseX: number, useFinalPosition: boolean) {
    return inRange(this, mouseX, null, useFinalPosition);
  }

  inYRange(mouseY: number, useFinalPosition: boolean) {
    return inRange(this, null, mouseY, useFinalPosition);
  }

  getCenterPoint(useFinalPosition: boolean) {
    const { x, x2, y } = this.getProps(["x", "x2", "y"], useFinalPosition);

    return {
      x: (x + x2) / 2,
      y,
    };
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  tooltipPosition = this.getCenterPoint;

  draw(ctx: CanvasRenderingContext2D) {
    const {
      options: { backgroundColor, strokeStyle, radius, height: optHeight },
    } = this;

    const h = this.height ?? optHeight;

    if (!this.width) {
      // draw single point based on height
      roundRect({
        ctx,
        x: this.x - h / 2,
        y: this.y - h / 2,
        width: h,
        height: h,
        radius: h / 2,
        fillStyle: backgroundColor as string,
        strokeStyle,
      });

      return;
    }

    roundRect({
      ctx,
      x: this.x,
      y: this.y - h / 2,
      width: this.width,
      height: h,
      radius,
      fillStyle: backgroundColor as string,
      strokeStyle,
    });
  }
}
