import { DeliveryWithId } from "../types/entities";

export function getResourceGroupIds(brandDeliveries: DeliveryWithId[]) {
  return Array.from(
    brandDeliveries.reduce((acc, delivery) => {
      if (delivery.resourceGroupId) {
        acc.add(delivery.resourceGroupId);
      }

      return acc;
    }, new Set<string>())
  );
}

export function getInventoryAndDeliveryIds(selectedResourceGroupIds: string[], deliveries: DeliveryWithId[]) {
  const inventoryAndDeliveries = deliveries.reduce(
    (acc, delivery) => {
      if (!selectedResourceGroupIds.includes(delivery.resourceGroupId)) {
        return acc;
      }

      acc.inventoryIds.add(delivery.inventoryId);
      acc.deliveryIds.add(delivery.id);

      return acc;
    },
    { inventoryIds: new Set<string>(), deliveryIds: new Set<string>() }
  );

  return {
    inventoryIds: Array.from(inventoryAndDeliveries.inventoryIds),
    deliveryIds: Array.from(inventoryAndDeliveries.deliveryIds),
  };
}
