import { useState, useCallback, useEffect } from "react";
import { Flex, Spin } from "antd";
import { LeftCircleTwoTone as LeftButton, RightCircleTwoTone as RightButton } from "@ant-design/icons";

import { Deliverable } from "../../../../types/deliverable";
import { imagineTheme } from "../../../../utils/imagine";

import UniqueCreativeDeliverable from "./UniqueCreativeDeliverable";
import UniqueCreativeDeliverableNoData from "./UniqueCreativeDeliverableNoData";

import { BucketFilter, useBucketValues } from "./bucketValues.query";

import "./UniqueCreativeCarouselDeliverables.scss";

export interface UniqueCreativeDeliverablesProps {
  firstDeliverables?: Deliverable;
  totalDeliverable: number;
  advancedSearch: string;
  filter: {
    bucketFilters: BucketFilter;
    groups?: { name: string; maxValue: number }[];
  };
}

const UniqueCreativeCarouselDeliverables = ({
  advancedSearch,
  firstDeliverables,
  filter,
  totalDeliverable,
}: UniqueCreativeDeliverablesProps) => {
  const [isEnabled, setIsEnabled] = useState(false);
  const [currentDeliverable, setCurrentDeliverable] = useState(firstDeliverables);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const { data, isFetching, fetchNextPage } = useBucketValues({
    advancedSearch,
    filter,
    enabled: isEnabled,
  });

  const [currentIndex, setCurrentIndex] = useState(1);
  const currentPage = data?.pages[currentPageIndex]?.data;

  useEffect(() => {
    if (currentIndex !== 1 && !isEnabled) {
      setIsEnabled(true);
    }
  }, [currentIndex, isEnabled]);

  useEffect(() => {
    const currentGroup = currentIndex - currentPageIndex * 20;

    if (!currentPage?.uniqueCreativesGroups[currentGroup] && currentGroup == 0) {
      void fetchNextPage();
    }

    if (currentPage?.uniqueCreativesGroups[currentGroup - 1]) {
      setCurrentDeliverable(currentPage?.uniqueCreativesGroups[currentGroup - 1].deliverables[0]);
    }
  }, [currentPageIndex, currentIndex, currentPage, fetchNextPage]);

  useEffect(() => {
    if (isFetching) {
      return;
    }

    setCurrentPageIndex(Math.floor(currentIndex / 20));
  }, [currentIndex, isFetching]);

  const increaseIndex = useCallback(() => {
    if (isFetching) {
      return;
    }

    setCurrentIndex((prevIndex) => {
      if (totalDeliverable < prevIndex + 1) {
        setCurrentPageIndex(0);

        return 1;
      }

      return prevIndex + 1;
    });
  }, [setCurrentIndex, totalDeliverable, isFetching]);

  const decreaseIndex = useCallback(() => {
    setCurrentIndex((prevIndex) => prevIndex - 1);
  }, [setCurrentIndex]);

  if (!currentDeliverable) {
    return <UniqueCreativeDeliverableNoData />;
  }

  return (
    <>
      {currentIndex > 1 && (
        <LeftButton
          className="unique-creative-carousel-deliverables unique-creative-carousel-deliverables-arrow unique-creative-carousel-deliverables-arrow__left"
          twoToneColor={imagineTheme.token?.colorInfo}
          onClick={decreaseIndex}
        />
      )}
      <Spin spinning={isFetching}>
        <UniqueCreativeDeliverable deliverable={currentDeliverable} />
      </Spin>
      {totalDeliverable > 1 && (
        <RightButton
          className="unique-creative-carousel-deliverables unique-creative-carousel-deliverables-arrow unique-creative-carousel-deliverables-arrow__right"
          twoToneColor={imagineTheme.token?.colorInfo}
          onClick={increaseIndex}
        />
      )}
      <Flex className="unique-creative-carousel-deliverables" justify="center">
        {currentIndex} / {totalDeliverable}
      </Flex>
    </>
  );
};

export default UniqueCreativeCarouselDeliverables;
