import { useCallback, useEffect, useState } from "react";
import { Button, Divider, Form, Input, Modal, Typography, message } from "antd";
import { MessageInstance } from "antd/es/message/interface";
import { DeleteOutlined } from "@ant-design/icons";
import { RuleObject } from "antd/es/form";

import { BrandWithId, UpdateBrand } from "../../../types/entities";
import { TemporaryFileLocation, FileUploader } from "../../../components/UI/FileUploader";

import { useUpdateBrand, useDeleteBrand } from "../../../queries/entities/brand.mutations";
import { useUniqueNameValidator } from "../../../utilityHooks/useUniqueNameValidator";

const { Title } = Typography;

interface Props {
  isOpen: boolean;
  brand: BrandWithId | null;
  onClose: () => void;
  brands: BrandWithId[];
}

interface ModalProps extends Props {
  brand: BrandWithId;
  messageApi: MessageInstance;
}

interface FormData {
  name: string;
}

const BrandModal = ({ isOpen, brand, onClose, messageApi, brands }: ModalProps) => {
  const [form] = Form.useForm<FormData>();

  const [temporaryLogoFileLocation, setTemporaryLogoFileLocation] = useState<TemporaryFileLocation | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);

  const updateBrand = useUpdateBrand();
  const deleteBrand = useDeleteBrand();

  const validateBrandName = useUniqueNameValidator(brands ?? [], "Brand");

  const handleOnSubmit = useCallback(
    (formData: FormData) => {
      const body = formData as UpdateBrand;

      if (temporaryLogoFileLocation) {
        body.temporaryLogoFileLocation = temporaryLogoFileLocation;
      }

      updateBrand.mutate({ id: brand.id, ...body });
    },
    [brand.id, temporaryLogoFileLocation, updateBrand]
  );

  const handleOnDelete = useCallback(() => {
    setIsConfirmDeleteOpen(true);
  }, []);

  const handleOnDeleteConfirm = useCallback(() => {
    setIsConfirmDeleteOpen(false);
    deleteBrand.mutate(brand.id);
  }, [brand.id, deleteBrand]);

  const handleOnDeleteConfirmCancel = useCallback(() => {
    setIsConfirmDeleteOpen(false);
  }, []);

  const handleValidation = useCallback(
    (_: RuleObject, value: string) => {
      if (brand.name === value) {
        return Promise.resolve();
      }

      return validateBrandName(_, value);
    },
    [brand.name, validateBrandName]
  );

  useEffect(() => {
    if (!deleteBrand.isSuccess) {
      return;
    }

    onClose();

    messageApi.success({
      content: `"${brand.name}" brand deleted successfully`,
      duration: 3,
      className: "brand-delete-success",
    });
  }, [brand.name, onClose, deleteBrand.isSuccess, messageApi]);

  useEffect(() => {
    if (!updateBrand.isSuccess) {
      return;
    }

    onClose();
    messageApi.success({ content: "Brand updated successfully", duration: 3, className: "brand-update-success" });
  }, [brand.name, messageApi, onClose, updateBrand.isSuccess]);

  useEffect(() => {
    if (!updateBrand.error) {
      return;
    }

    messageApi.error({
      content: updateBrand.error?.message ?? "Something went wrong",
      duration: 3,
      className: "brand-update-error",
    });
  }, [messageApi, updateBrand.error]);

  useEffect(() => {
    if (!deleteBrand.error) {
      return;
    }

    messageApi.error({
      content: deleteBrand.error?.message ?? "Something went wrong",
      duration: 3,
      className: "brand-delete-error",
    });
  }, [deleteBrand.error, messageApi]);

  const modalFooter = [
    <Button
      key="delete"
      danger
      icon={<DeleteOutlined />}
      onClick={handleOnDelete}
      loading={deleteBrand.isPending}
      data-testid="edit-brand-modal-delete-button"
    >
      Delete Brand
    </Button>,

    <Button key="cancel" onClick={onClose} data-testid="edit-brand-modal-cancel-button">
      Cancel
    </Button>,

    <Button
      key="confirm"
      type="primary"
      onClick={form.submit}
      loading={updateBrand.isPending}
      disabled={isUploading}
      data-testid="edit-brand-modal-confirm-button"
    >
      Confirm
    </Button>,
  ];

  return (
    <>
      <Modal
        title={<Title level={4}>{brand.name}</Title>}
        open={isOpen}
        footer={modalFooter}
        onCancel={onClose}
        cancelButtonProps={{ ["data-testid"]: "edit-brand-modal-close-button" }}
      >
        <Divider />

        <Form
          form={form}
          labelCol={{ span: 6 }}
          colon={false}
          initialValues={{ name: brand.name }}
          onFinish={handleOnSubmit}
        >
          <Form.Item label="Brand name" name="name" rules={[{ required: true }, { validator: handleValidation }]}>
            <Input data-testid="edit-brand-modal-name-input" />
          </Form.Item>

          <Form.Item label="Logo" valuePropName="fileList">
            <FileUploader
              testid="brand-logo-upload"
              logoUrl={brand.logoFileLocation?.signedUrl}
              onSuccess={setTemporaryLogoFileLocation}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
            />
          </Form.Item>
        </Form>

        <Divider />
      </Modal>

      <Modal
        title={`Delete brand ${brand.name}?`}
        open={isConfirmDeleteOpen}
        onOk={handleOnDeleteConfirm}
        okButtonProps={{ danger: true, "data-testid": "delete-brand-modal-delete-button" }}
        centered
        okText="Delete"
        onCancel={handleOnDeleteConfirmCancel}
        destroyOnClose
        width={400}
      >
        Are you sure you want to delete the {brand.name} brand?
      </Modal>
    </>
  );
};

export const EditBrandModal = (props: Props) => {
  const [messageApi, contextHolder] = message.useMessage();

  return (
    <>
      {contextHolder}

      {!!props.brand && <BrandModal {...props} brand={props.brand} messageApi={messageApi} />}
    </>
  );
};
