import { useCallback, useEffect, useMemo, useState } from "react";
import { Card, Col, Spin } from "antd";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import isEqual from "fast-deep-equal";

import { Tag } from "../../../../types/creativeInteligence";
import { DynamicBreakdowns } from "../../../../queries/insights/types";
import { useTagInsight } from "../../../../queries/tags/tagInsight.query";
import { useCreativeIntelligenceStore } from "../../../../store/creativeIntelligence.store";

import TagSettings from "./TagSettings";
import TagPreviewHeader from "./TagPreviewHeader";
import TagPreviewChart from "./TagPreviewChart";

import "./TagPreview.scss";

interface Props {
  brandId: string;
  dashboardId: string;
  tag: Tag;
  listeners?: SyntheticListenerMap | undefined;
}

const TagPreviewCard = ({ listeners, brandId, dashboardId, tag }: Props) => {
  const [isSettingsVisible, setIsSettingsVisible] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<DynamicBreakdowns | undefined>();

  const filtersList = useCreativeIntelligenceStore((state) => state.selectedFiltersList);

  const tagInsights = useTagInsight({
    tag,
    filter: selectedFilter,
  });

  const openSettings = useCallback(() => setIsSettingsVisible(true), []);
  const hideSettings = useCallback(() => setIsSettingsVisible(false), []);

  useEffect(() => {
    if (selectedFilter && filtersList?.findIndex((filter) => isEqual(filter, selectedFilter)) === -1) {
      setSelectedFilter(undefined);
    }
  }, [filtersList, selectedFilter]);

  const prevFilter = useCallback(() => {
    if (filtersList) {
      const currentIndex = selectedFilter ? filtersList.findIndex((filter) => filter === selectedFilter) : -1;
      const prevIndex = (currentIndex - 1 + filtersList.length + 1) % (filtersList.length + 1);

      setSelectedFilter(prevIndex === filtersList.length ? undefined : filtersList[prevIndex]);
    }
  }, [filtersList, selectedFilter]);

  const nextFilter = useCallback(() => {
    if (filtersList) {
      const currentIndex = selectedFilter ? filtersList.findIndex((filter) => filter === selectedFilter) : -1;
      const nextIndex = (currentIndex + 1) % (filtersList.length + 1);

      setSelectedFilter(nextIndex === filtersList.length ? undefined : filtersList[nextIndex]);
    }
  }, [filtersList, selectedFilter]);

  return (
    <Card className="tag-preview-card" classNames={{ body: "tag-preview-body" }}>
      {tagInsights.isPending ? (
        <Spin className="tag-preview-loading" />
      ) : (
        isSettingsVisible ? (
          <TagSettings brandId={brandId} dashboardId={dashboardId} tag={tag} handleHideSettings={hideSettings} />
        ) : (
          <>
            <TagPreviewHeader
              name={tag.name}
              tagId={tag.tagId}
              selectedFilter={selectedFilter}
              prevFilter={prevFilter}
              nextFilter={nextFilter}
              openSettings={openSettings}
              listeners={listeners}
            />

            <TagPreviewChart type={tag.tagOptions.defaultPreview} key={tag.tagId} data={tagInsights.data?.items} />
          </>
        )
      )}
    </Card>
  );
};

export const SortableTagPreview = ({ brandId, dashboardId, tag }: Props) => {
  const { attributes, setNodeRef, transform, transition, listeners } = useSortable({ id: tag.tagId });

  const style = useMemo(() => {
    return {
      transform: CSS.Transform.toString(transform),
      transition,
    };
  }, [transform, transition]);

  return (
    <Col span={12} ref={setNodeRef} style={style} {...attributes}>
      <TagPreviewCard brandId={brandId} dashboardId={dashboardId} tag={tag} listeners={listeners} />
    </Col>
  );
};

export default TagPreviewCard;
