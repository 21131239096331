import { Fragment, useMemo } from "react";
import { Dropdown, Flex, Button, Typography } from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  UserOutlined,
  SettingOutlined,
  LogoutOutlined,
  BellOutlined,
  EllipsisOutlined,
  SearchOutlined,
  LoginOutlined,
} from "@ant-design/icons";

import { authActions, useAuthStore } from "../../store/auth/auth.store";
import { ROUTES, useRouterPaths } from "../../hooks/useRouterPaths";

import { useBrand } from "../../queries/entities/brand.query";
import { useDashboard } from "../../queries/entities/dashboard.query";
import { useInventory } from "../../queries/entities/inventory.queries";
import { useCurrentTag } from "../../queries/tags/tags.query";

import "./MainHeader.scss";

const { Text } = Typography;

interface HeaderLinkType {
  to: string;
  text: string;
  strong?: boolean;
}

const HeaderLink = ({ link }: { link: HeaderLinkType }) => (
  <Text strong={link.strong} className="main-header__route" data-testid="optimise-title">
    <Link to={link.to}>{link.text}</Link>
  </Text>
);

function useHeaderLinks() {
  const location = useLocation();
  const { brandId, dashboardId, inventoryId, deliveryId, tagId } = useParams();
  const {
    brandPath, dashboardPath, jobIndexPath, jobPath, jobDeliveryIndexPath, jobDeliveryPath, tagPath,
  } = useRouterPaths();

  const brand = useBrand(brandId);
  const dashboard = useDashboard(dashboardId);
  const inventory = useInventory(inventoryId);
  const tag = useCurrentTag();

  return useMemo(() => {
    const links: HeaderLinkType[] = [
      {
        to: ROUTES.INDEX,
        text: "Optimise",
        strong: true,
      },
    ];

    if (brandId) {
      links.push({ to: brandPath, text: brand.data?.name ?? "..." });
    }

    if (dashboardId) {
      links.push({ to: dashboardPath, text: dashboard.data?.name ?? "..." });
    }

    if (tagId) {
      links.push({ to: tagPath, text: tag.data?.name ?? "..." });
    }

    if (location.pathname.endsWith(ROUTES.INVENTORIES)) {
      links.push({ to: jobIndexPath, text: "Inventories" });
    }

    if (!location.pathname.endsWith(ROUTES.INVENTORIES) && location.pathname.includes(ROUTES.INVENTORIES)) {
      links.push({ to: jobIndexPath, text: "Inventories" });
    }

    if (!location.pathname.endsWith(inventoryId) && inventoryId) {
      links.push({ to: jobPath, text: inventory.data?.extractorInput.jobName ?? "" });
    }

    if (!location.pathname.endsWith(ROUTES.DELIVERIES) && location.pathname.includes(ROUTES.DELIVERIES)) {
      links.push({ to: jobDeliveryIndexPath, text: "Deliveries" });
    }

    if (!location.pathname.endsWith(deliveryId) && deliveryId) {
      links.push({ to: jobDeliveryPath, text: deliveryId });
    }

    return links;
  }, [
    brand.data?.name,
    brandId,
    brandPath,
    dashboard.data?.name,
    dashboardId,
    dashboardPath,
    deliveryId,
    inventory.data?.extractorInput.jobName,
    inventoryId,
    jobDeliveryIndexPath,
    jobDeliveryPath,
    jobIndexPath,
    jobPath,
    location.pathname,
    tag.data?.name,
    tagId,
    tagPath,
  ]);
}

const Breadcrumbs = () => {
  const links = useHeaderLinks();

  return (
    <Flex align="center" gap="small" className="main-header__links">
      {links.map((link, i) => (
        <Fragment key={link.to}>
          {i > 0 && ">"}
          <HeaderLink link={link} />
        </Fragment>
      ))}
    </Flex>
  );
};

const MainHeader = () => {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const navigate = useNavigate();

  const menu = useMemo(() => {
    const menuItems: ItemType[] = [
      {
        label: "Settings",
        key: "1",
        icon: <SettingOutlined />,
      },
    ];

    if (isAuthenticated) {
      menuItems.push({
        label: <span data-testid="logout-button">Logout</span>,
        key: "2",
        icon: <LogoutOutlined />,
        onClick: () => authActions.signOut(),
      });
    } else {
      menuItems.push({
        label: "Log in",
        key: "3",
        icon: <LoginOutlined />,
        onClick: () => navigate(ROUTES.LOGIN),
      });
    }

    return {
      items: menuItems,
    };
  }, [navigate, isAuthenticated]);

  return (
    <Flex align="center" justify="space-between" className="main-header">
      <Breadcrumbs />

      <Flex gap="small" align="center">
        <Button icon={<SearchOutlined />} type="link" />
        <Button type="text">Help</Button>
        <Button icon={<EllipsisOutlined />} type="link" />
        <Button icon={<BellOutlined />} shape="circle" />
        <Dropdown menu={menu} trigger={["click"]} placement="bottomLeft">
          <Button icon={<UserOutlined />} type="link" data-testid="user-profile-button" />
        </Dropdown>
      </Flex>
    </Flex>
  );
};

export default MainHeader;
