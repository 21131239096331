import { useState, useCallback, useMemo, Fragment } from "react";
import { Button, Modal, Typography, List, Flex, message } from "antd";
import { PlusCircleFilled as PlusIcon } from "@ant-design/icons";

import { TagOptions } from "../../../../types/creativeInteligence";
import { ReactComponent as InsightMarketplaceIconSvg } from "../../../../assets/insight-marketplace-icon.svg";
import { useAddDashboardTag } from "../../../../queries/entities/dashboard.actions";
import { MarketPlaceItem, useMarketPlaceInsights } from "../../../../queries/tags/market.query";
import { defaultTagOptions } from "../../../../queries/tags/tags.query";

import TagKpis from "../TagKpis/TagKpis";

import "./InsightsMarketPlace.scss";

const { Title, Paragraph, Text } = Typography;

const RenderMarketPlaceItem = ({ marketItem, addTag }: { marketItem: MarketPlaceItem; addTag: () => void }) => {
  return (
    <div className={`marketplace-item ${marketItem.isDisabled ? "disabled" : ""}`} key={marketItem.name}>
      <div className="marketplace-item__content">
        <Flex align="flex-start" justify="space-between">
          <Paragraph>
            <strong>{marketItem.name}</strong>
          </Paragraph>
          {!marketItem.isDisabled && <TagKpis stats={marketItem.stats} />}
        </Flex>
      </div>
      <Paragraph>{marketItem.marketplaceDescription}</Paragraph>
      {marketItem.isAdded ? (
        <Text type="success" color="success">
          Added
        </Text>
      ) : (
        <Button
          onClick={addTag}
          type="text"
          icon={<PlusIcon />}
          className="add-insight-btn"
          data-testid={`add-insight-button-${marketItem.name}`}
        >
          Add Insight
        </Button>
      )}
      {marketItem.marketplaceIcon && (
        <img alt="Market Item Symbol" className="marketplace-item-provider" src={marketItem.marketplaceIcon} />
      )}
    </div>
  );
};

const InsightsMarketPlaceModal = ({
  brandId,
  dashboardId,
  handleCancel,
}: {
  brandId: string;
  dashboardId: string;
  handleCancel: () => void;
}) => {
  const marketPlaceInsights = useMarketPlaceInsights();
  const addDashboardTag = useAddDashboardTag(brandId, dashboardId);

  const marketplaceItems = useMemo(() => {
    const entries = Object.entries(marketPlaceInsights.data);

    return entries.slice().sort(([a], [b]) => a.localeCompare(b));
  }, [marketPlaceInsights.data]);

  const handleAddTag = useCallback(
    (marketplaceItem: MarketPlaceItem) => async () => {
      if (!dashboardId) {
        console.error("Dashboard id is not defined");

        return;
      }

      const tagOptions: TagOptions = {
        ...defaultTagOptions,
      };

      if (marketplaceItem.tagOptions?.endpointOptions) {
        tagOptions.advancedOptions = JSON.stringify({ endpointOptions: marketplaceItem.tagOptions.endpointOptions });
      }

      try {
        await addDashboardTag.add({
          tag: {
            ...marketplaceItem,
            tagOptions,
          },
          sourceTagId: marketplaceItem.tagId,
        });

        message.success("Insight added successfully");
      } catch (error) {
        console.error(error);
        message.error("Error adding insight");
      }
    },
    [addDashboardTag, dashboardId]
  );

  return (
    <Modal
      width={1000}
      className="market-place-modal"
      title={
        <Title level={4} className="market-place-modal-title">
          InsightsMarketPlace
        </Title>
      }
      open={true}
      onCancel={handleCancel}
      classNames={{
        body: "market-place-modal-body",
        content: "market-place-modal-content",
      }}
      footer={null}
    >
      <List size="large" loading={addDashboardTag.isPending || marketPlaceInsights.isPending}>
        {marketplaceItems?.map(([category, items]) => (
          <Fragment key={category}>
            <List.Item key={`${category}-1`} className="marketplace-category">
              <Title level={5} className="marketplace-category-title">
                {category}
              </Title>
            </List.Item>

            <List.Item key={`${category}-2`} className="marketplace-list-item">
              <Flex wrap="wrap" className="marketplace-item-container">
                {items
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((marketItem) => (
                    <RenderMarketPlaceItem
                      marketItem={marketItem}
                      key={marketItem.name}
                      addTag={handleAddTag(marketItem)}
                    />
                  ))}
              </Flex>
            </List.Item>
          </Fragment>
        ))}
      </List>
    </Modal>
  );
};

const InsightsMarketPlace = ({ brandId, dashboardId }: { brandId: string; dashboardId: string }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = useCallback(() => setIsModalVisible(true), [setIsModalVisible]);
  const handleCancel = useCallback(() => setIsModalVisible(false), [setIsModalVisible]);

  return (
    <>
      <Flex align="center" vertical gap="middle">
        <InsightMarketplaceIconSvg />
        <Text strong>
          Insights <Text type="secondary">Marketplace</Text>
        </Text>

        <Button onClick={showModal} type="primary" data-testid="explore-now-insight">
          Explore Now
        </Button>
      </Flex>

      {isModalVisible && (
        <InsightsMarketPlaceModal brandId={brandId} dashboardId={dashboardId} handleCancel={handleCancel} />
      )}
    </>
  );
};

export default InsightsMarketPlace;
