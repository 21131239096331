import { useCallback, useState } from "react";
import { Chart as ChartJS, TooltipModel, ChartType } from "chart.js";
import isEqual from "fast-deep-equal";

import { BaseChartDataset, TreemapDataset } from "../config";

import { TooltipDataProps } from "./Tooltip";

function getTooltipData(chart: ChartJS, tooltip: TooltipModel<ChartType>) {
  const labels = new Set<string>();
  const imgUrls = new Set<string>();

  const dataPoints = tooltip.dataPoints.reduce((acc, { datasetIndex, dataIndex }) => {
    const dataset = chart.data.datasets[datasetIndex] as unknown as BaseChartDataset;

    const creatives = dataset.creatives[dataIndex];
    const label = dataset.data[dataIndex].label ?? (dataset as TreemapDataset).tree[dataIndex].label;

    creatives.forEach(({ deliverables }) => {
      if (deliverables[0].signedPreviewUrl) {
        imgUrls.add(deliverables[0].signedPreviewUrl);
      }
    });

    if (labels.has(label)) {
      return acc;
    }

    labels.add(label);

    const at = creatives?.[0]?.deliverables?.[0].attributes.find(({ attribute }) => attribute === label);
    const attributeConfidence = at?.metadata?.[0]?.confidence;

    acc.push({
      label,
      attributeConfidence,
      summaryStats: dataset.summaryStats[dataIndex],
    });

    return acc;
  }, [] as TooltipDataProps["dataPoints"]);

  return {
    imgUrls: Array.from(imgUrls),
    dataPoints,
  };
}

export function useTooltipData() {
  const [tooltipPos, setTooltipPos] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [tooltipDataProps, setTooltipDataProps] = useState<TooltipDataProps | null>(null);

  const tooltipCallback = useCallback(
    ({ chart, tooltip }: { chart: ChartJS; tooltip: TooltipModel<ChartType> }) => {
      if (tooltip.opacity === 0) {
        if (tooltipDataProps) {
          setTooltipDataProps(null);
        }

        return;
      }

      const x = chart.canvas.offsetLeft + tooltip.caretX;
      const y = chart.canvas.offsetTop + tooltip.caretY - 20;

      if (tooltipPos.x !== x || tooltipPos.y !== y) {
        setTooltipPos({ x, y });
      }

      const data = getTooltipData(chart, tooltip);

      if (isEqual(data, tooltipDataProps)) {
        return;
      }

      setTooltipDataProps(data);
    },
    [tooltipDataProps, tooltipPos.x, tooltipPos.y]
  );

  return {
    tooltipPos,
    tooltipDataProps,
    tooltipCallback,
  };
}
