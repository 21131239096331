import { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Button, Col, Collapse, CollapseProps, Flex, Row, Spin, Typography, message } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";

import MainPageContent from "../../components/Layout/MainPageContent";
import { getResourceTextStatus } from "../../utils/inventoryJob";
import { DeliverySuccessOutput, FileLocation } from "../../types/entities";

import { useConfigStore } from "../../store/config/config.store";
import { useRouterPaths } from "../../hooks/useRouterPaths";
import { getDefaultHeaders, request } from "../../utils/api";
import { getAuthHeader } from "../../utils/jwt";
import { useAddDelivery } from "../../queries/entities/delivery.mutations";
import { useDelivery } from "../../queries/entities/delivery.query";
import { useInventory } from "../../queries/entities/inventory.queries";
import { useInventoryDeliveries } from "../../queries/entities/inventoryDeliveries.query";
import { getDeliveryAssets } from "../../utils/resourceManager.api";

import { DetailsTable, MainDivider } from "./JobDetails";
import { getDeliveryConfigFromDelivery } from "./utils";

function createDownloadLink(href: string, fileName?: string) {
  const a = document.createElement("a");

  a.href = href;

  if (fileName) {
    a.download = fileName;
  }

  a.click();
  a.remove();
}

interface DownloadAsset {
  bucket: string;
  key: string;
  signedUrl: string;
}

async function getReportDownloadLink(deliveryId: string) {
  const authHeader = await getAuthHeader();
  const headers = getDefaultHeaders();
  const url = `/v1/delivery/download-assets/${deliveryId}`;

  const resp = await request<DownloadAsset>(url, { ...authHeader, ...headers });

  const reportData = await fetch(resp.data.signedUrl);
  const report = (await reportData.json()) as Record<string, unknown>;
  const file = new Blob([JSON.stringify(report)], { type: "text/plain" });

  const fileURL = URL.createObjectURL(file);

  createDownloadLink(fileURL, `${deliveryId}-delivery-report.json`);
}

type Result =
  | DeliverySuccessOutput["enrichResult"]
  | DeliverySuccessOutput["extractorResult"]
  | { [x: string]: unknown }
  | undefined;

const Reports = ({ title, result }: { title: string; result: Result }) => {
  if (!result?.failureReportFileLocation || !result.snapshotFileLocation) {
    return null;
  }

  return (
    <>
      <MainDivider>{title}</MainDivider>

      <Row>
        <Col offset={1}>
          <a
            href={(result.failureReportFileLocation as FileLocation | undefined)?.signedUrl ?? ""}
            target="_blank"
            rel="noreferrer"
          >
            Open failure report <ArrowRightOutlined />
          </a>
        </Col>
      </Row>

      <Row>
        <Col offset={1}>
          <a
            href={(result.snapshotFileLocation as FileLocation | undefined)?.signedUrl ?? ""}
            target="_blank"
            rel="noreferrer"
          >
            Open snapshot file <ArrowRightOutlined />
          </a>
        </Col>
      </Row>
    </>
  );
};

const DeliveryDetails = () => {
  const navigate = useNavigate();
  const { inventoryId, deliveryId } = useParams();
  const { jobDeliveryIndexPath } = useRouterPaths();

  const inventory = useInventory(inventoryId);
  const deliveries = useInventoryDeliveries(inventoryId);
  const delivery = useDelivery(deliveryId);
  const config = useConfigStore((state) => state.config);
  const addDeliveryJob = useAddDelivery(inventoryId);

  const [fetchingDownloadLink, setFetchingDownloadLink] = useState(false);
  const [fetchingFullDownloadLink, setFetchingFullDownloadLink] = useState(false);
  const [downloadError, setDownloadError] = useState<string | null>(null);

  const deliveryStatus = useMemo(() => getResourceTextStatus(delivery.data), [delivery.data]);

  const collapseItems: CollapseProps["items"] = useMemo(() => {
    return [
      {
        key: "1",
        label: "Delivery resource",
        children: <pre>{JSON.stringify(delivery.data, null, 2)}</pre>,
      },
    ];
  }, [delivery.data]);

  const deliveryTableData = useMemo(() => {
    if (!delivery.data) {
      return [];
    }

    return [
      { label: "Extractor type", details: delivery.data.extractorType },
      { label: "Status", details: deliveryStatus },
      {
        label: "Percentage done",
        details:
          delivery.data?.resourceOutput?.percentage || delivery.data?.resourceOutput?.percentage === 0
            ? delivery.data?.resourceOutput?.percentage + "%"
            : "n/a",
      },
      { label: "Normalization Type", details: delivery.data.delivery.normalizationType },
    ];
  }, [delivery.data, deliveryStatus]);

  const handleRestartJob = useCallback(async () => {
    try {
      const deliveryConfig = getDeliveryConfigFromDelivery({
        previousDeliveryId: deliveries.data?.items[deliveries.data.items.length - 1]?.id,
        inventoryResourceIdUsed: inventory.data!.resourceId,
        resourceGroupId: inventory.data!.resourceGroupId,
        delivery: delivery.data!,
        sataliaIngestion: delivery.data!.sataliaIngestion ?? false,
        domoIngestion: delivery.data!.domoIngestion ?? false,
      });

      const newDelivery = await addDeliveryJob.mutateAsync(deliveryConfig);

      message.success({
        key: "startDeliveryJob",
        content: "Started delivery job",
      });

      navigate(`${jobDeliveryIndexPath}/${newDelivery.id}`);
    } catch {
      message.error({
        key: "startDeliveryJob",
        content: "Error starting delivery job",
      });
    }
  }, [addDeliveryJob, deliveries.data?.items, delivery.data, inventory.data, jobDeliveryIndexPath, navigate]);

  const handleDownloadReport = useCallback(async () => {
    setFetchingDownloadLink(true);

    try {
      await getReportDownloadLink(deliveryId);
    } catch {
      message.error("Failed to download the delivery report");
    }

    setFetchingDownloadLink(false);
  }, [deliveryId]);

  const handleDownloadAssets = useCallback(async () => {
    setFetchingFullDownloadLink(true);

    message.info("Downloading assets...");
    setDownloadError(null);

    try {
      const signedUrl = await getDeliveryAssets(
        delivery.data!.resourceGroupId,
        delivery.data!.resourceGroupId,
        delivery.data!.resourceId
      );

      createDownloadLink(signedUrl);
    } catch (error) {
      const errorMessage = (error as Error)?.message ?? "Failed to download assets";

      setDownloadError(errorMessage);
    }

    setFetchingFullDownloadLink(false);
  }, [delivery.data]);

  if (inventory.isPending || delivery.isPending || deliveries.isPending) {
    return <Spin />;
  }

  if (!inventory.data || !delivery.data || !config) {
    return <div>Failed to load the delivery</div>;
  }

  const outputSuccess = delivery.data.resourceOutput?.output?.success as DeliverySuccessOutput;

  return (
    <>
      <Typography.Title level={3}>
        {deliveryId} delivery for {inventory.data.extractorInput.jobName}
      </Typography.Title>

      <MainDivider>Delivery details</MainDivider>
      <DetailsTable rows={deliveryTableData} />

      <Flex justify="flex-end" gap="small">
        <Button
          type="primary"
          onClick={handleDownloadReport}
          loading={fetchingDownloadLink}
          disabled={deliveryStatus !== "success"}
        >
          Download JSON report
        </Button>

        <Button
          type="primary"
          onClick={handleDownloadAssets}
          loading={fetchingFullDownloadLink}
          disabled={deliveryStatus !== "success"}
        >
          Download assets
        </Button>
      </Flex>

      {downloadError && <Alert message={downloadError} type="error" showIcon />}

      <Reports title="Enrich result" result={outputSuccess?.enrichResult} />
      <Reports title="Extract result" result={outputSuccess?.extractorResult} />

      {delivery.data?.resourceOutput?.output?.fail && (
        <>
          <MainDivider>Errors</MainDivider>
          <Typography.Text>{delivery.data?.resourceOutput?.output?.fail}</Typography.Text>
        </>
      )}

      <Flex justify="flex-end">
        <Button
          type="primary"
          onClick={handleRestartJob}
          disabled={!delivery.data || (deliveryStatus !== "success" && deliveryStatus !== "failed")}
        >
          Restart delivery job
        </Button>
      </Flex>

      {addDeliveryJob.error && (
        <Alert description={`Failed to start delivery: ${addDeliveryJob.error.message}`} type="error" showIcon />
      )}

      <MainDivider>Raw data</MainDivider>
      <Collapse items={collapseItems} />
    </>
  );
};

const DeliveryDetailsPage = () => {
  return (
    <MainPageContent>
      <DeliveryDetails />
    </MainPageContent>
  );
};

export default DeliveryDetailsPage;
