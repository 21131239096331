import { useQuery } from "@tanstack/react-query";

import type { MetricStats } from "../../../types/creativeInteligence";
import { analyticsApiRequest } from "../../../utils/analytics.api";
import { useCurrentDashboard } from "../../../queries/entities/dashboard.query";

import { CreativeIntelligenceMetric, getMetricsLabel, metricValueFormatter } from "./utils";

enum ResourceType {
  SYNCDATA_JOB = "SYNCDATA_JOB",
}

interface ReportKpisMetricsApiReponse {
  metrics: CreativeIntelligenceMetric[];
  metricStats: Record<CreativeIntelligenceMetric, MetricStats>;
}

interface ReportKpisSyncApiReponse {
  percentage: number;
  waitingForResource: ResourceType;
}

type ReportKpisApiReponse = ReportKpisMetricsApiReponse | ReportKpisSyncApiReponse;

export type ReportKpis = Record<CreativeIntelligenceMetric, { label: string; avg: number }>;

async function fetchReportKPIs(dashboardId: string, resourceGroupIds: string[]) {
  const workerId = resourceGroupIds.join(",");
  const workerIdsQuery = resourceGroupIds.map((id) => `workerIds[]=${id}`).join("&");
  const url = `/query/okta/metadata/report-kpis?qs=${encodeURIComponent(`dashboardId=${dashboardId}&workerId=${workerId}&workerIds=${workerIdsQuery}&withStats=true`)}`;

  return analyticsApiRequest<ReportKpisApiReponse>(url);
}

function benchmarkSelect(data: ReportKpisApiReponse | undefined) {
  if (!data) {
    return;
  }

  if ("waitingForResource" in data) {
    if (data.waitingForResource !== ResourceType.SYNCDATA_JOB) {
      return;
    }

    return { isSyncing: true };
  }

  if (!data.metrics?.length) {
    return;
  }

  return data.metrics.reduce((result, metricName) => {
    result[metricName] = {
      label: getMetricsLabel(metricName),
      avg: parseFloat(metricValueFormatter(data.metricStats?.[metricName]?.avg ?? 0)),
    };

    return result;
  }, {} as ReportKpis);
}

const reportKPIsKey = "reportKPIs";

export function useReportKPIs() {
  const currentDashboard = useCurrentDashboard();
  const resourceGroupIds = currentDashboard.data?.resourceGroupIds ?? [];
  const dashboardId = currentDashboard.data?.id ?? "";

  return useQuery({
    queryKey: [reportKPIsKey, ...resourceGroupIds],
    queryFn: () => fetchReportKPIs(dashboardId, resourceGroupIds),
    select: (response) => {
      return response.data;
    },
    enabled: !!currentDashboard.data?.resourceGroupIds?.length,
    refetchInterval: () => 60 * 60 * 1000, // 1 hour
  });
}

export const useBenchmarkReportKPIs = () => {
  const reportKPIs = useReportKPIs();

  return {
    isPending: reportKPIs.isPending,
    error: reportKPIs.error,
    data: benchmarkSelect(reportKPIs.data),
  };
};
