import { useMutation, useQueryClient } from "@tanstack/react-query";

import { BrandEntity, UpdateBrand } from "../../types/entities";
import { entityRequest } from "../../utils/entity.api";

import { BRAND_KEY, BRAND_API_URL } from "./brand.query";

async function createBrand({ name }: { name: string }) {
  return entityRequest<BrandEntity, UpdateBrand>(BRAND_API_URL, "POST", {
    name,
  });
}

export function useCreateBrand() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createBrand,
    onSuccess: (newBrand: BrandEntity) => {
      queryClient.setQueryData([BRAND_KEY], (previous: BrandEntity[] = []) => {
        return [...previous, newBrand];
      });
    },
  });
}

async function updateBrand({ id, name, temporaryLogoFileLocation }: UpdateBrand & { id: string }) {
  const body: UpdateBrand = { name };

  if (temporaryLogoFileLocation) {
    body.temporaryLogoFileLocation = temporaryLogoFileLocation;
  }

  return entityRequest<BrandEntity, UpdateBrand>(`${BRAND_API_URL}/${id}`, "POST", body);
}

export function useUpdateBrand() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateBrand,
    onSuccess: (brand: BrandEntity) => {
      queryClient.setQueryData([BRAND_KEY], (previous: BrandEntity[] = []) => {
        return previous.map((item) => (item.id === brand.id ? brand : item));
      });
    },
  });
}

async function deleteBrand(id: string) {
  return entityRequest<Record<string, never>>(`${BRAND_API_URL}/${id}`, "DELETE");
}

export function useDeleteBrand() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteBrand,
    onSuccess: (_, brandId) => {
      queryClient.setQueryData([BRAND_KEY], (previous: BrandEntity[] = []) => {
        return previous.filter((item) => item.id !== brandId);
      });
    },
  });
}
