import { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { Flex, Menu, Typography, Spin } from "antd";

import { useRouterPaths } from "../../../../hooks/useRouterPaths";
import { useComputeReportingTags } from "../../../../queries/tags/computeTags.query";
import { useCurrentTagsByCategory, useCurrentTag } from "../../../../queries/tags/tags.query";

import InsightsMarketPlace from "../InsightsMarketplace/InsightsMarketPlace";
import TagKpis from "../TagKpis/TagKpis";

import "./ReportingDrawer.scss";

const { Text } = Typography;

const ReportingDrawer = () => {
  const { brandId, dashboardId } = useParams();

  const currentTagsByCategory = useCurrentTagsByCategory();
  const computeTags = useComputeReportingTags();
  const currentTag = useCurrentTag();

  const { tagIndexPath } = useRouterPaths();

  const tagsEntries = useMemo(() => {
    const entries = Object.entries(currentTagsByCategory.data ?? {});

    return entries.slice().sort(([a], [b]) => a.localeCompare(b));
  }, [currentTagsByCategory.data]);

  const selectedKeys = currentTag.data ? [currentTag.data?.tagId] : [""];

  return (
    <>
      <InsightsMarketPlace brandId={brandId} dashboardId={dashboardId} />
      <Spin spinning={currentTagsByCategory.isPending || computeTags.isPending} tip="Loading...">
        <Menu mode="vertical" className="menu-container" selectedKeys={selectedKeys}>
          {tagsEntries?.map(([category, tags]) => (
            <Menu.ItemGroup title={<Text strong>{category}</Text>} key={category}>
              {tags
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((tag) => (
                  <Menu.Item key={tag.tagId}>
                    <Link to={`${tagIndexPath}/${tag.tagId}`}>
                      <Flex justify="space-between" align="flex-start">
                        <p className="tag-name">{tag.name}</p>
                        <TagKpis stats={computeTags?.data?.[tag.tagId]} />
                      </Flex>
                    </Link>
                  </Menu.Item>
                ))}
            </Menu.ItemGroup>
          ))}
        </Menu>
      </Spin>
    </>
  );
};

export default ReportingDrawer;
