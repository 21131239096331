import { DConfOutput } from "dconf-service/src/types";

class DomainService {
  async getConfig(): Promise<DConfOutput | null> {
    const currentHost = window.location.hostname;
    const response = await fetch(`/dconf?domain=${currentHost}`);

    if (response.ok) {
      const config = await response.json() as { data: DConfOutput };

      return config.data;
    } else {
      const text = await response.text();

      return Promise.reject(new Error(text));
    }
  }
}

export const domainService = new DomainService();
