import { Select, InputNumber, Form, Space } from "antd";

import { MetricMode } from "../../../../../../types/entities";

const OPTIONS = [
  { value: MetricMode.Average, label: "Use Average" },
  { value: MetricMode.Value, label: "Use Value" },
];

const MetricModeValueField = ({ name, dataTestId }: { name: string; dataTestId: string }) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const metricMode = Form.useWatch([name, "mode"]);
  const isAvg = metricMode === MetricMode.Average;

  return (
    <Space.Compact block>
      <Form.Item name={[name, "mode"]} noStyle>
        <Select data-testid={`${dataTestId}Mode`} options={OPTIONS} />
      </Form.Item>

      <Form.Item name={[name, "value"]} noStyle>
        <InputNumber data-testid={`${dataTestId}-value`} disabled={isAvg} />
      </Form.Item>
    </Space.Compact>
  );
};

export default MetricModeValueField;
