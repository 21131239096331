import { Delivery, Inventory, RELATIONS } from "../../types/entities";

import { INVENTORY_API_URL } from "./inventory.queries";

import { useAllPagesEntityQuery } from "./useInfiniteEntityQuery";

export const INVENTORY_DELIVERABLES_QUERY_KEY = "inventoryDeliveries";

export function useInventoryDeliveries(inventoryId: string | undefined) {
  return useAllPagesEntityQuery<Inventory, Delivery>({
    queryKey: [INVENTORY_DELIVERABLES_QUERY_KEY, inventoryId ?? ""],
    urlPrefix: INVENTORY_API_URL,
    relationName: RELATIONS.INVENTORY_DELIVERIES,
    objectId: inventoryId,
  });
}
