import { type MouseEventHandler, useCallback } from "react";
import { type MenuProps, Button, Dropdown, Flex } from "antd";
import { FileImageOutlined, EllipsisOutlined } from "@ant-design/icons";

import "./style.scss";

interface Props {
  menu?: MenuProps;
  logoUrl?: string;
  footerTitle: string;
  testIdPrefix?: string;
}

export const Card = ({ menu, logoUrl, footerTitle, testIdPrefix = "card" }: Props) => {
  const handleMenuButtonClick = useCallback<MouseEventHandler>((e) => {
    e.preventDefault();
  }, []);

  return (
    <div className="card__container">
      <Flex className="card__logo-container" align="center" justify="center">
        {logoUrl ? (
          <img className="card__logo" alt="logo" src={logoUrl} />
        ) : (
          <FileImageOutlined className="card__logo-no-img-icon" />
        )}
      </Flex>

      <Flex className="card__footer" align="center" justify="space-between">
        <span className="card__footer-title" data-testid={`${testIdPrefix}-title--${footerTitle}`}>
          {footerTitle}
        </span>

        {menu && (
          <Dropdown menu={menu} trigger={["click"]} placement="bottomRight">
            <Button
              className="card__footer-dropdown"
              icon={<EllipsisOutlined />}
              type="text"
              onClick={handleMenuButtonClick}
              data-testid={`${testIdPrefix}-dropdown-menu-button`}
            />
          </Dropdown>
        )}
      </Flex>
    </div>
  );
};
