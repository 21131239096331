import { useMemo } from "react";
import { Spin, Typography } from "antd";

import { groupDeliveriesStatus } from "../../../utils/inventoryJob";
import { useInventoryDeliveries } from "../../../queries/entities/inventoryDeliveries.query";

const { Text } = Typography;

const DeliveryJobStatusCell = ({ inventoryId }: { inventoryId: string }) => {
  const deliveries = useInventoryDeliveries(inventoryId);

  const statusGroups = useMemo(() => {
    if (!deliveries.data) {
      return [];
    }

    const sg = groupDeliveriesStatus(deliveries.data.items);

    return Object.entries(sg).filter(([, delivery]) => delivery.length > 0);
  }, [deliveries.data]);

  if (deliveries.isPending) {
    return <Spin size="small" />;
  }

  if (deliveries.isError || !statusGroups.length || !deliveries.data) {
    return "no delivery job found";
  }

  return (
    <>
      {statusGroups.map(([group, deliveryGroup]) => (
        <div key={group}>
          <Text className={`job-table-success-${group}`}>
            {group}: {deliveryGroup.length}
          </Text>
        </div>
      ))}
      <Text> total: {deliveries.data.items.length}</Text>
    </>
  );
};

export default DeliveryJobStatusCell;
