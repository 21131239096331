import { useCallback, useMemo, useState } from "react";
import { Alert, Button, Card, Flex, Form, Input, InputNumber, message, Typography } from "antd";
import { RuleObject } from "antd/es/form";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";

import { generateJobId } from "../../utils";
import { useConfigStore } from "../../store/config/config.store";
import { useAddInventoryJob } from "../../queries/entities/inventory.mutations";
import { useRouterPaths } from "../../hooks/useRouterPaths";
import { InventoryExtractorInput, resourceTypeToExtractorType, SelfServiceExtractor } from "../../types/entities";
import MainPageContent from "../../components/Layout/MainPageContent";

import InventoryDatePicker from "./components/InventoryDatePicker";

import "./JobForm.scss";
import { getMaxDurationForJob } from "./utils";

const minImpressionsRules = [
  { required: true },
  {
    validator: (_: RuleObject, value: number) => {
      if (value < 0) {
        return Promise.reject(new Error("Min impressions must be greater than 0"));
      }

      return Promise.resolve();
    },
  },
];

function getDateRangeRules(maxExtractionDateSpan?: number) {
  return [
    { required: true },
    {
      validator: (_: RuleObject, value: [dayjs.Dayjs, dayjs.Dayjs]) => {
        if (maxExtractionDateSpan && value[1].diff(value[0], "day") > maxExtractionDateSpan) {
          return Promise.reject(new Error(`The Date Range must not be greater than ${maxExtractionDateSpan} days`));
        }

        return Promise.resolve();
      },
    },
  ];
}

interface FormValues {
  jobName: string;
  minImpressions: number;
  dateRange: [dayjs.Dayjs, dayjs.Dayjs | undefined];

  profileId?: string;
  advertiserIds?: string;
  campaignIds?: string;
  advertiserId?: string;
  partnerId?: string;
  adAccountIds?: string;
  adAccountId?: string;
  accessToken?: string;
}

const InventoryJobForm = ({ jobType }: { jobType: SelfServiceExtractor }) => {
  const navigate = useNavigate();
  const { brandId } = useParams();
  const { jobIndexPath } = useRouterPaths();

  const storageInfo = useConfigStore((state) => state.config?.storageInfo);
  const addInventoryJob = useAddInventoryJob<typeof jobType>(brandId, jobType);
  const inventoryResources = useConfigStore((state) => state.config?.inventoryResources);

  const [isLoading, setIsLoading] = useState(false);

  const onFinish = useCallback(
    async (values: FormValues) => {
      try {
        setIsLoading(true);
        const input = {
          jobName: values.jobName,
          minImpressions: values.minImpressions,
          resourceGroupId: generateJobId(values.jobName),
          storageInfo,
          dateRange: {
            startDate: values.dateRange[0].format("YYYY-MM-DD"),
            endDate: values.dateRange[1]?.format("YYYY-MM-DD") ?? "",
          },

          ...(values.profileId ? { profileId: values.profileId } : {}),
          ...(values.advertiserIds
            ? { advertiserIds: values.advertiserIds.split(",").map((id: string) => id.trim()) }
            : {}),
          ...(values.campaignIds ? { campaignIds: values.campaignIds.split(",").map((id: string) => id.trim()) } : []),

          ...(values.advertiserId ? { advertiserId: values.advertiserId } : {}),
          ...(values.partnerId ? { partnerId: values.partnerId } : {}),
          ...(values.adAccountId ? { adAccountId: values.adAccountId } : {}),

          ...(values.adAccountIds
            ? { adAccountIds: values.adAccountIds.split(",").map((id: string) => id.trim()) }
            : {}),
          ...(values.accessToken ? { accessToken: values.accessToken } : {}),
        };

        const newInventory = await addInventoryJob.mutateAsync(input as InventoryExtractorInput<typeof jobType>);

        message.success("Inventory job created");

        navigate(`${jobIndexPath}/${newInventory.id}`);
      } catch {
        message.error("Failed to create an inventory job");
      } finally {
        setIsLoading(false);
      }
    },
    [storageInfo, addInventoryJob, navigate, jobIndexPath, setIsLoading]
  );

  const initialValues = useMemo(
    () => ({
      minImpressions: 1000,
      dateRange: [dayjs().subtract(getMaxDurationForJob(jobType), "y").add(1, "d"), dayjs()],
    }),
    [jobType]
  );

  if (!storageInfo) {
    return <div>Failed to load the config, please try again later</div>;
  }

  return (
    <Flex vertical gap="middle" className="job-form-wrapper">
      <Typography.Title level={3}>Add {resourceTypeToExtractorType[jobType]} inventory job</Typography.Title>

      <Card>
        <Form layout="vertical" onFinish={onFinish} initialValues={initialValues}>
          <Form.Item name="jobName" label="Job Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          {jobType === SelfServiceExtractor.EXTDCM_INVENTORY && (
            <>
              <Form.Item name="advertiserIds" label="Advertiser Ids" rules={[{ required: true }]}>
                <Input />
              </Form.Item>

              <Form.Item name="profileId" label="Profile Id" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </>
          )}

          {[SelfServiceExtractor.EXTDCM_INVENTORY, SelfServiceExtractor.EXTTIKTOK_INVENTORY].includes(jobType) && (
            <Form.Item name="campaignIds" label="Campaign Ids">
              <Input />
            </Form.Item>
          )}

          {[SelfServiceExtractor.EXTDV360_INVENTORY, SelfServiceExtractor.EXTTIKTOK_INVENTORY].includes(jobType) && (
            <Form.Item name="advertiserId" label="Advertiser Id" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          )}

          {jobType === SelfServiceExtractor.EXTDV360_INVENTORY && (
            <Form.Item name="partnerId" label="Partner Id" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          )}

          {jobType === SelfServiceExtractor.EXTFB_INVENTORY && (
            <>
              <Form.Item name="accessToken" label="Access token">
                <Input />
              </Form.Item>

              <Form.Item name="adAccountIds" label="Ad Account Ids" rules={[{ required: true }]}>
                <Input />
              </Form.Item>

              <Form.Item name="campaignIds" label="Campaign Ids">
                <Input />
              </Form.Item>
            </>
          )}

          {jobType === SelfServiceExtractor.EXTLINKEDIN_INVENTORY && (
            <Form.Item name="adAccountId" label="Ad Account Id" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          )}
          <Form.Item name="minImpressions" label="Min impressions" rules={minImpressionsRules}>
            <InputNumber />
          </Form.Item>
          <Form.Item
            name="dateRange"
            label="Date range"
            rules={getDateRangeRules(inventoryResources?.[jobType]?.maxExtractionDateSpan)}
          >
            <InventoryDatePicker
              maxDuration={getMaxDurationForJob(jobType)}
              showExtendedDateList={jobType !== SelfServiceExtractor.EXTTIKTOK_INVENTORY}
            />
          </Form.Item>

          {addInventoryJob.error && (
            <>
              <Alert description={`Failed to start job: ${addInventoryJob.error.message}`} type="error" showIcon />
              <br />
            </>
          )}

          <Form.Item>
            <Flex justify="flex-end">
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Start inventory Job
              </Button>
            </Flex>
          </Form.Item>
        </Form>
      </Card>
    </Flex>
  );
};

const AddInventoryJobPage = ({ jobType }: { jobType: SelfServiceExtractor }) => {
  return (
    <MainPageContent>
      <InventoryJobForm jobType={jobType} />
    </MainPageContent>
  );
};

export default AddInventoryJobPage;
