import { useMemo, useState, useEffect } from "react";
import { Flex } from "antd";

import useElementSize from "../../../../../../utilityHooks/useElementSize";

import type { Deliverable } from "../../../../../../types/deliverable";
import { COLORS_HEX } from "../../../Charts/config";
import { useKpiMetricName } from "../../../../../../queries/metrics.queries";

import { AttributeTypeLabels } from "./consts";
import { filterAttributes, groupAttributes, mockImageAttributes } from "./utils";

import Filter from "./components/Filter";
import TimelinePreview from "./components/TimelinePreview";
import TimelineChart from "./charts/TimelineChart/TimelineChart";
import TextStatsChart from "./charts/TextStatsChart/TextStatsChart";
import DropoffChart from "./charts/DropoffChart/DropoffChart";

import "./TimelineDeliverable.scss";

export interface Props {
  videoRef: (node: HTMLVideoElement) => void;
  hasVideo: boolean;
  deliverable: Deliverable;
  progress: number;
  duration: number;
}

const TimelineDeliverable = ({ videoRef, deliverable, progress, duration, hasVideo }: Props) => {
  const kpiMetricName = useKpiMetricName();

  const [filters, setFilters] = useState<Set<string>>(new Set());
  const [scrubbarPosition, setScrubbarPosition] = useState<number>(14);

  const kpi = useMemo(() => {
    if (!kpiMetricName) {
      return;
    }

    return Number(deliverable.metrics[kpiMetricName]);
  }, [deliverable.metrics, kpiMetricName]);

  const [cardRef, chartCardSize] = useElementSize();
  const [wrapperRef, wrapperSize] = useElementSize();

  const cardWidth = useMemo(() => {
    // hack to resize charts | hardcoded values are from the css | 300px for preview and 16px for the gap
    return Math.max((wrapperSize.width || 0) - 300 - 16, 450);
  }, [wrapperSize.width]);

  const groupedAttributes = useMemo(() => {
    if (!hasVideo) {
      return groupAttributes(mockImageAttributes(deliverable.attributes));
    }

    return groupAttributes(deliverable.attributes);
  }, [deliverable.attributes, hasVideo]);

  const filteredAttributes = useMemo(() => {
    return filterAttributes(groupedAttributes.timelineAttributes, filters);
  }, [filters, groupedAttributes.timelineAttributes]);

  const filterLabels = useMemo(() => {
    return Array.from(
      groupedAttributes.timelineAttributes.reduce((acc, { type }) => acc.add(type), new Set<string>())
    ).map((value) => ({
      label: AttributeTypeLabels[value] || value,
      value,
    }));
  }, [groupedAttributes.timelineAttributes]);

  useEffect(() => {
    setFilters(new Set());
  }, [deliverable]);

  return (
    <Flex ref={wrapperRef} gap="middle">
      <Flex className="timeline-deliverable__preview" vertical gap="middle">
        <div className="timeline-deliverable__card timeline-deliverable__video-card">
          <TimelinePreview
            kpi={kpi}
            avgKpi={deliverable.avgKpiForSelectedKpi}
            deliverable={deliverable}
            videoRef={videoRef}
          />
        </div>

        <Flex className="timeline-deliverable__card" vertical>
          {filterLabels.map(({ label, value }) => (
            <Filter key={value} filters={filters} label={label} value={value} onChange={setFilters} />
          ))}
        </Flex>
      </Flex>

      <div
        className="timeline-deliverable__card timeline-deliverable__charts-card"
        ref={cardRef}
        style={{ width: `${cardWidth}px` }}
      >
        <div className="timeline-deliverable__scrubbar-wrapper">
          <div
            className={`timeline-deliverable__scrubbar ${!hasVideo ? "timeline-deliverable__scrubbar--disabled" : ""}`}
            style={{
              left: `${Math.round(scrubbarPosition)}px`,
              height: `${(chartCardSize.height || 0) - 32}px`,
            }}
          />
        </div>

        <div className="timeline-deliverable__charts-wrapper">
          <Flex vertical gap="small">
            {hasVideo && (
              <>
                <DropoffChart duration={duration} metrics={deliverable.metrics} />

                <TextStatsChart
                  title="Text Density"
                  color={COLORS_HEX.deviationDown}
                  attributes={groupedAttributes.textDensityAttributes}
                  duration={duration}
                  showPercentLabel
                />

                <TextStatsChart
                  title="Text words per second"
                  color={COLORS_HEX.textDensityBlue}
                  attributes={groupedAttributes.wordCountAttributes}
                  duration={duration}
                />
              </>
            )}

            <TimelineChart
              attributes={filteredAttributes}
              progress={progress}
              duration={duration}
              setScrubbarPosition={setScrubbarPosition}
            />
          </Flex>
        </div>
      </div>
    </Flex>
  );
};

export default TimelineDeliverable;
