import { useQuery } from "@tanstack/react-query";

import { InsightsEndpoint } from "../../constants/creativeInteligence";

import { fetchInsightData, useBaseInsightsOptions } from "./insights.queries";
import type { CampaignInfoInsights, DynamicBreakdowns, InsightsBasicOptions } from "./types";

function transformFilter(filters: string[]) {
  const breakdowns = filters.reduce(
    (acc, filterName) => {
      const breakdownValues = filterName.split("__$$__");

      breakdownValues.forEach((breakdownWithValue) => {
        const [breakdown, breakdownValue] = breakdownWithValue.split("#$#");

        if (!acc[breakdown]) {
          acc[breakdown] = new Set();
        }

        if (breakdownValue !== undefined) {
          acc[breakdown].add(breakdownValue);
        }
      });

      return acc;
    },
    {} as {
      [breakdown: string]: Set<string>;
    }
  );

  return Object.entries(breakdowns).reduce((acc, [breakdown, values]) => {
    acc[breakdown] = Array.from(values).sort((a, b) => a.localeCompare(b));

    return acc;
  }, {} as DynamicBreakdowns);
}

function select(data: CampaignInfoInsights) {
  const campaignFilters = transformFilter(data.filter);

  return {
    ...data,
    campaignFilters,
  };
}

const campaignInfoKey = "campaignInfo";

export const useCampaignInfo = () => {
  const options = useBaseInsightsOptions();

  return useQuery({
    queryKey: [campaignInfoKey, options],
    queryFn: () => {
      return fetchInsightData<CampaignInfoInsights, InsightsBasicOptions>(InsightsEndpoint.CampaignInfo, options!);
    },
    select: (response) => select(response.data),
    enabled: !!options,
    staleTime: 1000 * 60 * 60 * 1, // 1 hour
  });
};
