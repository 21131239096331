import { useCallback } from "react";
import { Typography, Button, Flex, Row } from "antd";

import { InsightsEndpoint } from "../../../../constants/creativeInteligence";
import { InsightItem } from "../InsightContent/InsightContent";

import { useUsageInsights } from "./usage.query";

import "./UsageContent.scss";

const { Text } = Typography;

interface UsageContentProps {
  advancedSearch: string;
}

const UsageContent = ({ advancedSearch }: UsageContentProps) => {
  const { data, isPending, fetchNextPage, hasNextPage } = useUsageInsights(advancedSearch);

  const handleNextPage = useCallback(() => void fetchNextPage(), [fetchNextPage]);

  if (!data?.length && !isPending) {
    return <Text>-</Text>;
  }

  return (
    <Flex align="center" justify="center" vertical gap="middle">
      <Row className="usage-content">
        {data?.map(([attributeName, group]) =>
          group.uniqueCreativesGroups.map((creative) => (
            <InsightItem
              key={creative.creativeHash}
              advancedSearch={advancedSearch}
              label={attributeName}
              summaryStats={group.summaryStats}
              deliverable={creative.deliverables[0]}
              filter={{ bucketFilters: { type: InsightsEndpoint.AttributeValues, value: attributeName } }}
            />
          ))
        )}
      </Row>

      {hasNextPage && (
        <Button loading={isPending} onClick={handleNextPage}>
          Load more
        </Button>
      )}
    </Flex>
  );
};

export default UsageContent;
