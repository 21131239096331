import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

import { DynamicBreakdowns } from "../queries/insights/types";
import { DashboardView } from "../constants/creativeInteligence";

// DATE_FORMAT
export interface FilterDateRange {
  startDate: string;
  endDate: string;
}

interface State {
  showAttentionScore: boolean;
  toggleAttentionScore: () => void;

  dashboardView: DashboardView;
  setDashboardView: (dashboardView: DashboardView) => void;

  selectedFilters: DynamicBreakdowns | undefined;
  selectedFiltersList: DynamicBreakdowns[] | undefined;
  setSelectedFilters: (selectedFilters: DynamicBreakdowns) => void;
  resetSelectedFilters: () => void;

  selectedDateRange: FilterDateRange | undefined;
  setSelectedDateRange: (selectedDateRange: FilterDateRange) => void;
}

function filtersList(filters: DynamicBreakdowns) {
  return Object.entries(filters).reduce((acc, [breakdown, values]) => {
    if (values.length === 0) {
      return acc;
    }

    return acc.concat(values.map((value) => ({ [breakdown]: [value] })));
  }, [] as DynamicBreakdowns[]);
}

export const useCreativeIntelligenceStore = create<State, [["zustand/devtools", never], ["zustand/immer", never]]>(
  devtools(
    immer((set) => ({
      showAttentionScore: false,
      toggleAttentionScore: () => set((prev) => ({ ...prev, showAttentionScore: !prev.showAttentionScore })),

      dashboardView: DashboardView.Utilisation,
      setDashboardView: (dashboardView) => set({ dashboardView }),

      selectedFilters: undefined,
      selectedFiltersList: undefined,
      setSelectedFilters: (selectedFilters) =>
        set({ selectedFilters, selectedFiltersList: filtersList(selectedFilters) }),
      resetSelectedFilters: () => {
        set({ selectedFilters: undefined, selectedFiltersList: undefined });
      },

      selectedDateRange: undefined,
      setSelectedDateRange: (selectedDateRange) => set({ selectedDateRange }),
    }))
  )
);
