import { useMemo } from "react";
import { Alert, Typography } from "antd";
import { useSearchParams } from "react-router-dom";

import { useKpiMetricName } from "../../../../queries/metrics.queries";
import { getBestAttributeValue } from "../../utils";

import { usePositionInsight } from "./position.query";

const { Text, Title } = Typography;

const title = "Position Analysis";

interface Props {
  name: string;
  advancedSearch: string;
}

const PositionTitle = ({ name, advancedSearch }: Props) => {
  const insights = usePositionInsight(advancedSearch);
  const kpiMetricName = useKpiMetricName();
  const [searchParams] = useSearchParams();
  const searchTerm = searchParams.get("term");

  const bestAttribute = useMemo(() => {
    if (kpiMetricName) {
      return getBestAttributeValue(insights.data, kpiMetricName);
    }
  }, [insights.data, kpiMetricName]);

  if (insights.isPending) {
    return <Text>Loading {title}...</Text>;
  }

  if (!bestAttribute) {
    return (
      <>
        <Text>{title}</Text>
        <Alert type="info" message="No data for this metric" />
      </>
    );
  }

  const text = searchTerm
    ? `The search query works best when it appears in the ${bestAttribute}`
    : `${name} works best when it appears in the ${bestAttribute}`;

  return (
    <>
      <Text>{title}</Text>
      <Title level={4} className="insight-report__title">
        {text}
      </Title>
    </>
  );
};

export default PositionTitle;
