import { Spin } from "antd";
import { ReactNode } from "react";

import { useCurrentDashboard } from "../../queries/entities/dashboard.query";

import MainPageContent from "../Layout/MainPageContent";
import PageWithTimelineDrawer from "../Layout/PageWithTimelineDrawer";

import AdvancedSearch, { SearchProps } from "./components/AdvancedSearch/AdvancedSearch";
import ReportingDrawer from "./components/ReportingDrawer/ReportingDrawer";
import SecondaryHeader from "./components/SecondaryHeader/SecondaryHeader";

const PageWrapper = ({ children, searchProps }: { children: ReactNode; searchProps?: SearchProps }) => {
  const dashboard = useCurrentDashboard();

  if (dashboard.isError) {
    return (
      <MainPageContent>
        <div>
          <h3>Something went wrong</h3>
          <h4>{dashboard.error.message}</h4>
        </div>
      </MainPageContent>
    );
  }

  return (
    <PageWithTimelineDrawer
      header={<SecondaryHeader searchComponent={<AdvancedSearch {...searchProps} />} />}
      sidebarIsCollapsed
      sidebarContent={<ReportingDrawer />}
    >
      <Spin spinning={dashboard.isPending}>{children}</Spin>
    </PageWithTimelineDrawer>
  );
};

export default PageWrapper;
