import {
  type UpdateMode,
  type ChartOptions,
  type Scale,
  type ChartMeta,
  BubbleController,
  CategoryScale,
  LinearScale,
} from "chart.js";

import { valueOrDefault } from "chart.js/helpers";

import { RoundedRect } from "./element.roundedRect";

const barHoverRadius = 8;

export interface GanttProps {
  x: number;
  x2: number;
  y: number;
  backgroundColor: string;
  hoverBackgroundColor: string;
  r?: number;
  height?: number;
}

export interface GanttDataPoint {
  x: number;
  x2: number;
  y: string;
  backgroundColor: string;
  hoverBackgroundColor: string;
  r?: number;
  height?: number;
}

export interface GanttOptions extends ChartOptions<"gantt"> {
  width: number;
  height: number;
  strokeStyle: string;
  radius: number;
}

export class GanttController extends BubbleController {
  static id = "gantt";

  static defaults = {
    datasetElementType: false,
    dataElementType: "RoundedRect",
    hoverRadius: 8,
    radius: 8,
    animations: {
      numbers: {
        type: "number",
        properties: ["x", "y"],
      },
    },
  };

  static overrides = {
    scales: {
      x: {
        type: LinearScale.id,
      },
      y: {
        type: CategoryScale.id,
      },
    },
  };

  // parsePrimitiveData() {
  //   throw new Error("format not supported");
  // }

  // parseArrayData() {
  //   throw new Error("format not supported");
  // }

  parseObjectData(meta: ChartMeta<"gantt", RoundedRect>, data: GanttDataPoint[], start: number, count: number) {
    const parsed = super.parseObjectData(meta, data, start, count); /*  as GanttDataPoint[] */

    for (let i = 0; i < parsed.length; i += 1) {
      const item = data[start + i];

      parsed[i].x2 = valueOrDefault<number>(
        item?.x2 && +item.x2,
        (this.resolveDataElementOptions(i + start) as GanttProps).x2
      );

      parsed[i].backgroundColor = item.backgroundColor;
      parsed[i].hoverBackgroundColor = item.hoverBackgroundColor;
    }

    return parsed;
  }

  getMaxOverflow() {
    return true;
  }

  getLabelAndValue(index: number) {
    const meta = this._cachedMeta;
    const parsed = this.getParsed(index) as GanttProps;

    return {
      label: meta.label,

      value: `(${parsed.x} - ${parsed.x2}, ${parsed.y})`,
    };
  }

  updateElements(elements: RoundedRect[], start: number, count: number, mode: UpdateMode) {
    const reset = mode === "reset";
    const { iScale, vScale } = this._cachedMeta;
    const firstOpts = this.resolveDataElementOptions(start, mode);
    const sharedOptions = this.getSharedOptions(firstOpts);
    const includeOptions = this.includeOptions(mode, sharedOptions ?? firstOpts);
    const iAxis = iScale?.axis;
    const vAxis = vScale?.axis;

    if (!iAxis || !vAxis) {
      return;
    }

    for (let i = start; i < start + count; i += 1) {
      const point = elements[i];

      const properties = {} as Partial<
        GanttProps & { options?: GanttOptions & Record<string, unknown> } & Record<string, unknown>
      >;

      let iPixel: number;
      let vPixel: number;

      const data = this.chart.data.datasets[this.index].data[i] as unknown as GanttProps;

      if (includeOptions) {
        properties.options = this.resolveDataElementOptions(i, point.active ? "active" : mode) as GanttOptions & Record<string, unknown>;

        properties.options.backgroundColor = data.backgroundColor || properties.options.backgroundColor;
        properties.options.hoverBackgroundColor = data.hoverBackgroundColor || properties.options.hoverBackgroundColor;
      }

      const parsed = this.getParsed(i) as Record<string, unknown>;

      iPixel = properties[iAxis] = iScale.getPixelForValue(parsed[iAxis] as number);

      vPixel = properties[vAxis] = vScale.getPixelForValue(parsed[vAxis] as number);

      if (reset) {
        iPixel = properties[iAxis] = iScale.getPixelForDecimal(0.5);

        vPixel = properties[vAxis] = vScale.getBasePixel();

        if (properties.options) {
          properties.options.x2 = properties.x;
        }
      }

      properties.skip = Number.isNaN(iPixel) || Number.isNaN(vPixel);

      const x = iScale.getPixelForValue(data?.x || 0);
      const x2 = iScale.getPixelForValue(data.x2);
      const y = vScale.getPixelForValue(data?.y || 0);

      const props = {
        ...properties,
        x,
        x2,
        y,
        width: x2 - x,
      };

      this.updateElement(point, i, props, mode);
    }

    if (sharedOptions) {
      this.updateSharedOptions(sharedOptions, mode, firstOpts);
    }
  }

  resolveDataElementOptions(index: number, mode: UpdateMode = "active") {
    let values = super.resolveDataElementOptions(index, mode);

    // In case values were cached (and thus frozen), we need to clone the values
    if (values.$shared) {
      values = { ...values, $shared: false };
    }

    // overwriting default bubble chart behaviour
    if (mode === "active") {
      values.radius = barHoverRadius;
    }

    return values;
  }

  getMinMax(scale: Scale) {
    const { min: superMin, max: superMax } = super.getMinMax(scale);
    const meta = this._cachedMeta;
    const { _parsed: parsed } = meta as { _parsed: GanttDataPoint[] };

    if (parsed.length < 2) {
      return { min: 0, max: 1 };
    }

    if (scale === meta.vScale) {
      return { min: superMin + 1, max: superMax - 8 };
    }

    let min = Number.POSITIVE_INFINITY;
    let max = Number.NEGATIVE_INFINITY;

    for (const data of parsed) {
      min = Math.min(min, data.x);
      max = Math.max(max, data.x2);
    }

    return { min, max };
  }
}
