import { useMemo } from "react";

import { InsightsEndpoint } from "../../../../constants/creativeInteligence";
import { useKpiMetricName } from "../../../../queries/metrics.queries";

import { getBestAttributeValue } from "../../utils";

import InsightContent, { InsightContentProps } from "../InsightContent/InsightContent";

import { usePositionInsight } from "./position.query";

interface PositionProps {
  advancedSearch: string;
}

const PositionContent = ({ advancedSearch }: PositionProps) => {
  const insights = usePositionInsight(advancedSearch);
  const kpiMetricName = useKpiMetricName();

  const bestAttribute = useMemo(() => {
    if (kpiMetricName) {
      return getBestAttributeValue(insights.data, kpiMetricName);
    }
  }, [insights.data, kpiMetricName]);

  const entries: InsightContentProps[] = useMemo(() => {
    if (!insights.data || !bestAttribute) {
      return [];
    }

    return Object.entries(insights.data).map(([label, position]) => ({
      creativeHash: position.uniqueCreativesGroups?.[0]?.creativeHash,
      label,
      advancedSearch,
      summaryStats: position.summaryStats,
      deliverable: position.uniqueCreativesGroups?.[0]?.deliverables[0],
      filter: {
        bucketFilters: {
          type: InsightsEndpoint.Position,
          value: label,
        },
      },
    }));
  }, [insights.data, bestAttribute, advancedSearch]);

  return <InsightContent loading={insights.isPending} bestAttribute={bestAttribute} entries={entries} />;
};

export default PositionContent;
