import { useCallback, useMemo, Key, useState } from "react";
import { Tree, Input } from "antd";
import { BasicDataNode } from "antd/es/tree";

import { camelToUpperCase } from "../../../../utils";
import { DynamicBreakdowns } from "../../../../queries/insights/types";

import "./FiltersTree.scss";

export interface TreeDataType extends BasicDataNode {
  key: string;
  title: string;
  children?: TreeDataType[];
}

function createFullTree(filters: DynamicBreakdowns): TreeDataType[] {
  return Object.entries(filters).map(([breakdown, values]) => {
    const children = values.map((value) => ({
      key: `${breakdown}-${value}`,
      title: value,
    }));

    return {
      key: breakdown,
      title: camelToUpperCase(breakdown.split(".")[1] ?? ""),
      children,
    };
  });
}

function findInTree(title: string, searchValue: string) {
  return title.toLowerCase().includes(searchValue.toLowerCase());
}

const TitleRender = (node: TreeDataType) => {
  return <span data-testid={`set-filters-${node.title}`}>{node.title}</span>;
};

const FiltersTree = ({
  campaignFilters,
  selectedFilters,
  setSelectedFilters,
}: {
  campaignFilters: DynamicBreakdowns | undefined;
  selectedFilters: DynamicBreakdowns | undefined;
  setSelectedFilters: (filters: DynamicBreakdowns) => void;
}) => {
  const [searchValue, setSearchValue] = useState("");

  const treeData = useMemo(() => {
    if (!campaignFilters) {
      return;
    }

    return createFullTree(campaignFilters);
  }, [campaignFilters]);

  const filteredTreeData = useMemo(() => {
    return treeData?.filter((node) => {
      if (findInTree(node.title, searchValue)) {
        return true;
      }

      if (node.children) {
        return node.children.some((child) => findInTree(child.title, searchValue));
      }

      return false;
    });
  }, [treeData, searchValue]);

  const checkedKeys = useMemo(() => {
    if (!selectedFilters) {
      return [];
    }

    return Object.entries(selectedFilters).reduce((acc, [breakdown, values]) => {
      return [...acc, ...values.map((value) => `${breakdown}-${value}`)];
    }, [] as string[]);
  }, [selectedFilters]);

  const onCheck = useCallback(
    (checked: Key[] | { checked: Key[]; halfChecked: Key[] }) => {
      if (!campaignFilters) {
        return;
      }

      const selectedKeys = Array.isArray(checked) ? checked : checked.checked;

      const newSelectedFilters = selectedKeys.reduce((acc, key) => {
        const [breakdown, ...values] = key.toString().split("-");
        const value = values.join("-"); // hmmmmmm

        if (!acc[breakdown]) {
          acc[breakdown] = [];
        }

        if (value) {
          acc[breakdown].push(value);
        }

        return acc;
      }, {} as DynamicBreakdowns);

      setSelectedFilters(newSelectedFilters);
    },
    [campaignFilters, setSelectedFilters]
  );

  const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  }, []);

  return (
    <div className="fitler-container">
      <Input
        placeholder="Search"
        value={searchValue}
        onChange={handleSearchChange}
        allowClear
        data-testid="search-filter"
      />

      <Tree
        checkable
        selectable={false}
        treeData={filteredTreeData}
        checkedKeys={checkedKeys}
        onCheck={onCheck}
        titleRender={TitleRender}
      />
    </div>
  );
};

export default FiltersTree;
