import { Alert, Typography } from "antd";

import { useUsageInsights } from "./usage.query";

const { Text, Title } = Typography;

const title = "Usage";

interface UsageTitleProps {
  advancedSearch: string;
}

const UsageTitle = ({ advancedSearch }: UsageTitleProps) => {
  const insights = useUsageInsights(advancedSearch);
  const bestAttribute = insights.data?.[0]?.[0];

  if (insights.isPending) {
    return <Text>Loading Usage...</Text>;
  }

  if (!bestAttribute) {
    return (
      <>
        <Text>{title}</Text>
        <Alert type="info" message="No data for this metric" />
      </>
    );
  }

  return (
    <>
      <Text>{title}</Text>
      <Title level={4} className="insight-report__title">
        {`"${bestAttribute}" is performing best`}
      </Title>
    </>
  );
};

export default UsageTitle;
