import { useMemo } from "react";
import { Alert, Typography } from "antd";
import { useSearchParams } from "react-router-dom";

import { useKpiMetricName } from "../../../../queries/metrics.queries";
import { getBestAttributeValue } from "../../utils";

import { useSizeInsights } from "./size.query";

const { Text, Title } = Typography;

const title = "Size Analysis";

interface SizeProps {
  name: string;
  advancedSearch: string;
}

const SizeTitle = ({ name, advancedSearch }: SizeProps) => {
  const insights = useSizeInsights(advancedSearch);
  const kpiMetricName = useKpiMetricName();
  const [searchParams] = useSearchParams();
  const searchTerm = searchParams.get("term");

  const bestAttribute = useMemo(() => {
    if (insights.data && kpiMetricName) {
      return getBestAttributeValue(insights.data, kpiMetricName);
    }
  }, [kpiMetricName, insights.data]);

  if (insights.isPending) {
    return <Text>Loading {title}...</Text>;
  }

  if (!bestAttribute) {
    return (
      <>
        <Text>{title}</Text>
        <Alert type="info" message="No data for this metric" />
      </>
    );
  }

  const text = searchTerm
    ? `When ads matching the query are shown at a ${bestAttribute} size, they perform better.`
    : `When ${name} is shown at a ${bestAttribute} size, it performs better.`;

  return (
    <>
      <Text>{title}</Text>
      <Title level={4} className="insight-report__title">
        {text}
      </Title>
    </>
  );
};

export default SizeTitle;
