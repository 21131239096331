import { Chart as ChartJS } from "chart.js";

import { COLORS_HEX } from "../config";

export function drawQuadrants(chart: ChartJS, kpiMetricValue: number, confidenceMetricValue: number) {
  const {
    ctx,
    chartArea: { left, right, top, bottom },
    scales: { x: xScale, y: yScale },
  } = chart;

  const leftPad = left + 10;
  const rightPad = right - 130;
  const topPad = top + 25;
  const bottomPad = bottom - 15;

  const yMetricValuePosition = yScale.getPixelForValue(+kpiMetricValue);
  const xMetricValuePosition = xScale.getPixelForValue(+confidenceMetricValue);
  const midY = yMetricValuePosition > bottom ? bottom : yMetricValuePosition;
  const midX = xMetricValuePosition > right ? right : xMetricValuePosition;

  const stdFont = "normal 700 14px \"WPP Regular\"";

  ctx.beginPath();
  ctx.strokeStyle = "gray";
  ctx.setLineDash([2, 6]); /* dashes are 5px and spaces are 3px */
  ctx.moveTo(midX, top);
  ctx.lineTo(midX, bottom);
  ctx.stroke();

  ctx.beginPath();
  ctx.strokeStyle = "gray";

  ctx.moveTo(left, midY);
  ctx.lineTo(right, midY);
  ctx.stroke();
  ctx.setLineDash([]);

  // Top right quadrant
  if (bottom > yMetricValuePosition) {
    ctx.beginPath();
    ctx.lineWidth = 2;
    ctx.strokeStyle = COLORS_HEX.deviationUp;
    ctx.moveTo(midX + 1, top);
    ctx.lineTo(right, top);
    ctx.lineTo(right, midY);
    ctx.stroke();

    // there may be no points in this quadrant
    ctx.font = stdFont;
    ctx.fillStyle = COLORS_HEX.lightgray;
    ctx.fillText("HIGH PERFORMERS", rightPad, topPad);
  }

  // Top left quadrant
  ctx.beginPath();
  ctx.lineWidth = 2;
  ctx.strokeStyle = "#D8E1EA";
  ctx.moveTo(left, midY + 1);
  ctx.lineTo(left, top);
  ctx.lineTo(midX, top);
  ctx.stroke();

  ctx.font = stdFont;
  ctx.fillStyle = COLORS_HEX.lightgray;
  ctx.fillText("EXPLORE", leftPad, topPad);

  // Bottom left quadrant
  ctx.beginPath();
  ctx.lineWidth = 2;
  ctx.strokeStyle = "#F5F7F9";
  ctx.moveTo(left, midY);
  ctx.lineTo(left, bottom);
  ctx.lineTo(midX, bottom);
  ctx.stroke();

  ctx.font = stdFont;
  ctx.fillStyle = COLORS_HEX.lightgray;
  ctx.fillText("GETTING STARTED", leftPad, bottomPad);

  // Bottom right quadrant
  if (right > xMetricValuePosition) {
    ctx.beginPath();
    ctx.lineWidth = 2;
    ctx.strokeStyle = COLORS_HEX.deviationDown;
    ctx.moveTo(midX + 1, bottom);
    ctx.lineTo(right, bottom);
    ctx.lineTo(right, midY);
    ctx.stroke();

    ctx.font = stdFont;
    ctx.fillStyle = COLORS_HEX.lightgray;
    ctx.fillText("LOW PERFORMERS", rightPad, bottomPad);
  }
}
