import { TokenPagination } from "../types/creativeInteligence";

interface ApiError {
  message: string;
}

export interface ApiResponse<ResponseData> {
  data: ResponseData;
  pagination?: TokenPagination;
}

export const API_KEY = process.env.REACT_APP_API_KEY ?? "p8r2XhnLwZ3sIMlzdVJJ395isF2P4pNZ4po6xUe3";

export function getDefaultHeaders(apiKey: string | null = API_KEY) {
  return {
    "Content-Type": "application/json",
    ...(apiKey && { "x-api-key": apiKey }),
  };
}

export type Method = "GET" | "POST" | "PUT" | "DELETE";

export async function request<ResponseData, Body = undefined>(
  url: string,
  headers: Record<string, string>,
  method: Method = "GET",
  body?: Body
) {
  const params: RequestInit = {
    method,
    headers,
  };

  if (body) {
    params.body = JSON.stringify(body);
  }

  const resp = await fetch(url, params);

  if (!resp.ok) {
    const { message } = (await resp.json()) as ApiError;

    if (message) {
      throw new Error(message);
    }

    throw new Error("Something went wrong");
  }

  return resp.json() as Promise<ApiResponse<ResponseData>>;
}
