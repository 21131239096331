export * from "./deliverable.queries";

const DELIVERABLE_QUERY_URL = "/query/okta/reports-with-deliverable-info";

export interface QueryReportParams {
  resourceGroupIds: string[];
  reportId: string;
  kpi: string;
  storageBucket: string;
  dashboardId: string;
}

export const getDeliverableQueryUrl = ({
  resourceGroupIds,
  reportId,
  kpi,
  storageBucket,
  dashboardId,
}: QueryReportParams) => {
  const workerId = resourceGroupIds.join(",");

  return `${DELIVERABLE_QUERY_URL}?qs=${encodeURIComponent(`workerId=${workerId}&reportId=${reportId}&staticBreakdown=no-breakdowns&kpi=${kpi}&kpiOrder=asc&pageSize=20&storageBucket=${storageBucket}&dashboardId=${dashboardId}`)}`;
};
