import { useQuery } from "@tanstack/react-query";

import { EntityListResponse, EntityRelation, User, Brand } from "../../types/entities";
import { entityRequest } from "../../utils/entity.api";
import { useAuthStore } from "../../store/auth/auth.store";

interface UserEntityRelation extends EntityRelation<User, Brand> {
  role?: "OWNER" | "MEMBER";
}

export const BRAND_API_URL = "/brand";
export const BRAND_KEY = "brand";

async function fetchBrands() {
  const data = await entityRequest<EntityListResponse<UserEntityRelation>>(`${BRAND_API_URL}/list-mine?limit=40`);

  return data.items.map((item) => ({
    ...item.objectEntityListing,
    id: item.objectEntityId,
  }));
}

export function useBrands() {
  return useQuery({
    queryKey: [BRAND_KEY],
    queryFn: fetchBrands,
  });
}

export function useBrand(brandId: string | undefined) {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  return useQuery({
    queryKey: [BRAND_KEY, brandId],
    queryFn: () => {
      return entityRequest<Brand>(`${BRAND_API_URL}/${brandId}`);
    },
    enabled: !!isAuthenticated && !!brandId,
  });
}
