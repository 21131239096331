import { useMemo } from "react";
import { MenuProps } from "antd";

import { BrandWithId } from "../../../types/entities";
import { Card } from "../../../components/UI/Card";

interface Props {
  brand: BrandWithId;
  onEdit: (brandId: string) => void;
}

export const BrandCard = ({ brand, onEdit }: Props) => {
  const menu: MenuProps = useMemo(
    () => ({
      items: [
        {
          key: "1",
          label: <span data-testid="brand-card-menu-edit-button">Edit</span>,
          onClick: (info) => {
            info.domEvent.stopPropagation();
            onEdit(brand.id);
          },
        },
      ],
    }),
    [brand.id, onEdit]
  );

  return (
    <Card menu={menu} logoUrl={brand.logoFileLocation?.signedUrl} footerTitle={brand.name} testIdPrefix="brand-card" />
  );
};
