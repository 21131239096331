import { ReactNode, useMemo } from "react";
import { Typography, Flex, Card, Space, Tooltip } from "antd";
import { PieChartOutlined, RiseOutlined, EyeOutlined } from "@ant-design/icons";

import { useCurrentMetrics } from "../../../../queries/metrics.queries";
import { SummaryStats } from "../../../../types/creativeInteligence";
import { Deliverable } from "../../../../types/deliverable";
import {
  numberToShortKM,
  utilisationDisplayFormatter,
} from "../../../../utils";
import { getMetricStat } from "../../../../utils/analytics";

const { Text } = Typography;

interface Props {
  summaryStats: SummaryStats;
  rightLabel?: string;
  children: ReactNode;
  deliverable: Deliverable;
}

const QuadrantNames = {
  "00": "Too Early",
  "01": "Low Performer",
  "10": "One To Watch",
  "11": "High Performer",
} as const;

const TooltipLabels = {
  [QuadrantNames["00"]]: "KPI and confidence below dashboard values",
  [QuadrantNames["01"]]: "KPI below dashboard value, confidence above",
  [QuadrantNames["10"]]: "KPI above dashboard value, confidence below",
  [QuadrantNames["11"]]: "KPI and confidence above dashboard values",
} as const;

const UniqueCreativeDeliverableSummaryStats = ({
  children,
  rightLabel,
  summaryStats,
  deliverable,
}: Props) => {
  const currentMetrics = useCurrentMetrics();

  const { quadrantName, impressions, kpi } = useMemo(() => {
    if (!currentMetrics.data) {
      return {};
    }

    const kpiMetric = currentMetrics.data.kpiMetric;
    const confidenceMetric = currentMetrics.data.confidenceMetric;

    const kpiValue = summaryStats.kpiMetric[getMetricStat(kpiMetric.metric)];
    const confidenceValue =
      summaryStats.kpiMetric[getMetricStat(confidenceMetric.metric)];

    const kpiHalf = kpiValue > kpiMetric.value ? 1 : 0;
    const confidenceHalf = confidenceValue > confidenceMetric.value ? 1 : 0;

    const impressionsStatsValue =
      confidenceMetric.metric === "impressions"
        ? summaryStats.impressionsStats.sum
        : Number(deliverable?.metrics.impressions);

    return {
      kpi: kpiValue,
      quadrantName: QuadrantNames[`${kpiHalf}${confidenceHalf}`],
      impressions: numberToShortKM(impressionsStatsValue),
    };
  }, [currentMetrics.data, summaryStats, deliverable]);

  const quadrantLabel = quadrantName ? (
    <Tooltip title={TooltipLabels[quadrantName]}>
      <Text
        className={`performance-text performance-text--style-${quadrantName}`}
      >
        {quadrantName}
      </Text>
    </Tooltip>
  ) : null;

  return (
    <Flex className="unique-creative" vertical>
      {rightLabel ? (
        <Flex justify="space-between" align="center">
          <Text>{rightLabel}</Text>
          {quadrantLabel}
        </Flex>
      ) : (
        quadrantLabel
      )}

      <Card className="unique-creative__content">{children}</Card>

      <Flex justify="space-around">
        <Tooltip title="Utilisation">
          <Space>
            <PieChartOutlined />
            {utilisationDisplayFormatter(summaryStats.utilisation)}
          </Space>
        </Tooltip>

        <Tooltip title="KPI value">
          <Space>
            <RiseOutlined />
            {kpi?.toFixed(3)}
          </Space>
        </Tooltip>

        <Tooltip title="Impressions">
          <Space>
            <EyeOutlined />
            {impressions}
          </Space>
        </Tooltip>
      </Flex>
    </Flex>
  );
};

export default UniqueCreativeDeliverableSummaryStats;
