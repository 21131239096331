import { useEffect } from "react";
import { QueryClient, QueryKey, useInfiniteQuery } from "@tanstack/react-query";

import { EntityListResponse, RELATIONS } from "../../types/entities";
import { entityReverseRelationRequest } from "../../utils/entity.api";

export function addInfiniteEntityQueryData<T>(queryKey: QueryKey, queryClient: QueryClient, newItems: T[]) {
  queryClient.setQueryData<{ pages: EntityListResponse<T>[] }>(queryKey, (previous) => {
    if (!previous) {
      return {
        pages: [
          {
            items: newItems,
            pagination: {
              nextPageToken: "",
              total: newItems.length,
            },
          },
        ],
      };
    }

    const lastPage = previous.pages[previous.pages.length - 1];

    lastPage.items = [...lastPage.items, ...newItems];
    lastPage.pagination.total += newItems.length;

    return {
      ...previous,
      pages: [...previous.pages],
    };
  });
}

export function updateInfiniteEntityQueryData<T extends { id: string }>(
  queryKey: QueryKey,
  queryClient: QueryClient,
  updatedItem: T
) {
  queryClient.setQueryData<{ pages: EntityListResponse<T>[] }>(queryKey, (previous) => {
    if (!previous) {
      return {
        pages: [
          {
            items: [updatedItem],
            pagination: {
              nextPageToken: "",
              total: 1,
            },
          },
        ],
      };
    }

    return {
      ...previous,
      pages: previous.pages.map((page) => {
        page.items = page.items.map((item) => (item.id === updatedItem.id ? updatedItem : item));

        return page;
      }),
    };
  });
}

export function deleteInfiniteEntityQueryData<T extends { id: string }>(
  queryKey: QueryKey,
  queryClient: QueryClient,
  deletedItemId: string
) {
  queryClient.setQueryData<{ pages: EntityListResponse<T>[] }>(queryKey, (previous) => {
    if (!previous) {
      return {
        pages: [],
        pagination: {
          nextPageToken: "",
          total: 0,
        },
      };
    }

    return {
      ...previous,
      pages: previous.pages.map((page) => {
        page.items = page.items.filter((item) => item.id !== deletedItemId);
        page.pagination.total -= 1;

        return page;
      }),
    };
  });
}

interface QueryParams<S, T> {
  queryKey: string[];
  objectId: string | undefined;
  urlPrefix: string;
  relationName: (typeof RELATIONS)[keyof typeof RELATIONS];
  select?: (resp: (S & { id: string })[]) => T;
}

export function useInfiniteEntityQuery<O, S, T = (S & { id: string })[]>({
  queryKey,
  objectId,
  urlPrefix,
  relationName,
  limit,
  select,
}: QueryParams<S, T> & { limit: number }) {
  return useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam }) => {
      return entityReverseRelationRequest<O, S>({
        url: `${urlPrefix}/${objectId}`,
        relationName,
        limit,
        nextPageToken: pageParam,
      });
    },
    select: (resp) => {
      return {
        items: select ? select(resp.pages.flatMap((page) => page.items)) : resp.pages.flatMap((page) => page.items),
        pagination: resp.pages?.[0].pagination,
      };
    },
    getNextPageParam: (resp) => resp?.pagination?.nextPageToken,
    initialPageParam: "",
    enabled: !!objectId,
  });
}

export function useAllPagesEntityQuery<O, S, T = (S & { id: string })[]>(args: QueryParams<S, T>) {
  const query = useInfiniteEntityQuery<O, S, T>({ ...args, limit: 200 });

  useEffect(() => {
    if (!query.isFetching && query.hasNextPage) {
      void query.fetchNextPage();
    }
  }, [query]);

  return {
    ...query,
    isPending: query.isPending || query.hasNextPage,
  };
}
