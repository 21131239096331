import type { ChartDataset } from "chart.js";

import type { Attribute } from "../../../../../../../../types/attribute";
import { COLORS_HEX } from "../../../../../Charts/config";

import { AttributeTypes } from "../../consts";

import type { GanttOptions, GanttDataPoint } from "./GanttChart/controller.gantt";

export const getTimelineChartOptions = ({ xMax }: { xMax: number }): Partial<GanttOptions> => ({
  animation: false,
  maintainAspectRatio: false,
  responsive: true,

  layout: {
    padding: {
      // not pixel perfect, but close enough
      right: 15,
    },
  },

  plugins: {
    tooltip: {
      enabled: true,
      callbacks: {
        label: ({ label, raw }) => {
          const { y } = raw as TimelineDatapoint;

          return [`${label}: ${y}`];
        },
      },
      xAlign: "center",
      yAlign: "bottom",
    },
    legend: {
      display: false,
    },
  },

  scales: {
    x: {
      type: "linear",
      position: "top",
      min: 0,
      suggestedMax: xMax,
      ticks: {
        display: false,
      },
      border: {
        display: false,
      },
      grid: {
        display: false,
      },
    },
    y: {
      offset: true,
      stackWeight: 2,
      stack: "chartStack",
      border: {
        display: false,
      },
      grid: {
        display: false,
      },
      ticks: {
        mirror: true,
        labelOffset: -20,
        font: {
          family: "WPP Regular",
          weight: "bold",
          size: 14,
        },
      },
    },
  },
});

const ROW_HEIGHT = 25;

export const getChartStyle = (items: number) => {
  const chartHeight = items * 2 * ROW_HEIGHT + 23;

  return { height: `${chartHeight}px` };
};

export interface TimelineDatapoint extends GanttDataPoint {
  attribute: Attribute;
}

export interface TimelineDataset extends ChartDataset<"gantt", TimelineDatapoint[]> {
  analysisType: string;
  backgroundColor: string[];
  borderColor: string;
  data: TimelineDatapoint[];
  height: number;
  radius: number;
  label: string;
  labels: string[];
}

const timelineAttributeLabelOrder: { [key: string]: number } = {
  "ImageTag-Label": 3,
  "ImageTag-Logo": 4,
  Label: 3,
  Logo: 4,
  Text: 2,
  TextValue: 2,
};

const getTimelineAttributeLabelOrder = (attributeType: string) => timelineAttributeLabelOrder[attributeType] || 0;

const sortByAttributeLabelOrder = (a: Attribute, b: Attribute) =>
  getTimelineAttributeLabelOrder(b.type) - getTimelineAttributeLabelOrder(a.type);

export const sortAttributes = (a: Attribute, b: Attribute) => {
  const labelOrderCmp = sortByAttributeLabelOrder(a, b);

  if (labelOrderCmp !== 0) {
    return labelOrderCmp;
  }

  const strA = a.attribute.toLowerCase();
  const strB = b.attribute.toLowerCase();

  if (strA > strB) {
    return 1;
  }

  if (strA < strB) {
    return -1;
  }

  return 0;
};

const TimelineAnalysisType = {
  "ImageTag-Label": "ImageTag-Label",
  "ImageTag-Logo": "ImageTag-Logo",
  TextValue: "TextValue",
  Label: "Label",
  Logo: "Logo",
  Text: "Text",
};

const getTypeColor = (type: string) => {
  if (
    type === TimelineAnalysisType.Text
    || type === TimelineAnalysisType.TextValue
    || type === TimelineAnalysisType["ImageTag-Label"]
  ) {
    return {
      backgroundColor: COLORS_HEX.forAdam,
      hoverBackgroundColor: COLORS_HEX.forAdam,
    };
  }

  if (type === TimelineAnalysisType.Logo || type === TimelineAnalysisType["ImageTag-Logo"]) {
    return {
      backgroundColor: COLORS_HEX.green,
      hoverBackgroundColor: COLORS_HEX.green,
    };
  }

  return {
    backgroundColor: COLORS_HEX.gray,
    hoverBackgroundColor: COLORS_HEX.gray,
  };
};

const getChartData = (attrs: Attribute[]) => {
  const initial: {
    backgroundColors: string[];
    hoverBackgroundColors: string[];
    labels: string[];
    values: TimelineDatapoint[];
  } = {
    backgroundColors: [],
    hoverBackgroundColors: [],
    labels: [],
    values: [],
  };

  return attrs.reduce((acc, attribute) => {
    if (!attribute.timeline) {
      return acc;
    }

    const { backgroundColor, hoverBackgroundColor } = getTypeColor(attribute.type);

    const useIndividualPointColors = [
      AttributeTypes.COLOR_THEORY_COLOR,
      AttributeTypes.COLOR_THEORY_COLOR_GROUP,
      AttributeTypes.COLOR_THEORY_PANTONE_COLOR_GROUP,
      AttributeTypes.COLOR_THEORY_PRIMARY_COLOR_GROUP,
    ].includes(attribute.type);

    let pointColor = backgroundColor as string;

    if (useIndividualPointColors) {
      const colorHex = attribute.attribute.split("-");

      pointColor = colorHex.length > 1 ? colorHex[1] : colorHex[0];
    }

    const y = attribute.attribute;

    const values = attribute.timeline.map((segment) => {
      return {
        x: segment.start,
        x2: segment.end,
        y,
        backgroundColor: useIndividualPointColors ? pointColor : backgroundColor,
        hoverBackgroundColor: useIndividualPointColors ? pointColor : hoverBackgroundColor,
        attribute,
      } as TimelineDatapoint;
    });

    return {
      backgroundColors: [...acc.backgroundColors, useIndividualPointColors ? pointColor : backgroundColor],
      hoverBackgroundColors: [
        ...acc.hoverBackgroundColors,
        useIndividualPointColors ? pointColor : hoverBackgroundColor,
      ],
      labels: [...acc.labels, y],
      values: [...acc.values, ...values],
    };
  }, initial);
};

export const getLabelsAndTypes = (attributes: Attribute[]) => {
  const R = attributes.reduce(
    (acc, { type, attribute }) => ({
      displayLabels: [...acc.displayLabels, attribute],
      attributeTypes: [...acc.attributeTypes, type],
    }),
    { displayLabels: [] as string[], attributeTypes: [] as string[] }
  );

  return {
    displayLabels: [...new Set(R.displayLabels)],
    attributeTypes: [...new Set(R.attributeTypes)],
  };
};

const BAR_HEIGHT = 18;
const BAR_RADIUS = 10;
const BORDER_COLOR = "#FFFFFF00";

export const getTimelineDatasets = (attributeTypes: string[], attributes: Attribute[]) => {
  return attributeTypes.reduce((acc, analysisType) => {
    const data = attributes.filter(({ type }) => type === analysisType);

    if (!data.length) {
      return acc;
    }

    const { backgroundColors, labels, values } = getChartData(data);

    acc.push({
      analysisType,
      backgroundColor: backgroundColors,
      borderColor: BORDER_COLOR,
      data: values,
      height: BAR_HEIGHT,
      radius: BAR_RADIUS,
      label: analysisType,
      labels,
    });

    return acc;
  }, [] as TimelineDataset[]);
};
