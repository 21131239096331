import { useMemo } from "react";

import { useKpiMetricName } from "../../../../queries/metrics.queries";
import { InsightsEndpoint } from "../../../../constants/creativeInteligence";
import {
  SEGMENT_GROUPS,
  SEGMENT_GROUPS_OPTIONS,
  useAppearanceInsights,
} from "../../../../queries/insights/group.queries";

import { getBestAttributeValue } from "../../utils";

import InsightContent, { InsightContentProps } from "../InsightContent/InsightContent";

interface AppearanceProps {
  advancedSearch: string;
}
const AppearanceContent = ({ advancedSearch }: AppearanceProps) => {
  const insights = useAppearanceInsights(advancedSearch);
  const kpiMetricName = useKpiMetricName();

  const bestAttribute = useMemo(() => {
    if (insights.data && kpiMetricName) {
      return getBestAttributeValue(insights.data, kpiMetricName);
    }
  }, [kpiMetricName, insights.data]);

  const entries: InsightContentProps[] = useMemo(() => {
    if (!insights.data) {
      return [];
    }

    return [
      {
        creativeHash: insights.data["0-2"].uniqueCreativesGroups?.[0]?.creativeHash,
        label: "First Frame",
        advancedSearch,
        summaryStats: insights.data["0-2"].summaryStats,
        deliverable: insights.data["0-2"].uniqueCreativesGroups?.[0]?.deliverables[0],
        filter: {
          bucketFilters: {
            type: InsightsEndpoint.SegmentPosition,
            minValue: 0,
            maxValue: SEGMENT_GROUPS_OPTIONS["0-2"],
          },
          groups: SEGMENT_GROUPS,
        },
      },
      {
        creativeHash: insights.data["0-5"].uniqueCreativesGroups?.[0]?.creativeHash,
        label: "Middle Frame",
        advancedSearch,
        summaryStats: insights.data["0-5"].summaryStats,
        deliverable: insights.data["0-5"].uniqueCreativesGroups?.[0]?.deliverables[0],
        filter: {
          bucketFilters: {
            type: InsightsEndpoint.SegmentPosition,
            minValue: SEGMENT_GROUPS_OPTIONS["0-2"],
            maxValue: SEGMENT_GROUPS_OPTIONS["0-5"],
          },
          groups: SEGMENT_GROUPS,
        },
      },
      {
        creativeHash: insights.data["0-200"].uniqueCreativesGroups?.[0]?.creativeHash,
        label: "End Frame",
        advancedSearch,
        summaryStats: insights.data["0-200"].summaryStats,
        deliverable: insights.data["0-200"].uniqueCreativesGroups?.[0]?.deliverables[0],
        filter: {
          bucketFilters: {
            type: InsightsEndpoint.SegmentPosition,
            minValue: SEGMENT_GROUPS_OPTIONS["0-5"],
            maxValue: SEGMENT_GROUPS_OPTIONS["0-200"],
          },
          groups: SEGMENT_GROUPS,
        },
      },
    ];
  }, [insights.data, advancedSearch]);

  return <InsightContent loading={insights.isPending} bestAttribute={bestAttribute} entries={entries} />;
};

export default AppearanceContent;
