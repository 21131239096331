import { useMutation, useQueryClient } from "@tanstack/react-query";

import { CreateDashboard, DashboardEntity, UpdateDashboard } from "../../types/entities";
import { entityRequest } from "../../utils/entity.api";

import { DASHBOARD_QUERY_KEY, DASHBOARD_API_URL } from "./dashboard.query";
import { BRAND_DASHBOARDS_QUERY_KEY } from "./brandDashboards.query";
import {
  addInfiniteEntityQueryData,
  deleteInfiniteEntityQueryData,
  updateInfiniteEntityQueryData,
} from "./useInfiniteEntityQuery";

async function createDashboard(dashboardData: CreateDashboard) {
  return entityRequest<DashboardEntity, CreateDashboard>(DASHBOARD_API_URL, "POST", dashboardData);
}

export function useCreateDashboard() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createDashboard,
    onSuccess: (newDashboard: DashboardEntity, { brandId }) => {
      addInfiniteEntityQueryData([BRAND_DASHBOARDS_QUERY_KEY, brandId], queryClient, [newDashboard]);
      queryClient.setQueryData([DASHBOARD_QUERY_KEY, newDashboard.id], newDashboard);
    },
  });
}

async function updateDashboard({
  dashboardId,
  dashboardData,
}: {
  brandId: string;
  dashboardId: string;
  dashboardData: UpdateDashboard;
}) {
  return entityRequest<DashboardEntity, UpdateDashboard>(`${DASHBOARD_API_URL}/${dashboardId}`, "POST", dashboardData);
}

export const useUpdateDashboard = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateDashboard,
    onMutate: ({ dashboardId, dashboardData }) => {
      const previousData = queryClient.getQueryData([DASHBOARD_QUERY_KEY, dashboardId]);

      queryClient.setQueryData([DASHBOARD_QUERY_KEY, dashboardId], (previous: DashboardEntity) => ({
        ...previous,
        ...dashboardData,
      }));

      return { previousData };
    },
    onSuccess: (newDashboard: DashboardEntity, { brandId, dashboardId }) => {
      updateInfiniteEntityQueryData([BRAND_DASHBOARDS_QUERY_KEY, brandId], queryClient, newDashboard);
      queryClient.setQueryData([DASHBOARD_QUERY_KEY, dashboardId], newDashboard);
    },
    onError: (_, { dashboardId }, ctx) => {
      if (ctx?.previousData) {
        queryClient.setQueryData([DASHBOARD_QUERY_KEY, dashboardId], ctx.previousData);
      }
    },
  });
};

async function deleteDashboard(id: string) {
  return entityRequest<Record<string, never>>(`${DASHBOARD_API_URL}/${id}`, "DELETE");
}

export const useDeleteDashboard = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ dashboardId }: { brandId: string; dashboardId: string }) => deleteDashboard(dashboardId),
    onSuccess: (_, { brandId, dashboardId }) => {
      deleteInfiniteEntityQueryData([BRAND_DASHBOARDS_QUERY_KEY, brandId], queryClient, dashboardId);
      queryClient.setQueryData([DASHBOARD_QUERY_KEY, dashboardId], undefined);
    },
  });
};
