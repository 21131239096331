import { InsightsEndpoint } from "../../../../constants/creativeInteligence";
import { useInsights } from "../../../../queries/insights/insights.queries";
import { InsightsData, RawInsightsCreativeGroup } from "../../../../types/creativeInteligence";

export interface Position extends InsightsData {
  BOTTOM_CENTER: RawInsightsCreativeGroup;
  BOTTOM_LEFT: RawInsightsCreativeGroup;
  BOTTOM_RIGHT: RawInsightsCreativeGroup;
  CENTER: RawInsightsCreativeGroup;
  CENTER_LEFT: RawInsightsCreativeGroup;
  CENTER_RIGHT: RawInsightsCreativeGroup;
  TOP_CENTER: RawInsightsCreativeGroup;
  TOP_LEFT: RawInsightsCreativeGroup;
  TOP_RIGHT: RawInsightsCreativeGroup;
}

interface PositionResponse {
  positions: Position;
}

const positionReportKey = "positionReportKey";

function select(data?: PositionResponse) {
  return data?.positions;
}

export function usePositionInsight(advancedSearch: string) {
  const insights = useInsights<PositionResponse>({
    queryKey: positionReportKey,
    endpoint: InsightsEndpoint.Position,
    extraOptions: {
      advancedSearch,
    },
  });

  return {
    ...insights,
    data: select(insights.data),
  };
}
