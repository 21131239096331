import cn from "classnames";
import React, { useState, useCallback } from "react";
import { Layout, Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import "./MainSider.scss";

interface MainSiderProps {
  defaultIsCollapsed?: boolean;
  children: React.ReactNode;
}

const MainSider: React.FunctionComponent<MainSiderProps> = ({ children, defaultIsCollapsed = false }) => {
  const [collapsed, setCollapsed] = useState(defaultIsCollapsed);
  const onCollapse = useCallback((value: boolean) => setCollapsed(value), [setCollapsed]);

  const closeSidebar = useCallback(() => {
    setCollapsed(true);
  }, []);

  return (
    <>
      <button
        type="button"
        className={cn("sider-overlay", { "sider-overlay--hidden": collapsed })}
        onClick={closeSidebar}
      />
      <Layout.Sider
        collapsible
        className="sider-container"
        width={280}
        collapsed={collapsed}
        onCollapse={onCollapse}
        collapsedWidth={0}
        defaultCollapsed={true}
        trigger={<Button icon={collapsed ? <RightOutlined /> : <LeftOutlined />} type="link" />}
        theme="light"
      >
        {children}
      </Layout.Sider>
    </>
  );
};

export default MainSider;
