import { useState, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Spin, Flex } from "antd";

import { BrandWithId } from "../../../types/entities";
import { useRouterPaths } from "../../../hooks/useRouterPaths";
import { useBrands } from "../../../queries/entities/brand.query";

import { BrandsSortType } from "../BrandListPage";

import { BrandCard } from "./BrandCard";
import { EditBrandModal } from "./EditBrandModal";

function sortByName<T extends { name: string }>(data: T[] | void, sortType: BrandsSortType) {
  return data?.sort((a, b) => {
    if (sortType === BrandsSortType.NAME_AZ) {
      return a.name.localeCompare(b.name);
    }

    if (sortType === BrandsSortType.NAME_ZA) {
      return b.name.localeCompare(a.name);
    }

    return 0;
  });
}

interface ListProps {
  brands: BrandWithId[];
}

const Brands = ({ brands }: ListProps) => {
  const { brandIndexPath } = useRouterPaths();
  const [selectedBrand, setSelectedBrand] = useState<BrandWithId | null>(null);

  const handleEditModalOpen = useCallback(
    (brandId: string) => {
      const brand = brands.find((b) => b.id === brandId) ?? null;

      setSelectedBrand(brand);
    },
    [brands]
  );

  const handleEditModalClose = useCallback(() => {
    setSelectedBrand(null);
  }, []);

  return (
    <>
      <Row gutter={[15, 15]}>
        {brands?.map((brand) => (
          <Col key={brand.id} xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
            <Link to={`${brandIndexPath}/${brand.id}`}>
              <BrandCard brand={brand} onEdit={handleEditModalOpen} />
            </Link>
          </Col>
        ))}
      </Row>

      <EditBrandModal isOpen={!!selectedBrand} brand={selectedBrand} onClose={handleEditModalClose} brands={brands} />
    </>
  );
};

interface Props {
  sortType: BrandsSortType;
}

const BrandsList = ({ sortType }: Props) => {
  const brands = useBrands();

  const sortedBrandsData = useMemo(() => sortByName(brands.data, sortType), [brands.data, sortType]);

  if (brands.isPending) {
    return <Spin />;
  }

  if (brands.isError) {
    return <>Failed to load the brands. {brands.error.message}</>;
  }

  if (!sortedBrandsData) {
    return (
      <Flex justify="center">
        <h3>No brands exist yet.</h3>
      </Flex>
    );
  }

  return <Brands brands={sortedBrandsData} />;
};

export default BrandsList;
