import { useQuery } from "@tanstack/react-query";

import { useMemo } from "react";

import {
  DashboardView,
  Order,
  InsightTypeDefaultServiceType,
  InsightTypeDefaultServiceOptions,
  InsightTypeToServiceName,
} from "../../constants/creativeInteligence";
import { useCreativeIntelligenceStore } from "../../store/creativeIntelligence.store";
import { InsightsData, RawInsightsCreativeGroup, Tag } from "../../types/creativeInteligence";
import { ApiResponse } from "../../utils/api";
import {
  useBaseInsightsOptions,
  useSelectedDateRangeInsightsOptions,
  fetchInsightData,
} from "../insights/insights.queries";
import { InsightsBasicOptions, DynamicBreakdowns } from "../insights/types";
import { useKpiMetricName } from "../metrics.queries";

function getSortableTopMetrics(view: DashboardView, kpiMetricName: string) {
  if (view === DashboardView.Utilisation) {
    return {
      topDeliverablesNumber: 1,
      topUniqueCreativesNumber: 3,
      topAttributesValuesNumber: 10,
      sortBy: { field: DashboardView.Utilisation, order: Order.desc },
    };
  }

  const order = view === DashboardView.Bottom ? Order.asc : Order.desc;

  return {
    topDeliverablesNumber: 1,
    topUniqueCreativesNumber: 3,
    topAttributesValuesNumber: 10,
    sortBy: { field: kpiMetricName, order },
  };
}

export type TagInsightsResponse =
  | { attributes: InsightsData<RawInsightsCreativeGroup>; groups: undefined }
  | { groups: InsightsData<RawInsightsCreativeGroup>; attributes: undefined };

const select = ({ tagId, name }: Tag, response: ApiResponse<TagInsightsResponse>) => {
  return {
    items: response.data.attributes ? response.data.attributes : response.data.groups,
    tagId,
    name,
  };
};

const tagInsightsKey = "tagInsight";

type Options = InsightsBasicOptions & InsightTypeDefaultServiceType;

interface Props {
  tag: Tag;
  filter: DynamicBreakdowns | undefined;
}

export const useTagInsight = ({ tag, filter }: Props) => {
  const baseParams = useBaseInsightsOptions();
  const dateRangeBreakdowns = useSelectedDateRangeInsightsOptions();
  const kpiMetricName = useKpiMetricName();

  const dashboardView = useCreativeIntelligenceStore((state) => state.dashboardView);
  const selectedFilters = useCreativeIntelligenceStore((state) => state.selectedFilters);

  const tagOptions = tag.tagOptions;

  const advancedOptions = useMemo(() => {
    if (tagOptions.advancedOptions) {
      try {
        return JSON.parse(tagOptions.advancedOptions) as Record<string, unknown>;
      } catch {
        return InsightTypeDefaultServiceOptions[tagOptions.endpoint];
      }
    }

    return InsightTypeDefaultServiceOptions[tagOptions.endpoint];
  }, [tagOptions]);

  const options = useMemo(() => {
    if (!baseParams || !kpiMetricName || !dateRangeBreakdowns) {
      return {};
    }

    const filters = filter ?? selectedFilters;

    return {
      ...baseParams,
      dynamicBreakdowns: {
        ...dateRangeBreakdowns,
        ...filters,
      },
      ...getSortableTopMetrics(dashboardView, kpiMetricName),
      advancedSearch: tag.advancedSearch,
      ...advancedOptions,
    };
  }, [
    baseParams,
    kpiMetricName,
    dateRangeBreakdowns,
    filter,
    selectedFilters,
    dashboardView,
    tag.advancedSearch,
    advancedOptions,
  ]) as Options;

  return useQuery({
    queryKey: [tagInsightsKey, tagOptions.endpoint, options],
    queryFn: () => {
      return fetchInsightData<TagInsightsResponse, Options>(InsightTypeToServiceName[tagOptions.endpoint], options);
    },
    select: (response) => select(tag, response),
    enabled: !!options,
    staleTime: 1000 * 60, // 1 minute
  });
};
