import { useMemo } from "react";
import { Chart } from "react-chartjs-2";

import { type Metrics } from "../../../../../../../../types/deliverable";
import { useReportKPIs } from "../../../../../../queries/reportKPIs.query";

import { type DropoffDataset, getDropoffChartOptions, getDropoffDatasets } from "./utils";

import "./DropoffChart.scss";

interface Props {
  duration: number;
  metrics?: Metrics;
}

const DropoffChart = ({ metrics, duration }: Props) => {
  const reportKPIs = useReportKPIs();

  const datasets: DropoffDataset[] | undefined = useMemo(() => {
    if (reportKPIs.data && "metricStats" in reportKPIs.data) {
      return getDropoffDatasets(metrics, reportKPIs.data.metricStats, duration);
    }
  }, [duration, metrics, reportKPIs.data]);

  const options = useMemo(() => getDropoffChartOptions({ xMax: duration }), [duration]);

  return (
    <div className={`dropoff-chart__container ${!metrics || !duration ? "" : "dropoff-chart__container--no-data"}`}>
      {datasets && <Chart type="line" data={{ datasets }} options={options} />}
    </div>
  );
};

export default DropoffChart;
