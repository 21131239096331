import { useMemo } from "react";

import marketplaceAnnotations from "./marketAnnotations.json";
import { TagStats, useComputeTags } from "./computeTags.query";
import { TagsByCategory, useCurrentTagsByCategory } from "./tags.query";

export type MarketPlaceItem = (typeof marketplaceAnnotations)[number] & {
  isAdded: boolean;
  stats?: TagStats;
  isDisabled: boolean;
};

interface MarketPlaceByCategory {
  [category: string]: MarketPlaceItem[];
}

const initialMarketPlaceByCategory: MarketPlaceByCategory = marketplaceAnnotations.reduce((acc, item) => {
  const category = item.category;

  if (!acc[category]) {
    acc[category] = [];
  }

  acc[category].push({ ...item, isAdded: false, isDisabled: false });

  return acc;
}, {} as MarketPlaceByCategory);

const getMarketplaceItemsByCategory = (tagsByCategory: TagsByCategory, computeTagsData: Record<string, TagStats>) => {
  return marketplaceAnnotations.reduce((acc, marketplaceAnnotation) => {
    let isAdded = false;
    let isDisabled = false;
    let stats;
    const categoryTags = tagsByCategory[marketplaceAnnotation.category];

    if (categoryTags) {
      const tag = categoryTags.find(({ sourceTag }) => sourceTag === marketplaceAnnotation.tagId);

      if (tag) {
        isAdded = true;
      }
    }

    if (computeTagsData && marketplaceAnnotation.tagId) {
      stats = computeTagsData[marketplaceAnnotation.tagId];
      isDisabled = computeTagsData[marketplaceAnnotation.tagId].confidenceMetricStats?.count === 0;
    }

    if (!acc[marketplaceAnnotation.category]) {
      acc[marketplaceAnnotation.category] = [{ ...marketplaceAnnotation, isAdded, stats, isDisabled }];
    } else {
      acc[marketplaceAnnotation.category].push({ ...marketplaceAnnotation, isAdded, stats, isDisabled });
    }

    return acc;
  }, {} as MarketPlaceByCategory);
};

const defaultMarketplaceItems = marketplaceAnnotations.map(({ tagId, advancedSearch }) => ({ tagId, advancedSearch }));

const computeMarketTagsKey = "computeMarketTagsKey";

export const useMarketPlaceInsights = () => {
  const tagsByCategory = useCurrentTagsByCategory();

  const computeTags = useComputeTags({
    queryKey: computeMarketTagsKey,
    tags: defaultMarketplaceItems,
  });

  const marketplaceItems = useMemo(() => {
    if (!computeTags.data || !tagsByCategory.data) {
      return initialMarketPlaceByCategory;
    }

    return getMarketplaceItemsByCategory(tagsByCategory.data, computeTags.data);
  }, [computeTags.data, tagsByCategory.data]);

  return {
    data: marketplaceItems,
    isPending: tagsByCategory.isPending || computeTags.isPending,
  };
};
