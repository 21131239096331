import { useState } from "react";

import MainPageContent from "../../components/Layout/MainPageContent";

import BrandsListHeader from "./components/BrandListHeader";
import BrandsList from "./components/BrandList";

export enum BrandsSortType {
  NAME_AZ = "nameaz",
  NAME_ZA = "nameza",
}

const BrandListPage = () => {
  const [sortType, setSortType] = useState<BrandsSortType>(BrandsSortType.NAME_AZ);

  return (
    <MainPageContent header={<BrandsListHeader handleSort={setSortType} />}>
      <BrandsList sortType={sortType} />
    </MainPageContent>
  );
};

export default BrandListPage;
