import { Col, Flex, Row, Typography } from "antd";

import { SummaryStats } from "../../../../types/creativeInteligence";
import { Deliverable } from "../../../../types/deliverable";

import { transformReportTitleText } from "../../utils";

import UniqueCreativeDeliverableSummaryStats from "../UniqueCreative/UniqueCreativeDeliverableSummaryStats";
import UniqueCreativeCarouselDeliverables, {
  UniqueCreativeDeliverablesProps,
} from "../UniqueCreative/UniqueCreativeCarouselDeliverables";

import "./InsightContent.scss";

const { Text } = Typography;

export const InsightItem = ({
  label,
  advancedSearch,
  summaryStats,
  filter,
  deliverable,
}: Omit<InsightContentProps, "creativeHash">) => {
  return (
    <Col sm={24} md={12} lg={8}>
      <UniqueCreativeDeliverableSummaryStats
        rightLabel={transformReportTitleText(label)}
        summaryStats={summaryStats}
        deliverable={deliverable}
      >
        <UniqueCreativeCarouselDeliverables
          firstDeliverables={deliverable}
          totalDeliverable={summaryStats.count}
          advancedSearch={advancedSearch}
          filter={filter}
        />
      </UniqueCreativeDeliverableSummaryStats>
    </Col>
  );
};

export interface InsightContentProps {
  creativeHash: string;
  label: string;
  advancedSearch: string;
  summaryStats: SummaryStats;
  filter: UniqueCreativeDeliverablesProps["filter"];
  deliverable: Deliverable;
}

const InsightContent = ({
  loading,
  bestAttribute,
  entries,
}: {
  loading: boolean;
  bestAttribute: string | null | undefined;
  entries: InsightContentProps[];
}) => {
  if (loading) {
    return <Text>Loading...</Text>;
  }

  if (!bestAttribute) {
    return null;
  }

  return (
    <Flex align="center" justify="center" vertical gap="middle">
      <Row className="insights-content">
        {entries.map((entry) => (
          <InsightItem key={`${entry.creativeHash}-${entry.label}`} {...entry} />
        ))}
      </Row>
    </Flex>
  );
};

export default InsightContent;
