import { useMemo, useState } from "react";
import { Alert, Card, Space, Spin, Switch, Typography } from "antd";

import { useCurrentDashboardTags } from "../../../../queries/entities/dashboard.query";
import { CHART_COLORS, CHARTS } from "../../components/Charts/config";
import ChartWithTooltip from "../../components/Charts/Chart";

import { useDataExplorer } from "./dataExplorer.query";

import "./DataExplorer.scss";

const { Text } = Typography;

const DataExplorer = () => {
  const currentTags = useCurrentDashboardTags();
  const { data, totalCount, isPending, isError, tagIndex } = useDataExplorer(currentTags.data);
  const currentTag = currentTags.data?.[tagIndex];

  const [logScale, setLogScale] = useState(true);

  const datasets = useMemo(() => {
    if (!currentTags.data || !data) {
      return [];
    }

    return data.map((dataExplorerSeries, seriesIndex) => {
      return {
        label: currentTags.data?.[seriesIndex].name ? `${currentTags.data[seriesIndex].name}` : `Series ${seriesIndex}`,
        data: dataExplorerSeries,
        metric: "kpiMetric" as const,
        backgroundColor: CHART_COLORS[seriesIndex % CHART_COLORS.length],
      };
    });
  }, [currentTags.data, data]);

  if (!currentTags.data?.length || !currentTag) {
    return <Card loading={currentTags.isPending}>No tag data</Card>;
  }

  if (isError) {
    return <Card>Error fetching insights</Card>;
  }

  return (
    <Card className="data-explorer__card" loading={currentTags.isPending}>
      {isPending && (
        <Alert
          message={
            <Text strong>
              {`Loading resources for "${currentTag.name}" (${tagIndex + 1}/${currentTags.data?.length})`}
            </Text>
          }
          description={`Fetched ${totalCount} resource elements`}
          type="info"
          showIcon
        />
      )}

      <Spin spinning={isPending}>
        <ChartWithTooltip
          className="data-explorer__chart-wrapper"
          type={CHARTS.SCATTER}
          dataSeries={datasets}
          isLogScale={logScale}
        />

        <Space size="small" className="data-explorer__log-switch">
          Log scale <Switch value={logScale} onChange={setLogScale} data-testid="log-scale-switch" />
        </Space>
      </Spin>
    </Card>
  );
};

export default DataExplorer;
