import { useState, useCallback } from "react";
import { Button, message } from "antd";

import { analyticsApiRequest } from "../../../utils/analytics.api";

const SYNCDATA_COPY_URL = "/syncdata/okta/copy";

interface SyncDataSource {
  dashboardId: string;
  stack: string;
  environment: string;
}

export async function fetchSyncData(dashboardId: string) {
  const query = new URLSearchParams({ dashboardId });

  return analyticsApiRequest<SyncDataSource>(`${SYNCDATA_COPY_URL}?${query.toString()}`);
}

const CopyResourcesButton = ({ dashboardId }: { dashboardId: string }) => {
  const [isSyncing, setIsSyncing] = useState(false);

  const handleResourcesCopy = useCallback(async () => {
    setIsSyncing(true);

    const { data } = await fetchSyncData(dashboardId);

    try {
      await navigator.clipboard.writeText(JSON.stringify(data));

      message.success({ content: "Resources copied to clipboard", duration: 3 });
    } catch {
      message.error({ content: "Clipboard API is not supported", duration: 3 });
    } finally {
      setIsSyncing(false);
    }
  }, [dashboardId]);

  return (
    <Button
      data-testid="edit-dashboard-modal-enriched-resources-copy-button"
      onClick={handleResourcesCopy}
      loading={isSyncing}
    >
      Copy resources
    </Button>
  );
};

export default CopyResourcesButton;
