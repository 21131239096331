import { useQuery } from "@tanstack/react-query";

import { DeliveryEntity } from "../../types/entities";
import { entityRequest } from "../../utils/entity.api";

const DELIVERY_API_URL = "/delivery";
const DELIVERY_QUERY = "delivery";

export function useDelivery(deliveryId: string | undefined) {
  return useQuery({
    queryKey: [DELIVERY_QUERY, deliveryId],
    queryFn: () => {
      return entityRequest<DeliveryEntity>(`${DELIVERY_API_URL}/${deliveryId}`);
    },
    enabled: !!deliveryId,
  });
}
