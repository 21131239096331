import { useInfiniteQuery, useQuery } from "@tanstack/react-query";

import { InsightsEndpoint, Order } from "../../constants/creativeInteligence";
import { useCurrentMetrics, discardThresholdsToMetric } from "../metrics.queries";
import { useConfigStore } from "../../store/config/config.store";
import { analyticsApiRequest } from "../../utils/analytics.api";
import { useCreativeIntelligenceStore } from "../../store/creativeIntelligence.store";
import { useCurrentDashboard } from "../entities/dashboard.query";

import { InsightsBasicOptions } from "./types";
import { useCampaignInfo } from "./campaignInfo.queries";

export async function fetchInsightData<ResponseData, Options extends InsightsBasicOptions>(
  endpoint: InsightsEndpoint,
  options: Options,
  nextPageToken?: string
) {
  return analyticsApiRequest<ResponseData, Options>(`/query/okta/insights/${endpoint}`, "POST", {
    ...options,
    nextPageToken,
  });
}

export function useBaseInsightsOptions() {
  const currentDashboard = useCurrentDashboard();
  const currentMetrics = useCurrentMetrics();
  const storageBucket = useConfigStore((state) => state.storageBucket);

  const resourceGroupIds = currentDashboard.data?.resourceGroupIds ?? [];

  if (!currentDashboard.data || !currentMetrics.data || !storageBucket) {
    return;
  }

  const metrics = currentMetrics.data;

  const discardMetricThresholds = currentMetrics.data.discardThresholds
    ? discardThresholdsToMetric(currentMetrics.data.discardThresholds)
    : undefined;

  return {
    dashboardId: currentDashboard.data.id,
    kpiMetric: metrics.kpiMetric.metric,
    confidenceMetric: metrics.confidenceMetric.metric,
    kpiBenchmarkValue: metrics.kpiMetric.value,
    workerIds: resourceGroupIds,
    storageBucket,
    discardThresholds: discardMetricThresholds,
    topDeliverablesNumber: 1,
    topUniqueCreativesNumber: 1,
  };
}

export function useDateRangeInsightsOptions() {
  const campaignInfo = useCampaignInfo();

  if (!campaignInfo.data) {
    return;
  }

  return {
    "adMetadata.startDate": [campaignInfo.data.dateRange.minDate],
    "adMetadata.endDate": [campaignInfo.data.dateRange.maxDate],
  };
}

export function useSelectedDateRangeInsightsOptions() {
  const selectedDateRange = useCreativeIntelligenceStore((state) => state.selectedDateRange);

  if (!selectedDateRange) {
    return;
  }

  return {
    "adMetadata.startDate": [selectedDateRange.startDate],
    "adMetadata.endDate": [selectedDateRange.endDate],
  };
}

function useInisghtsOptions<Options>() {
  const baseParams = useBaseInsightsOptions();
  const currentMetrics = useCurrentMetrics();
  const dateRangeBreakdowns = useSelectedDateRangeInsightsOptions();

  if (!baseParams || !currentMetrics.data || !dateRangeBreakdowns) {
    return {};
  }

  return {
    ...baseParams,
    dynamicBreakdowns: dateRangeBreakdowns,
    sortBy: { field: currentMetrics.data.kpiMetric.metric, order: Order.desc },
  } as Options;
}

interface InsightsQueryOptions<Options> {
  queryKey: string;
  endpoint: InsightsEndpoint;
  extraOptions: Partial<Options>;
}

export function useInsights<ResponseData, Options extends InsightsBasicOptions = InsightsBasicOptions>({
  queryKey,
  endpoint,
  extraOptions = {},
}: InsightsQueryOptions<Options>) {
  const options = useInisghtsOptions<Options>();

  return useQuery({
    queryKey: [queryKey, endpoint, options, extraOptions],
    queryFn: () => {
      return fetchInsightData<ResponseData, Options>(endpoint, { ...options, ...extraOptions } as Options);
    },
    select: (response) => response?.data,
    enabled: !!options,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });
}

export function useInfiniteInsights<ResponseData, Options extends InsightsBasicOptions = InsightsBasicOptions>({
  queryKey,
  endpoint,
  extraOptions = {},
}: InsightsQueryOptions<Options>) {
  const options = useInisghtsOptions<Options>();

  return useInfiniteQuery({
    queryKey: [queryKey, endpoint, options, extraOptions],
    queryFn: ({ pageParam }) => {
      return fetchInsightData<ResponseData, Options>(endpoint, { ...options, ...extraOptions } as Options, pageParam);
    },
    getNextPageParam: (resp) => resp?.pagination?.nextPageToken,
    initialPageParam: "",
    staleTime: 1000 * 60 * 5, // 5 minutes
    enabled: !!options,
  });
}
