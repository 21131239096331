import { useCallback, useMemo, useState } from "react";

import { Link, useParams } from "react-router-dom";
import { Col, Flex, Row, Spin } from "antd";

import { DashboardWithId } from "../../../types/entities";
import { useRouterPaths } from "../../../hooks/useRouterPaths";
import { useBrandDashboards } from "../../../queries/entities/brandDashboards.query";

import { DashboardsSortType } from "../DashboardListPage";

import { DashboardCard } from "./DashboardCard";
import { EditDashboardModal } from "./EditDashbordModal";

function sortByName<T extends { name: string }>(data: T[] | void, sortType: DashboardsSortType) {
  return data?.sort((a, b) => {
    if (sortType === DashboardsSortType.NAME_AZ) {
      return a.name.localeCompare(b.name);
    }

    if (sortType === DashboardsSortType.NAME_ZA) {
      return b.name.localeCompare(a.name);
    }

    return 0;
  });
}

interface ListProps {
  dashboards: DashboardWithId[];
  brandId: string;
}

const Dashboards = ({ brandId, dashboards }: ListProps) => {
  const { dashboardIndexPath } = useRouterPaths();

  const [selectedDashboard, setSelectedDashboard] = useState<DashboardWithId>();

  const handleEditModalOpen = useCallback(
    (dashboardId: string) => {
      const dashboard = dashboards.find(({ id }) => id === dashboardId);

      setSelectedDashboard(dashboard);
    },
    [dashboards]
  );

  const handleEditModalClose = useCallback(() => {
    setSelectedDashboard(undefined);
  }, []);

  return (
    <>
      <Row gutter={[15, 15]}>
        {dashboards?.map((userDashboard) => (
          <Col key={userDashboard.id} xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
            <Link to={`${dashboardIndexPath}/${userDashboard.id}`}>
              <DashboardCard dashboard={userDashboard} onEdit={handleEditModalOpen} />
            </Link>
          </Col>
        ))}
      </Row>

      <EditDashboardModal
        isOpen={!!selectedDashboard}
        brandId={brandId}
        dashboard={selectedDashboard}
        onClose={handleEditModalClose}
      />
    </>
  );
};

interface Props {
  sortType: DashboardsSortType;
}

const DashboardList = ({ sortType }: Props) => {
  const { brandId } = useParams();
  const dashboards = useBrandDashboards(brandId);

  const sortedDashboardsData = useMemo(() => sortByName(dashboards.data?.items, sortType), [dashboards.data, sortType]);

  if (dashboards.isPending) {
    return <Spin />;
  }

  if (dashboards.isError) {
    return <>Failed to load dashboards. {dashboards.error.message}</>;
  }

  if (!sortedDashboardsData?.length) {
    return (
      <Flex justify="center">
        <h3>No dashboards exist for this brand.</h3>
      </Flex>
    );
  }

  return <Dashboards dashboards={sortedDashboardsData} brandId={brandId} />;
};

export default DashboardList;
