import { useCallback, useState } from "react";
import { Button, Modal, Flex, Input, Form, message } from "antd";
import { useNavigate } from "react-router-dom";

import { Tag } from "../../../../../types/creativeInteligence";
import { UpdateTagPayload, useUpdateTag } from "../../../../../queries/tags/tag.mutations";
import { useRouterPaths } from "../../../../../hooks/useRouterPaths";

import DeleteTagModal from "../../DeleteTagModal";

type MessageApiType = ReturnType<typeof message.useMessage>[0];

const EditTagModal = ({
  tag,
  brandId,
  dashboardId,
  isModalOpen,
  onClose,
  messageApi,
}: {
  tag: Tag;
  brandId: string;
  dashboardId: string;
  isModalOpen: boolean;
  onClose: () => void;
  messageApi: MessageApiType;
}) => {
  const navigate = useNavigate();
  const { dashboardPath } = useRouterPaths();

  const updateTag = useUpdateTag();
  const [form] = Form.useForm<UpdateTagPayload>();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const openDeleteModal = useCallback(() => setIsDeleteModalOpen(true), []);
  const closeDeleteModal = useCallback(() => setIsDeleteModalOpen(false), []);

  const handleOk = useCallback(async () => {
    try {
      const newValues = await form.validateFields();

      await updateTag.mutateAsync({
        tag: newValues,
        tagId: tag.tagId,
        dashboardId,
      });

      messageApi.success("Search query has been updated");
      onClose();
    } catch {
      messageApi.error("Search query hasn't been updated");
    }
  }, [form, updateTag, tag.tagId, dashboardId, onClose, messageApi]);

  const handleClose = useCallback(() => {
    closeDeleteModal();
    onClose();
  }, [closeDeleteModal, onClose]);

  const handleOnDelete = useCallback(() => {
    navigate(dashboardPath);
  }, [dashboardPath, navigate]);

  return (
    <>
      <Modal
        title="Edit search tag"
        open={isModalOpen}
        onOk={handleOk}
        okButtonProps={{ htmlType: "submit" }}
        centered
        destroyOnClose
        onCancel={handleClose}
        className="edit-tag-modal"
        footer={[
          <Button key="delete" danger onClick={openDeleteModal} data-testid="advanced-search-open-delete-modal-button">
            Delete
          </Button>,

          <Flex key="operation" gap="small">
            <Button key="back" onClick={handleClose} data-testid="advanced-search-modal-cancel-button">
              Close
            </Button>

            <Button
              key="submit"
              type="primary"
              onClick={handleOk}
              loading={updateTag.isPending}
              data-testid="advanced-search-modal-save-button"
            >
              Save
            </Button>
          </Flex>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          preserve={false}
          initialValues={{
            name: tag.name,
            advancedSearch: tag.advancedSearch,
          }}
        >
          <Form.Item label="Name" name="name" rules={[{ required: true, message: "Please input the tag name!" }]}>
            <Input data-testid="advanced-search-modal-edit-input-name" />
          </Form.Item>
          <Form.Item
            label="Query"
            name="advancedSearch"
            rules={[{ required: true, message: "Please input the query!" }]}
          >
            <Input data-testid="advanced-search-modal-edit-input-query" />
          </Form.Item>
        </Form>
      </Modal>

      <DeleteTagModal
        isOpen={isDeleteModalOpen}
        key="delete"
        tagId={tag.tagId}
        brandId={brandId}
        dashboardId={dashboardId}
        onClose={closeDeleteModal}
        onDelete={handleOnDelete}
      />
    </>
  );
};

export default EditTagModal;
