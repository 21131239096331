import React, { Fragment, useEffect, useMemo, useState, useCallback } from "react";
import { usePopper } from "react-popper";

import { SummaryStats } from "../../../../../types/creativeInteligence";
import { utilisationDisplayFormatter } from "../../../../../utils";
import { getMetricStat } from "../../../../../utils/analytics";

import ImageCachingService from "./ImageCachingService";

import "./Tooltip.scss";

export interface TooltipDataProps {
  imgUrls: string[];
  dataPoints: {
    label: string;
    attributeConfidence: number | undefined;
    summaryStats: SummaryStats;
  }[];
}

const Tooltip = ({ kpiMetricName, data }: { kpiMetricName: string; data: TooltipDataProps }) => {
  const [currentImage, setCurrentImage] = useState<HTMLImageElement | null>(null);
  const [imgUrlIndex, setImgUrlIndex] = useState(0);

  const loadImage = useCallback(async () => {
    const url = data.imgUrls[imgUrlIndex];

    try {
      const img = await ImageCachingService.getImage(url);

      setCurrentImage(img);
    } catch (error) {
      console.error("Error loading image:", error);
      setCurrentImage(null);
    }
  }, [imgUrlIndex, data.imgUrls]);

  useEffect(() => {
    void loadImage();
  }, [loadImage]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setImgUrlIndex((index) => (index + 1) % data.imgUrls.length);
    }, 1500);

    return () => clearInterval(intervalId);
  }, [data.imgUrls.length]);

  useEffect(() => {
    return () => {
      const urlsToPreserve = new Set(data.imgUrls);

      ImageCachingService.clearStaleImages(urlsToPreserve);
    };
  }, [data.imgUrls]);

  const headerParams = useMemo(() => {
    return data.dataPoints.map(({ attributeConfidence, summaryStats, label }) => {
      const { count, totalCount, utilisation, kpiMetric } = summaryStats;

      const utilisationValue = utilisationDisplayFormatter(utilisation);
      const kpiValue = kpiMetric?.[getMetricStat(kpiMetricName)]?.toFixed(3) ?? "N/A";

      const confidence = attributeConfidence ? ` (Confidence: ${Math.round(attributeConfidence)} %)` : "";

      return {
        label,
        count,
        totalCount,
        utilisationValue,
        kpiValue,
        confidence,
      };
    });
  }, [data.dataPoints, kpiMetricName]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setImgUrlIndex((index) => (index + 1) % data.imgUrls.length);
    }, 1500);

    return () => clearInterval(intervalId);
  }, [data.imgUrls]);

  return (
    <div>
      <dl className="chart-tooltip__header">
        {headerParams.map((params) => (
          <Fragment key={params.label}>
            <dt>{params.label}</dt>
            <dd>
              <div>
                Found in {params.utilisationValue} of ads ({params.count} of {params.totalCount})
              </div>
              <div>
                KPI: {params.kpiValue} {params.confidence}
              </div>
            </dd>
          </Fragment>
        ))}
      </dl>

      <div className="chart-tooltip__img-wrapper">
        <div className={`chart-tooltip__img-animation ${data.imgUrls.length > 1 ? "animate" : ""}`} />

        {currentImage ? (
          <img className="chart-tooltip__img" src={currentImage.src} alt="Creative" />
        ) : (
          <div className="chart-tooltip__no-data">{headerParams[0].label}: No matching data.</div>
        )}
      </div>
    </div>
  );
};

export const TooltipPopover = ({ x, y, children }: { x: number; y: number; children: React.ReactNode }) => {
  const [anchorRef, setAnchorRef] = useState<HTMLDivElement | null>(null);
  const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(anchorRef, tooltipRef, {
    placement: "top-start",
    modifiers: [
      {
        name: "preventOverflow",
        options: {
          padding: 10,
          tether: false,
          altAxis: true,
        },
      },
      { name: "offset", options: { offset: [x, -y] } },
    ],
  });

  return (
    <>
      <div ref={setAnchorRef} />

      <div ref={setTooltipRef} className="chart-tooltip__wrapper" style={styles.popper} {...attributes.popper}>
        <div className="chart-tooltip__container">{children}</div>
      </div>
    </>
  );
};

export default Tooltip;
