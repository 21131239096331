import { useCallback, useMemo } from "react";

import { SummaryStats } from "../../../../../types/creativeInteligence";
import { useCurrentMetrics } from "../../../../../queries/metrics.queries";

import { BaseChartDataset, BaseDataPoint, ChartTypes, BaseChartDatasetConfig, CHARTS } from "../config";
import { genericGetPointValue } from "../utils/getPointValues";
import { insightSeriesToDataset } from "../utils/insightSeriesToDataset";

export function useChart({
  type,
  dataSeries,
  isUtilization,
}: {
  type: ChartTypes;
  dataSeries: BaseChartDatasetConfig[];
  isUtilization: boolean;
}) {
  const currentMetrics = useCurrentMetrics();

  const getPointValue = useCallback(
    (summaryStats: SummaryStats, metric: "kpiMetric" | "confidenceMetric", datasetSummaryStats?: SummaryStats[]) =>
      genericGetPointValue(type, currentMetrics.data, isUtilization, summaryStats, metric, datasetSummaryStats),
    [type, currentMetrics.data, isUtilization]
  );

  const labels = useMemo(() => {
    const labelSet = new Set<string>();

    for (const series of dataSeries) {
      const s = insightSeriesToDataset(series, getPointValue);

      s[0].forEach((label) => labelSet.add(label));
    }

    return labelSet;
  }, [dataSeries, getPointValue]);

  const truncatedLabels = useMemo(() => {
    return Array.from(labels).map((label) => {
      const trimmedLabel = label.trim();
      const truncated = trimmedLabel.length > 10 ? `${trimmedLabel.slice(0, 10)}...` : trimmedLabel;

      return truncated;
    });
  }, [labels]);

  const data = useMemo(() => {
    const datasets = [] as BaseChartDataset<BaseDataPoint<number | { x: number; y: number }>[]>[];

    for (const series of dataSeries) {
      const s = insightSeriesToDataset(series, getPointValue);

      datasets.push(s[1]);
    }

    if (type === CHARTS.TREEMAP) {
      return {
        datasets: datasets.map((dataset) => ({
          ...dataset,
          tree: dataset.data,
          data: [],
        })),
        labels: truncatedLabels,
      };
    }

    return {
      datasets,
      labels: truncatedLabels,
    };
  }, [dataSeries, getPointValue, type, truncatedLabels]);

  return {
    data,
    multiDataset: data.datasets.length > 1,
  };
}
