import { useMemo } from "react";
import { Chart as ChartJS, ChartType, TooltipModel } from "chart.js";
import { Chart } from "react-chartjs-2";

import { useCurrentMetrics } from "../../../../queries/metrics.queries";

import Tooltip, { TooltipPopover } from "./Tooltip/Tooltip";
import { useTooltipData } from "./Tooltip/utils";
import { BaseChartDatasetConfig, ChartTypes, typeToChartType } from "./config";
import { useChart } from "./hooks/useChartData";
import { useChartOptions } from "./hooks/useChartOptions";
import { useChartScales } from "./hooks/useChartScales";
import { useChartPlugins } from "./hooks/useChartPlugins";

interface TooltipChartProps {
  dataSeries: BaseChartDatasetConfig[];
  isUtilization?: boolean;
  isLogScale?: boolean;
  onElementClick?: (reportIds: string[]) => void;
  type: ChartTypes;
}

interface ChartProps extends TooltipChartProps {
  tooltipCallback?: ({ chart, tooltip }: { chart: ChartJS; tooltip: TooltipModel<ChartType> }) => void;
}

export const ChartComponent = ({
  type,
  tooltipCallback,
  onElementClick,
  dataSeries,
  isUtilization = false,
  isLogScale = false,
}: ChartProps) => {
  const { data, multiDataset } = useChart({
    type,
    dataSeries,
    isUtilization,
  });

  const optionsWithoutScales = useChartOptions({
    type,
    tooltipCallback,
    onElementClick,
    multiDataset,
  });

  const scales = useChartScales({
    type,
    datasets: data.datasets,
    isUtilization,
    isLogScale,
  });

  const plugins = useChartPlugins({ type });

  const options = useMemo(
    () => ({
      ...optionsWithoutScales,
      scales,
    }),
    [optionsWithoutScales, scales]
  );

  return <Chart type={typeToChartType[type]} data={data} options={options} plugins={plugins} />;
};

const ChartWithTooltip = ({ className, ...props }: TooltipChartProps & { className: string }) => {
  const currentMetrics = useCurrentMetrics();
  const { tooltipPos, tooltipDataProps, tooltipCallback } = useTooltipData();

  const kpiMetricName = currentMetrics.data?.kpiMetric.metric;

  return (
    <div className={className}>
      {tooltipDataProps && kpiMetricName && (
        <TooltipPopover x={tooltipPos.x} y={tooltipPos.y}>
          <Tooltip kpiMetricName={kpiMetricName} data={tooltipDataProps} />
        </TooltipPopover>
      )}

      <ChartComponent {...props} tooltipCallback={tooltipCallback} />
    </div>
  );
};

export default ChartWithTooltip;
