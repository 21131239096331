import { useCallback, useEffect, useMemo } from "react";
import { Button, Drawer, Pagination, Flex, Slider, Spin, Alert } from "antd";
import { PlayCircleOutlined, PauseCircleOutlined } from "@ant-design/icons";

import { useVideoPlayer } from "../../../../utilityHooks/useVideoPlayer";
import { useReportIdsSearchParams } from "../../../../hooks/useReportIdsParam";

import { useDeliverablesData } from "../../queries";

import TimelineDeliverable from "./components/TimelineDeliverable/TimelineDeliverable";

import "./TimelineDrawer.scss";

const TimelineDrawer = () => {
  const { reportIdIndex, reportIds, setReportIdIndex, setReportIds } = useReportIdsSearchParams();
  const videoPlayer = useVideoPlayer();

  const currentReportId = reportIds[reportIdIndex - 1];
  const currentDeliverable = useDeliverablesData(currentReportId);

  const hasVideo = !!currentDeliverable.data?.signedVideoUrl;

  const handleOnClose = useCallback(() => {
    setReportIds([]);
    setReportIdIndex(0);
  }, [setReportIdIndex, setReportIds]);

  useEffect(() => {
    if (!hasVideo) {
      videoPlayer.seekToProgress(0);
    }
  }, [hasVideo, videoPlayer]);

  const footer = (
    <Flex gap="small">
      <Button
        size="large"
        disabled={!hasVideo}
        onClick={videoPlayer.playPause}
        icon={videoPlayer.isPlaying ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
      />

      <Slider
        className="timeline-drawer__slider"
        disabled={!hasVideo}
        defaultValue={0}
        value={videoPlayer.progress}
        onChange={videoPlayer.seekToProgress}
      />
    </Flex>
  );

  const content = useMemo(() => {
    if (currentDeliverable.isPending) {
      return <Spin className="timeline-drawer__container" />;
    }

    if (!reportIds.length) {
      return <Alert type="error" message="No deliverables to show." />;
    }

    if (currentDeliverable.isError || !currentDeliverable.data) {
      return <Alert type="error" message="Failed to load deliverables." />;
    }

    return (
      <TimelineDeliverable
        progress={videoPlayer.progress}
        duration={hasVideo ? videoPlayer.duration : 1}
        hasVideo={hasVideo}
        videoRef={videoPlayer.setVideoNode}
        deliverable={currentDeliverable.data}
      />
    );
  }, [
    currentDeliverable.data,
    currentDeliverable.isError,
    currentDeliverable.isPending,
    hasVideo,
    reportIds.length,
    videoPlayer.duration,
    videoPlayer.progress,
    videoPlayer.setVideoNode,
  ]);

  return (
    <Drawer
      title={currentDeliverable.data?.reportingLabel ?? "Timeline"}
      placement="right"
      size="large"
      onClose={handleOnClose}
      open={!!reportIds.length}
      width="90%"
      classNames={{
        header: "timeline-drawer__header",
        body: "timeline-drawer__body",
        footer: "timeline-drawer__footer",
      }}
      extra={
        <Pagination
          className="timeline-drawer__pagination"
          current={reportIdIndex}
          total={reportIds.length}
          pageSize={1}
          onChange={setReportIdIndex}
        />
      }
      footer={footer}
    >
      {content}
    </Drawer>
  );
};

export default TimelineDrawer;
