import { ScriptableContext, ChartType } from "chart.js";

import { SummaryStats, UniqueCreativesGroups } from "../../../../../types/creativeInteligence";

import { BaseChartDatasetConfig, BaseDataPoint, BaseChartDataset } from "../config";

import { getPointColors } from "./getPointColors";

export function insightSeriesToDataset<TData = number>(
  series: BaseChartDatasetConfig,
  getPointValue: (
    summaryStats: SummaryStats,
    metric: "kpiMetric" | "confidenceMetric",
    datasetSummaryStats?: SummaryStats[]
  ) => TData
) {
  const labels = Object.keys(series.data).sort((a, b) => a.localeCompare(b));

  const datasetSummaryStats = labels.reduce<SummaryStats[]>((acc, label) => {
    if (series.data[label].summaryStats) {
      acc.push(series.data[label].summaryStats);
    }

    return acc;
  }, []);

  const dataset = {
    backgroundColor:
      series.backgroundColor ?? ((ctx: ScriptableContext<ChartType>) => dataset.backgroundColors[ctx.dataIndex]),
    key: "value",
    label: series.label,
    metric: series.metric,
    data: [] as BaseDataPoint[],
    yAxisID: series.yAxisID,
    backgroundColors: [] as string[],
    creatives: [] as UniqueCreativesGroups[][],
    summaryStats: datasetSummaryStats,
  } as BaseChartDataset<BaseDataPoint<TData>[]>;

  labels.forEach((label, i) => {
    if (series.data[label].summaryStats) {
      const pointColors = getPointColors(label, i);

      dataset.backgroundColors.push(pointColors.backgroundColor);
      dataset.creatives.push(series.data[label].uniqueCreativesGroups);

      dataset.data.push({
        label,
        value: getPointValue(series.data[label].summaryStats, series.metric, datasetSummaryStats),
      });
    }
  });

  return [labels, dataset] as const;
}
