import { useCallback } from "react";
import { Modal, message } from "antd";

import { useDeleteDashboardTag } from "../../../queries/entities/dashboard.actions";

const DeleteTagModal = ({
  isOpen,
  tagId,
  brandId,
  dashboardId,
  onDelete,
  onClose,
}: {
  isOpen: boolean;
  tagId: string;
  brandId: string;
  dashboardId: string;
  onDelete?: () => void;
  onClose?: () => void;
}) => {
  const deleteTag = useDeleteDashboardTag(brandId, dashboardId);

  const handleDelete = useCallback(async () => {
    try {
      await deleteTag.remove(tagId);

      if (onDelete) {
        onDelete();
      }

      message.success("Tag has been deleted");
    } catch {
      message.error("Tag hasn't been deleted");
    }
  }, [tagId, deleteTag, onDelete]);

  return (
    <Modal
      title="Are you sure?"
      open={isOpen}
      onOk={handleDelete}
      centered
      okText="Delete"
      okButtonProps={{ loading: deleteTag.isPending, ["data-testid"]: "advanced-search-delete-modal-delete-button" }}
      cancelButtonProps={{ ["data-testid"]: "advanced-search-delete-modal-cancel-button" }}
      onCancel={onClose}
      destroyOnClose
    />
  );
};

export default DeleteTagModal;
