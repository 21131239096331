import { ReactNode } from "react";
import { Layout } from "antd";

import "./MainPageContentHeader.scss";

const MainPageContentHeader = ({ children }: { children: ReactNode }) => {
  return <Layout.Header className="main-content-header">{children}</Layout.Header>;
};

export default MainPageContentHeader;
