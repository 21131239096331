import { useMemo } from "react";
import { Checkbox, Row, Col, Form } from "antd";

import { DomainConfig } from "../../../store/config/types";
import { Inventory, SelfServiceExtractor } from "../../../types/entities";

const ExtractorForm = ({ config, inventory, show }: { config: DomainConfig; inventory: Inventory; show: boolean }) => {
  const metricsOptions = useMemo(() => {
    return config.inventoryResources[inventory.resourceType].metrics.map(({ name, required }) => ({
      value: name,
      disabled: required,
    }));
  }, [config.inventoryResources, inventory.resourceType]);

  const breakdownsOptions = useMemo(() => {
    return config.inventoryResources[inventory.resourceType]?.breakdowns.map((breakdown: string[]) => {
      return breakdown.join(", ");
    });
  }, [config.inventoryResources, inventory.resourceType]);

  return (
    <>
      <Form.Item name="metricNames" label="Metrics" rules={[{ required: true }]} hidden={!show}>
        <Checkbox.Group>
          <Row>
            {metricsOptions?.map((option) => {
              return (
                <Col key={option.value} span={24}>
                  <Checkbox value={option.value} disabled={option.disabled}>
                    {option.value}
                  </Checkbox>
                </Col>
              );
            })}
          </Row>
        </Checkbox.Group>
      </Form.Item>

      {inventory.resourceType !== SelfServiceExtractor.EXTDV360_INVENTORY && (
        <Form.Item name="breakdowns" label="Breakdowns" hidden={!show}>
          <Checkbox.Group>
            <Row>
              {breakdownsOptions?.map((option) => {
                return (
                  <Col key={option} span={24}>
                    <Checkbox value={option}>{option}</Checkbox>
                  </Col>
                );
              })}
            </Row>
          </Checkbox.Group>
        </Form.Item>
      )}
    </>
  );
};

export default ExtractorForm;
