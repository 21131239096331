import { useMemo } from "react";
import { Chart as ChartJS } from "chart.js";

import { useCurrentMetrics } from "../../../../../queries/metrics.queries";

import { ChartTypes, CHARTS } from "../config";
import { drawQuadrants } from "../utils/drawQuadrants";

export function useChartPlugins({ type }: { type: ChartTypes }) {
  const currentMetrics = useCurrentMetrics();

  const kpiMetricValue = currentMetrics.data?.kpiMetric.value;
  const confidenceMetricValue = currentMetrics.data?.confidenceMetric.value;

  return useMemo(() => {
    const plugins = [];

    if (type === CHARTS.SCATTER) {
      plugins.push({
        id: "drawQuadrant",
        beforeDraw: (chart: ChartJS) => drawQuadrants(chart, kpiMetricValue ?? 0, confidenceMetricValue ?? 0),
      });
    }

    return plugins;
  }, [type, kpiMetricValue, confidenceMetricValue]);
}
