import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { DashboardEntity } from "../../types/entities";
import { Tag } from "../../types/creativeInteligence";
import { domoDashboardRequest, entityRequest } from "../../utils/entity.api";
import { useAuthStore } from "../../store/auth/auth.store";

import { useTags } from "../tags/tags.query";

import { useUpdateDashboard } from "./dashboard.mutations";

export const DASHBOARD_QUERY_KEY = "dashboard";
export const DOMO_DASHBOARD_QUERY_KEY = "domo";

export const DASHBOARD_API_URL = "/dashboard";

export function useDashboard<T = DashboardEntity>(
  dashboardId: string | undefined,
  select?: (dashboard: DashboardEntity) => T
) {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  return useQuery({
    queryKey: [DASHBOARD_QUERY_KEY, dashboardId],
    queryFn: () => entityRequest<DashboardEntity>(`${DASHBOARD_API_URL}/${dashboardId}`),
    select,
    enabled: !!isAuthenticated && !!dashboardId,
  });
}

export function useDomoDashboard<T = string>(
  domoDashboardUrl: string | undefined,
  select?: (domoDashboardId: string) => T
) {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const dashboardId = domoDashboardUrl?.split("/").at(-1);

  return useQuery({
    queryKey: [DOMO_DASHBOARD_QUERY_KEY, dashboardId],
    queryFn: () => domoDashboardRequest<string>(dashboardId!),
    select,
    enabled: !!isAuthenticated && !!dashboardId,
  });
}

export const useCurrentDashboard = () => {
  const { dashboardId } = useParams();

  return useDashboard(dashboardId);
};

function useDashboardTags(brandId: string, dashboardId: string) {
  const tags = useTags(dashboardId);
  const dashboard = useDashboard(dashboardId);
  const updateDashboard = useUpdateDashboard();

  useEffect(() => {
    if (dashboard.isLoading || tags.isLoading) {
      return;
    }

    if (
      tags.data?.find(({ tagId }) => !dashboard.data?.tagsIds?.includes(tagId))
      || dashboard.data?.tagsIds?.find((tagId) => !tags.data?.find(({ tagId: id }) => id === tagId))
    ) {
      /* this shouldn't be necessary, but will sync the tag data with the dashboard entity since the backend currently doesn't  */
      console.info("Some tags were missing in the dashboard");

      updateDashboard.mutate({
        brandId,
        dashboardId,
        dashboardData: {
          tagsIds: tags.data?.map((tag) => tag.tagId),
        },
      });
    }
  }, [brandId, dashboard.data?.tagsIds, dashboard.isLoading, dashboardId, tags.data, tags.isLoading, updateDashboard]);

  const data = useMemo(() => {
    if (!tags.data || !dashboard.data) {
      return;
    }

    return dashboard.data.tagsIds?.reduce<Tag[]>((acc, tagId) => {
      const tag = tags.data?.find((t) => t.tagId === tagId);

      if (tag) {
        acc.push(tag);
      }

      return acc;
    }, []);
  }, [dashboard.data, tags.data]);

  return {
    isPending: tags.isPending || dashboard.isPending,
    data,
  };
}

export function useCurrentDashboardTags() {
  const { brandId, dashboardId } = useParams();

  return useDashboardTags(brandId, dashboardId);
}
