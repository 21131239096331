import { ReactNode } from "react";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import { Layout } from "antd";

import MainSider from "./MainSider";

import "./MainPageContent.scss";

export interface IMainPageContentProps {
  children: ReactNode;
  header?: ReactNode;
  sidebarContent?: ReactNode;
  sidebarIsCollapsed?: boolean;
  className?: string;
  showCopyright?: boolean;
}

const MainPageContent = ({
  children,
  header,
  sidebarContent,
  sidebarIsCollapsed,
  className = "",
  showCopyright = false,
}: IMainPageContentProps) => {
  const location = useLocation();
  const classNames = `main-content ${className} ${showCopyright ? "main-content__with-copyright" : ""}`;

  return (
    <Layout>
      {!!sidebarContent && <MainSider defaultIsCollapsed={sidebarIsCollapsed}>{sidebarContent}</MainSider>}

      <Layout.Content className={className}>
        {header}
        <motion.div
          key={location.pathname}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className={classNames}
        >
          {children}
        </motion.div>
      </Layout.Content>
    </Layout>
  );
};

export default MainPageContent;
