import { useEffect } from "react";
import { Hub } from "aws-amplify/utils";

export function useAuthEvents(callback: (event: string, message: string | undefined) => void) {
  useEffect(() => {
    return Hub.listen("auth", ({ payload: { event, message } }) => {
      callback(event, message);
    });
  }, [callback]);
}
