class ImageCachingService {
  private static cache = new Map<string, HTMLImageElement>();

  static async getImage(url: string): Promise<HTMLImageElement> {
    const cachedImage = this.cache.get(url);

    if (cachedImage) {
      return cachedImage;
    }

    return new Promise((resolve, reject) => {
      const image = new Image();

      image.onload = () => {
        this.cache.set(url, image);
        resolve(image);
      };

      image.onerror = reject;
      image.src = url;
    });
  }

  static clearStaleImages(urlsToPreserve: Set<string>): void {
    this.cache.forEach((_value, key) => {
      if (!urlsToPreserve.has(key)) {
        this.cache.delete(key);
      }
    });
  }
}

export default ImageCachingService;
