import { InsightsData } from "../../types/creativeInteligence";
import { getMetricStat } from "../../utils/analytics";

export const transformReportTitleText = (inputText?: string) => {
  if (!inputText) {
    return "";
  }

  const words = inputText.toLowerCase().split("_");

  return words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
};

export const getBestAttributeValue = (groups: InsightsData | undefined, metric: string) => {
  if (!groups) {
    return null;
  }

  const sorted = Object.entries(groups).sort(([, groupA], [, groupB]) => {
    const kpiValueA = groupA.summaryStats.kpiMetric[getMetricStat(metric)];
    const kpiValueB = groupB.summaryStats.kpiMetric[getMetricStat(metric)];

    return kpiValueB - kpiValueA;
  });

  const bestGroupKey = sorted[0][0];
  const bestGroup = groups[bestGroupKey];

  if (!bestGroup.uniqueCreativesGroups.length) {
    return null;
  }

  return transformReportTitleText(bestGroupKey);
};
