import { useCallback, useEffect, useState } from "react";

interface VideoController {
  duration: number;
  isPlaying: boolean;
  playPause: () => void;
  progress: number;
  seekToProgress: (value: number) => void;
  setVideoNode: (node: HTMLVideoElement | null) => void;
}

export function useVideoPlayer(): VideoController {
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);

  const [videoNode, setVideoNode] = useState<HTMLVideoElement | null>(null);

  const playPause = useCallback(() => {
    if (!videoNode) return;

    if (videoNode.paused) {
      void videoNode.play();
    } else {
      videoNode.pause();
    }
  }, [videoNode]);

  const seekToProgress = useCallback(
    (value: number) => {
      if (!videoNode?.duration) return;

      setVideoNode((prev) => {
        if (!prev) return prev;

        prev.currentTime = (value / 100) * prev.duration;

        return prev;
      });

      setProgress(value);
    },
    [videoNode]
  );

  useEffect(() => {
    if (!videoNode) return;

    const onDurationChange = () => {
      if (!videoNode) return;

      setDuration(videoNode.duration);
    };

    const onTimeUpdate = () => {
      if (!videoNode?.duration) return;

      setProgress((videoNode.currentTime / videoNode.duration) * 100);
    };

    const onPlayPause = () => {
      if (!videoNode) return;

      setIsPlaying(!videoNode.paused);
    };

    setVideoNode((prev) => {
      if (!prev) return prev;

      prev.ondurationchange = onDurationChange;
      prev.ontimeupdate = onTimeUpdate;
      prev.onplay = onPlayPause;
      prev.onpause = onPlayPause;

      return prev;
    });
  }, [videoNode]);

  return {
    duration,
    isPlaying,
    playPause,
    progress,
    seekToProgress,
    setVideoNode,
  };
}
