import { useMemo } from "react";
import { Typography } from "antd";
import { useSearchParams } from "react-router-dom";

import { useCurrentMetrics } from "../../../../queries/metrics.queries";
import { utilisationDisplayFormatter } from "../../../../utils";

import { useKeyTakeawayInsight } from "./keyTakeaway.query";

const title = "Key Takeaway";

const { Text, Title } = Typography;

interface KeyTakeawayTitleProps {
  name: string;
  advancedSearch: string;
}

const KeyTakeawayTitle = ({ name, advancedSearch }: KeyTakeawayTitleProps) => {
  const insights = useKeyTakeawayInsight(advancedSearch);
  const currentMetrics = useCurrentMetrics();
  const [searchParams] = useSearchParams();
  const searchTerm = searchParams.get("term");

  const { percentText, performsText } = useMemo(() => {
    if (!currentMetrics.data || !insights.data) {
      return {};
    }

    const utilisation = insights.data.summaryStats.utilisation;
    const kpiMetricAvg = insights.data.summaryStats.kpiMetric?.avg;

    const kpiMetricValue = currentMetrics.data.kpiMetric.value;

    const delta = kpiMetricValue - kpiMetricAvg;
    const deltaPercent = Math.ceil((Math.abs(delta) / kpiMetricValue) * 100);
    const aboveBelow = delta < 0 ? "above" : "below";

    const percent = searchTerm
      ? `Ads matching the query appear on ${utilisationDisplayFormatter(utilisation)} of ads`
      : `${name} appears on the ${utilisationDisplayFormatter(utilisation)} of ads`;

    const performs = searchTerm
      ? `, and perform ${deltaPercent}% ${aboveBelow} your benchmark of ${kpiMetricValue}`
      : `, and performs ${deltaPercent}% ${aboveBelow} your benchmark of ${kpiMetricValue}`;

    return {
      percentText: percent,
      performsText: kpiMetricValue && delta ? performs : "",
    };
  }, [currentMetrics.data, insights.data, name, searchTerm]);

  if (currentMetrics.isPending || insights.isPending) {
    return <Text>Loading {title}...</Text>;
  }

  return (
    <div>
      <Text>{title}</Text>
      <Title level={4} className="insight-report__title">
        {percentText}
        {performsText}
      </Title>
    </div>
  );
};

export default KeyTakeawayTitle;
