import { useEffect } from "react";
import { Select, Flex, Typography, Spin } from "antd";

import { DashboardView } from "../../constants/creativeInteligence";
import { useCreativeIntelligenceStore } from "../../store/creativeIntelligence.store";
import { useCurrentDashboard, useDomoDashboard } from "../../queries/entities/dashboard.query";
import PageWrapper from "../../components/DashboardView/DashboardPageWrapper";
import { AnalysisTypeValue, getDomoDashbordHTML } from "../../utils/dashboards";

import DataExplorer from "./components/DataExplorer/DataExplorer";
import TagList from "./components/TagList/TagList";
import DashboardFilters from "./components/DashboardFilters";
import { useBenchmarkReportKPIs } from "./queries/reportKPIs.query";

import "./DashboardPage.scss";

const { Paragraph } = Typography;

const views = {
  [DashboardView.Utilisation]: {
    title: "What my content looks like",
    description: "Discover which creative elements you're using the most",
  },
  [DashboardView.Top]: {
    title: "What's working",
    description: "Here’s your best performing creative elements",
  },
  [DashboardView.Bottom]: {
    title: "What's not working",
    description: "These creative elements are not driving performance",
  },
  [DashboardView.DataExplorer]: {
    title: "Data Explorer",
    description: "Explore all the data points in a single chart",
  },
};

const dashboardViews = Object.values(DashboardView).map((value) => ({
  label: views[value].title,
  value,
}));

const DashboardPage = () => {
  const dashboard = useCurrentDashboard();
  const reportKPIs = useBenchmarkReportKPIs();
  const domoDashboard = useDomoDashboard(dashboard.data?.domoDashboardUrl);

  const dashboardView = useCreativeIntelligenceStore((state) => state.dashboardView);
  const setDashboardView = useCreativeIntelligenceStore((state) => state.setDashboardView);
  const resetSelectedFilters = useCreativeIntelligenceStore((state) => state.resetSelectedFilters);

  useEffect(() => {
    resetSelectedFilters();
  }, [resetSelectedFilters]);

  // Show a loading spinner if dashboard is still pending or additional conditions apply
  if (dashboard.isPending
    || (dashboard.data?.dashboardType === AnalysisTypeValue.postFlightDomo && domoDashboard.isPending)) {
    return (
      <Spin
        className="dashboard-page-loader"
      />
    );
  }

  // Show syncing message if relevant conditions are met
  if (dashboard.data?.dashboardType === AnalysisTypeValue.postFlightIC && reportKPIs.data && "isSyncing" in reportKPIs.data) {
    return (
      <PageWrapper>
        <Spin
          tip={
            <Typography.Title level={5}>
              Your data is syncing...
              <br />
              It might take a while. Please come back later!
            </Typography.Title>
          }
          size="large"
          className="dashboard-page-loader"
        >
          <span></span>
        </Spin>
      </PageWrapper>
    );
  }

  // Handle domo dashboard types
  if (dashboard.data?.dashboardType === AnalysisTypeValue.postFlightDomo) {
    return (
      <iframe
        className="domo-dashboard"
        srcDoc={getDomoDashbordHTML(dashboard.data.domoDashboardUrl, domoDashboard.data)}
        frameBorder="0"
      />
    );
  }

  if (!dashboard.data?.resourceGroupIds?.length) {
    return (
      <PageWrapper>
        <div>
          <h3>This dashboard has no datasets assigned</h3>
          <h4>Please got to settings to configure datasets</h4>
        </div>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <Flex vertical gap="middle">
        <section className="dashboard-content-select">
          <Select
            variant="borderless"
            value={dashboardView}
            className="dashboard-content-select-custom-selector"
            onChange={setDashboardView}
            popupMatchSelectWidth={false}
            options={dashboardViews}
          />
          <Paragraph>{views[dashboardView].description}</Paragraph>
        </section>

        <DashboardFilters />

        {dashboardView === DashboardView.DataExplorer ? <DataExplorer /> : <TagList />}
      </Flex>
    </PageWrapper>
  );
};

export default DashboardPage;
