import { Delivery, ResourceManagerEntity } from "../types/entities";

export const ResourceTextStatus = {
  success: "success",
  failed: "failed",
  inProgress: "in progress",
} as const;

export type StatusText = ReturnType<typeof getResourceTextStatus>;

export function getResourceTextStatus(job: ResourceManagerEntity<unknown> | undefined) {
  if (!job?.resourceOutput) {
    return ResourceTextStatus.inProgress;
  }

  if (job.resourceOutput.output?.success) {
    return ResourceTextStatus.success;
  }

  if (job.resourceOutput.output?.fail) {
    return ResourceTextStatus.failed;
  }

  return ResourceTextStatus.inProgress;
}

export function groupDeliveriesStatus(deliveries: Delivery[]) {
  return deliveries.reduce(
    (acc, delivery) => {
      const status = getResourceTextStatus(delivery);

      acc[status].push(delivery);

      return acc;
    },
    {
      [ResourceTextStatus.success]: [] as Delivery[],
      [ResourceTextStatus.failed]: [] as Delivery[],
      [ResourceTextStatus.inProgress]: [] as Delivery[],
    }
  );
}
