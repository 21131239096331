import { useCallback } from "react";
import { Tooltip } from "antd";

import { Attribute } from "../../../../types/attribute";
import { useReportIdsSearchParams } from "../../../../hooks/useReportIdsParam";
import { useCreativeIntelligenceStore } from "../../../../store/creativeIntelligence.store";
import { Deliverable } from "../../../../types/deliverable";

const FramesPerSecond = 2;
const computePreviewFrameNumber = (imageUrl: string) => {
  if (!imageUrl) {
    return "?";
  }

  const [base] = imageUrl.split("?");
  const [, frame] = base.split("-frame-");

  if (!frame) {
    return 0;
  }

  const frameNumber = frame.replace(".png", "").replace("-heatmap.jpeg", "");

  if (Number.isInteger(+frameNumber)) {
    return +frameNumber;
  }

  return 0;
};

const showBoundingBox = ({ metadata, timeline }: Attribute, imageUrl: string) => {
  const hasBoxes = metadata[0]?.bounds;

  if (!hasBoxes) {
    return false;
  }

  if (!timeline) {
    return true;
  }

  const frameNumber = computePreviewFrameNumber(imageUrl);
  const previewFrameNumber = typeof frameNumber === "number" ? frameNumber / FramesPerSecond : 0;

  return timeline.some((segment) => segment.start <= previewFrameNumber && segment.end >= previewFrameNumber);
};

const toCssPercent = (val: string) => {
  return `${Math.floor(Number.parseFloat(val) * 100)}%`;
};

const getBoxStyle = ({ metadata, timeline }: Attribute) => {
  let longestSegmentIndex = 0;

  if (timeline?.length) {
    longestSegmentIndex = [...timeline]
      .map((segment, segmentIndex) => ({
        ...segment,
        segmentIndex,
      }))
      .sort((a, b) => b.end - b.start - (a.end - a.start))[0].segmentIndex;
  }

  if (longestSegmentIndex === undefined || !metadata?.[longestSegmentIndex]?.bounds?.width) {
    return {
      width: 0,
      height: 0,
      top: 0,
      left: 0,
    };
  }

  let width = 0,
    height = 0,
    top = 0,
    left = 0;

  if (metadata[longestSegmentIndex]) {
    const { bounds } = metadata[longestSegmentIndex];

    width = bounds?.width ?? 0;
    height = bounds?.height ?? 0;
    top = bounds?.top ?? 0;
    left = bounds?.left ?? 0;
  }

  return {
    width: toCssPercent(width.toString()),
    height: toCssPercent(height.toString()),
    top: toCssPercent(top.toString()),
    left: toCssPercent(left.toString()),
  };
};

const getAttributeConfidence = ({ metadata }: Attribute) => {
  if (!metadata?.length) {
    return "";
  }

  const confidence = metadata[0].confidence ?? 0;

  if (confidence > 1) {
    return `${Math.round(confidence)}%`;
  }

  return `${Math.round(confidence * 100)}%`;
};

interface UniqueCreativeDeliverableProps {
  deliverable: Deliverable;
}

const UniqueCreativeDeliverable = ({ deliverable }: UniqueCreativeDeliverableProps) => {
  const showAttentionScore = useCreativeIntelligenceStore((state) => state.showAttentionScore);
  const { setReportIds } = useReportIdsSearchParams();

  const { signedPreviewUrl, signedHeatMapPreviewUrl, attributes } = deliverable;
  const imageUrl = showAttentionScore ? signedHeatMapPreviewUrl : signedPreviewUrl;

  const handleOnClick = useCallback(() => setReportIds([deliverable.reportId]), [deliverable, setReportIds]);

  return (
    <div className="unique-creative__deliverable">
      <a aria-hidden onClick={handleOnClick}>
        <img className="unique-creative__deliverable__image" src={imageUrl} alt="Unique Creative Image" />
      </a>
      {attributes.map((item) => {
        const { attribute, attributeId } = item;
        const displayOverlay = showBoundingBox(item, imageUrl ?? "");

        if (displayOverlay) {
          return (
            <span
              key={attributeId}
              className="unique-creative__deliverable__attribute"
              style={{ ...getBoxStyle(item) }}
            >
              <Tooltip placement="bottomLeft" title={`${attribute} ${getAttributeConfidence(item)}`}>
                <span className="unique-creative__deliverable__attribute-text">
                  {attribute} ({getAttributeConfidence(item)})
                </span>
              </Tooltip>
            </span>
          );
        }
      })}
    </div>
  );
};

export default UniqueCreativeDeliverable;
