import { Chart as ChartJS, ChartEvent, ActiveElement } from "chart.js";

import { BaseChartDataset } from "../config";

export function getOnChartElementClick(
  onElementClick: ((reportIds: string[]) => void) | undefined
): (event: ChartEvent, elements: ActiveElement[], chart: ChartJS) => void {
  return (_, elements, chart) => {
    if (!onElementClick || !elements?.length) {
      return;
    }

    const firstElement = elements[0];
    const dataset = chart.data.datasets[firstElement.datasetIndex] as unknown as BaseChartDataset;

    const reportIds = dataset.creatives[firstElement.index].reduce<string[]>((ids, { deliverables }) => {
      return ids.concat(deliverables.map(({ reportId }) => reportId));
    }, []);

    onElementClick(reportIds);
  };
}
