import { useMemo } from "react";
import { Button, Dropdown } from "antd";
import { Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";

import { useRouterPaths } from "../../../hooks/useRouterPaths";

const AddInventoryJobDropdown = () => {
  const {
    addDCMjobIndexPath,
    addDV360jobIndexPath,
    addFBjobIndexPath,
    addTiktokJobIndexPath,
    addLinkedinJobIndexPath } = useRouterPaths();

  const menu = useMemo(
    () => ({
      items: [
        {
          key: "1",
          label: <Link to={addDCMjobIndexPath}>DCM</Link>,
        },
        {
          key: "2",
          label: <Link to={addFBjobIndexPath}>Meta</Link>,
        },
        {
          key: "3",
          label: <Link to={addDV360jobIndexPath}>DV360</Link>,
        },
        {
          key: "4",
          label: <Link to={addTiktokJobIndexPath}>TikTok</Link>,
        },
        {
          key: "5",
          label: <Link to={addLinkedinJobIndexPath}>Linkedin</Link>,
        },
      ],
    }),
    [addDCMjobIndexPath, addDV360jobIndexPath, addFBjobIndexPath, addTiktokJobIndexPath, addLinkedinJobIndexPath]
  );

  return (
    <Dropdown menu={menu} placement="bottomRight">
      <Button type="text" icon={<PlusOutlined />}>
        Add Inventory Job
      </Button>
    </Dropdown>
  );
};

export default AddInventoryJobDropdown;
