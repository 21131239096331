import { useMemo } from "react";
import type { ChartDataset, ChartOptions } from "chart.js";

import { type Attribute } from "../../../../../../../../types/attribute";
import { COLORS_HEX } from "../../../../../Charts/config";

export const SCREENSHOT_INTERVAL = 0.5;

export function useTextStatsChartOptions({ xMax, yMax }: { xMax: number; yMax: number }): ChartOptions<"bar"> {
  return useMemo(
    () => ({
      animation: false,
      maintainAspectRatio: false,
      responsive: true,

      layout: {
        // not pixel perfect, but close enough
        padding: { left: 7, right: 14 },
      },

      plugins: {
        tooltip: { enabled: false },
        legend: { display: false },
      },

      scales: {
        x: {
          type: "linear",
          position: "bottom",
          min: 0,
          suggestedMax: xMax,
          ticks: { display: false },
          border: { display: false },
          grid: { display: false },
          offset: false,
          afterFit: (scale) => (scale.height = 0),
        },
        y: {
          min: 0,
          max: yMax,
          border: { display: false },
          grid: { display: false },
          ticks: { display: false },
        },
      },
    }),
    [xMax, yMax]
  );
}

export interface DataPoint {
  x: number;
  y: number;
}

export interface TextDataset extends ChartDataset<"bar", DataPoint[]> {
  backgroundColor: string[];
  borderColor: string;
  data: DataPoint[];
  pointRadius: number;
  inflateAmount: number;
  barPercentage: number;
  categoryPercentage: number;
  borderWidth: number;
}

export const getChartDataMap = (attributes: Attribute[], ticksCount: number) => {
  if (!ticksCount) {
    return [];
  }

  const data: Record<number, DataPoint> = {};

  for (let i = 0; i < ticksCount; i++) {
    data[i * SCREENSHOT_INTERVAL] = { y: 0, x: i * SCREENSHOT_INTERVAL };
  }

  attributes.forEach(({ timeline, attribute }) => {
    if (!timeline) {
      return;
    }

    timeline.forEach(({ start, end }) => {
      // not all timeline data divides by 0.5, so we need to round it
      const rStart = Math.round(start / SCREENSHOT_INTERVAL) * SCREENSHOT_INTERVAL;
      const rEnd = Math.round(end / SCREENSHOT_INTERVAL) * SCREENSHOT_INTERVAL;

      for (let i = rStart; i < rEnd; i += SCREENSHOT_INTERVAL) {
        if (data[i]?.y !== undefined) {
          data[i].y += Number.parseFloat(attribute);
        } else {
          console.error("TODO: data[i] is undefined");
        }
      }
    });
  });

  return Object.values(data).sort((a, b) => b.x - a.x);
};

export const getBgColor = (rgbColor: string, value: number, datasetMax: number) => {
  if (value === 0) {
    return `${rgbColor}00`;
  }

  const maxPercent = value / datasetMax;
  // + 10% to avoid being fully transparent
  const percent = maxPercent + 0.03 > 1 ? 1 : maxPercent + 0.03;
  const hexAlpha = Math.ceil(255 * percent).toString(16);

  return `${rgbColor}${hexAlpha}`;
};

export const getTextStatsDatasets = (attributesData: DataPoint[], color: string, attributesMaxValue: number) => [
  {
    backgroundColor: attributesData.map(({ y }) => getBgColor(color, y, attributesMaxValue)),
    borderColor: COLORS_HEX.transparent,
    data: attributesData,
    pointRadius: 4,
    inflateAmount: 0,
    barPercentage: 1,
    categoryPercentage: 1,
    borderWidth: 0,
  },
];
