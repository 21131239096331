import { fetchAuthSession } from "aws-amplify/auth";

export async function getAuthHeader() {
  try {
    const { tokens } = await fetchAuthSession();

    return { Authorization: tokens?.idToken?.toString() ?? "" };
  } catch {
    return { Authorization: "" };
  }
}
