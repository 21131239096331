import { useMemo } from "react";

import { useCurrentMetrics } from "../../../../../queries/metrics.queries";

import { ChartTypes, CHARTS, BaseChartDataset, BaseDataPoint } from "../config";
import { getBarScales } from "../utils/getBarScales";
import { getScatterScales } from "../utils/getScatterScales";

export function useChartScales({
  type,
  datasets,
  isUtilization,
  isLogScale,
}: {
  type: ChartTypes;
  datasets: BaseChartDataset<BaseDataPoint<number | { x: number; y: number }>[]>[];
  isUtilization: boolean;
  isLogScale: boolean;
}) {
  const currentMetrics = useCurrentMetrics();

  return useMemo(() => {
    const summaryStats = datasets[0]?.summaryStats ?? [];
    const multiDataset = datasets.length > 1;

    switch (type) {
      case CHARTS.SCATTER: {
        return getScatterScales({
          isUtilization,
          isLogScale,
          currentMetricsData: currentMetrics.data,
          multiDataset,
          summaryStats,
        });
      }

      case CHARTS.BAR: {
        const metric = datasets[0]?.metric;
        const metricLabel =
          (metric === "kpiMetric" ? currentMetrics.data?.kpiMetricLabel : currentMetrics.data?.confidenceMetricLabel)
          ?? "";

        const scale1 = getBarScales({
          isUtilization,
          metricLabel,
        });

        if (!multiDataset) {
          return {
            y: scale1,
          };
        }

        const metricB = datasets[1]?.metric;
        const metricBLabel =
          (metricB === "kpiMetric"
            ? currentMetrics.data?.kpiMetricLabel
            : currentMetrics.data?.confidenceMetricLabel) ?? "";

        const scale2 = getBarScales({
          isUtilization,
          metricLabel: metricBLabel,
        });

        return {
          y: scale1,
          y1: { ...scale2, display: true, position: "right" },
        };
      }

      case CHARTS.HORIZONTAL_BAR: {
        const metric = datasets[0]?.metric ?? "kpiMetric";
        const metricLabel =
          (metric === "kpiMetric" ? currentMetrics.data?.kpiMetricLabel : currentMetrics.data?.confidenceMetricLabel)
          ?? "";

        const scale1 = getBarScales({
          isUtilization,
          metricLabel,
        });

        return {
          x: scale1,
        };
      }

      default:
        return {};
    }
  }, [currentMetrics.data, datasets, isLogScale, isUtilization, type]);
}
