import { RightCircleOutlined, SettingFilled, DragOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Flex, Space, Typography } from "antd";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";

import { useRouterPaths } from "../../../../hooks/useRouterPaths";
import { DynamicBreakdowns } from "../../../../queries/insights/types";

const { Text } = Typography;

const Filters = ({
  selectedFilter,
  prevFilter,
  nextFilter,
  tagId,
}: {
  selectedFilter: DynamicBreakdowns | undefined;
  prevFilter: () => void;
  nextFilter: () => void;
  tagId: string;
}) => {
  const selectedFilterTitle = useMemo(() => {
    return selectedFilter ? Object.values(selectedFilter)[0] : "All";
  }, [selectedFilter]);

  return (
    <>
      <Button
        icon={<LeftOutlined />}
        type="link"
        onClick={prevFilter}
        data-testid={`tag-preview-left-filter-btn-${tagId}`}
      />

      <Text data-testid={`tag-preview-current-filter-${tagId}`}>{selectedFilterTitle}</Text>

      <Button
        icon={<RightOutlined />}
        type="link"
        onClick={nextFilter}
        data-testid={`tag-preview-right-filter-btn-${tagId}`}
      />
    </>
  );
};

interface Props {
  name: string | undefined;
  tagId: string;
  selectedFilter: DynamicBreakdowns | undefined;
  prevFilter: () => void;
  nextFilter: () => void;
  openSettings: () => void;
  listeners: SyntheticListenerMap | undefined;
}

const PreviewHeader = ({
  name = "",
  tagId,
  selectedFilter,
  prevFilter,
  nextFilter,
  openSettings,
  listeners,
}: Props) => {
  const { tagIndexPath } = useRouterPaths();

  return (
    <Flex justify="space-between" align="center" className="tag-preview-header">
      <Link to={`${tagIndexPath}/${tagId}`} data-testid={`tag-preview-link-${tagId}`}>
        <Text strong style={{ fontSize: 16 }}>
          <RightCircleOutlined /> {name}
        </Text>
      </Link>

      <Space size={0}>
        <Filters selectedFilter={selectedFilter} prevFilter={prevFilter} nextFilter={nextFilter} tagId={tagId} />

        <Button
          icon={<SettingFilled />}
          type="link"
          onClick={openSettings}
          data-testid={`tag-preview-settings-btn-${tagId}`}
        />

        <div className="tag-drag-btn" {...listeners} data-testid={`tag-preview-drag-btn-${tagId}`}>
          <Button icon={<DragOutlined />} type="link" />
        </div>
      </Space>
    </Flex>
  );
};

export default PreviewHeader;
