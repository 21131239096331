import { useMutation, useQueryClient } from "@tanstack/react-query";

import { InventoryEntity, InventoryExtractorInput, SelfServiceExtractor, UpdateInventory } from "../../types/entities";
import { entityRequest } from "../../utils/entity.api";

import { BRAND_INVENTORY_QUERY_KEY, INVENTORY_API_URL } from "./inventory.queries";

export function useAddInventoryJob<T extends SelfServiceExtractor>(
  brandId: string,
  resourceType: SelfServiceExtractor
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (extractorInput: InventoryExtractorInput<T>) => {
      const body: UpdateInventory<T> = {
        resourceType,
        extractorInput,
        brandId,
      };

      return entityRequest<InventoryEntity, UpdateInventory<T>>(INVENTORY_API_URL, "POST", body);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [BRAND_INVENTORY_QUERY_KEY, brandId],
      });
    },
  });
}
