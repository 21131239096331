export enum AnalysisTypeValue {
  postFlightIC = "native",
  postFlightDomo = "domo",
}

export interface AnalysisType {
  label: string;
  value: AnalysisTypeValue;
}

export const ANALYSIS_TYPES: AnalysisType[] = [{
  label: "Post-Flight Intelligent Content",
  value: AnalysisTypeValue.postFlightIC,
}, {
  label: "Post-Flight Domo",
  value: AnalysisTypeValue.postFlightDomo,
}];

export const getDomoDashbordHTML = (dashboardUrl: string | undefined, token: string | undefined) => {
  if (!dashboardUrl || !token) {
    return "";
  }

  const dashboardId = dashboardUrl?.split("/").at(-1);

  return `<html>
  <body>
    <form
      id="form"
      action="https://public.domo.com/embed/pages/${dashboardId}"
      method="post"
    >
      <input type="hidden" name="embedToken" value="${token}" />
    </form>
    <script>
      document.forms[0].submit();
    </script>
  </body>
</html>
`;
};
