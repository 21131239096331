import React from "react";

import { numberToShortKM, utilisationDisplayFormatter } from "../../../../utils";
import { getMetricStat } from "../../../../utils/analytics";
import { useCurrentMetrics } from "../../../../queries/metrics.queries";
import { TagStats } from "../../../../queries/tags/computeTags.query";

import "./TagKpis.scss";

interface Props {
  stats?: TagStats;
}

const kpiDisplayFormatter = (value: number) => {
  return numberToShortKM(value);
};

const TagKpis: React.FunctionComponent<Props> = ({ stats }) => {
  const currentMetrics = useCurrentMetrics();

  if (!stats || !currentMetrics.data) {
    return null;
  }

  const kpiStat = getMetricStat(currentMetrics.data.kpiMetric.metric);

  const kpiStatsValue = stats.kpiMetricStats[kpiStat];
  const direction = currentMetrics.data.kpiMetric.value > kpiStatsValue ? "down" : "up";

  return (
    <span className="kpi-stats">
      <span className={`kpi-stats--${direction} circle`} />
      <span className="kpi-stats--kpi-metric-value">{kpiStatsValue && kpiDisplayFormatter(kpiStatsValue)}</span>
      <span className="kpi-stats--utilisation">{utilisationDisplayFormatter(stats.utilisation)}</span>
    </span>
  );
};

export default TagKpis;
