import { API_KEY, Method, getDefaultHeaders, request } from "./api";
import { getAuthHeader } from "./jwt";

export async function analyticsApiRequest<ResponseData, Body = undefined>(
  url: string,
  method: Method = "GET",
  body?: Body
) {
  const authHeader = await getAuthHeader();
  const headers = getDefaultHeaders(API_KEY);

  return request<ResponseData, Body>("/v1" + url, { ...authHeader, ...headers }, method, body);
}
