import { useMemo } from "react";
import { Card, Flex, Typography } from "antd";

import ChartWithTooltip from "../../DashboardPage/components/Charts/Chart";
import { CHARTS } from "../../DashboardPage/components/Charts/config";

import { InsightsData } from "../../../types/creativeInteligence";
import { useCurrentMetrics } from "../../../queries/metrics.queries";
import { useReportIdsSearchParams } from "../../../hooks/useReportIdsParam";
import { useDurationInsights, useAppearanceInsights } from "../../../queries/insights/group.queries";

import { usePositionInsight } from "./Position/position.query";
import { useSizeInsights } from "./Size/size.query";
import { useUsageInsights } from "./Usage/usage.query";

const { Title } = Typography;

const KPI_METRIC_COLOR = "#58AFFF";
const CONFIDENCE_METRIC_COLOR = "#8E66DD";

function useChartReportDatasets(
  insightsData: InsightsData | undefined,
  type: typeof CHARTS.BAR | typeof CHARTS.SCATTER
) {
  const currentMetrics = useCurrentMetrics();

  return useMemo(() => {
    const kpiMetricLabel = currentMetrics.data?.kpiMetricLabel;
    const confidenceMetricLabel = currentMetrics.data?.confidenceMetricLabel;

    if (!insightsData || !kpiMetricLabel || !confidenceMetricLabel) {
      return [];
    }

    if (type === CHARTS.BAR) {
      return [
        {
          label: kpiMetricLabel,
          metric: "kpiMetric" as const,
          backgroundColor: KPI_METRIC_COLOR,
          data: insightsData,
          yAxisID: "y",
        },
        {
          label: confidenceMetricLabel,
          metric: "confidenceMetric" as const,
          backgroundColor: CONFIDENCE_METRIC_COLOR,
          data: insightsData,
          yAxisID: "y1",
        },
      ];
    }

    return [
      {
        label: kpiMetricLabel,
        metric: "kpiMetric" as const,
        backgroundColor: KPI_METRIC_COLOR,
        data: insightsData,
        yAxisID: "y",
      },
    ];
  }, [currentMetrics.data?.confidenceMetricLabel, currentMetrics.data?.kpiMetricLabel, insightsData, type]);
}

const InsightChart = ({
  label,
  insightsData,
  type,
}: {
  label: string;
  insightsData: InsightsData | undefined;
  type: typeof CHARTS.BAR | typeof CHARTS.SCATTER;
}) => {
  const { setReportIds } = useReportIdsSearchParams();
  const datasets = useChartReportDatasets(insightsData, type);

  return (
    <Card>
      <Title level={4}>{label}</Title>
      <ChartWithTooltip className="insights-chart" type={type} dataSeries={datasets} onElementClick={setReportIds} />
    </Card>
  );
};

const ChartReport = ({
  advancedSearch,
  type,
}: {
  advancedSearch: string;
  type: typeof CHARTS.BAR | typeof CHARTS.SCATTER;
}) => {
  const usageInsights = useUsageInsights(advancedSearch);
  const positionInsights = usePositionInsight(advancedSearch);
  const sizeInsights = useSizeInsights(advancedSearch);
  const durationInsights = useDurationInsights(advancedSearch);
  const appearanceInsights = useAppearanceInsights(advancedSearch);

  const usageData = useMemo(() => {
    if (usageInsights.isPending) {
      return {};
    }

    return (
      usageInsights.data?.reduce<InsightsData>((acc, insight) => {
        acc[insight[0]] = insight[1];

        return acc;
      }, {}) ?? {}
    );
  }, [usageInsights.data, usageInsights.isPending]);

  return (
    <Flex vertical gap="large">
      <InsightChart label="Usage" insightsData={usageData} type={type} />
      <InsightChart label="Position Analysis" insightsData={positionInsights.data} type={type} />
      <InsightChart label="Size Analysis" insightsData={sizeInsights.data} type={type} />
      <InsightChart label="Time on Screen" insightsData={durationInsights.data} type={type} />
      <InsightChart label="Appearance Time" insightsData={appearanceInsights.data} type={type} />
    </Flex>
  );
};

export default ChartReport;
