import { InfiniteData } from "@tanstack/react-query";

import { InsightsEndpoint } from "../../../../constants/creativeInteligence";
import { useInfiniteInsights } from "../../../../queries/insights/insights.queries";
import { GetAttributeValuesInsightsOptions } from "../../../../queries/insights/types";
import { InsightsData } from "../../../../types/creativeInteligence";
import { ApiResponse } from "../../../../utils/api";

interface AttributesValueResponse {
  attributes: InsightsData;
}

const usageReportKey = "usageReportKey";

function select(resp?: InfiniteData<ApiResponse<AttributesValueResponse>>) {
  return resp?.pages?.map((page) => Object.entries(page.data.attributes)).flat();
}

export function useUsageInsights(advancedSearch: string) {
  const insights = useInfiniteInsights<AttributesValueResponse, GetAttributeValuesInsightsOptions>({
    queryKey: usageReportKey,
    endpoint: InsightsEndpoint.AttributeValues,
    extraOptions: {
      advancedSearch,
      topAttributesValuesNumber: 10,
    },
  });

  return {
    ...insights,
    data: select(insights.data),
  };
}
