export function getUtilizationTickValue(v: string | number) {
  const value = Number(v);

  return value > 100 ? "" : `${value.toFixed(1)}%`;
}

export function getTickValue(v: string | number) {
  const value = Number(v);

  if (value === 0) {
    return "0";
  }

  if (Math.abs(value) < 0.01) {
    return value.toFixed(4);
  }

  if (Math.abs(value) < 100) {
    return value.toFixed(2);
  }

  return value.toFixed(0);
}
