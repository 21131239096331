import { useMutation, useQueryClient } from "@tanstack/react-query";

import { entityRequest } from "../../utils/entity.api";
import { DeliveryConfig, DeliveryEntity } from "../../types/entities";

import { INVENTORY_DELIVERABLES_QUERY_KEY } from "./inventoryDeliveries.query";
import { addInfiniteEntityQueryData } from "./useInfiniteEntityQuery";

const DELIVERY_API_URL = "/delivery";

export function useAddDelivery(inventoryId: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (input: DeliveryConfig) => {
      return entityRequest<DeliveryEntity, DeliveryConfig>(DELIVERY_API_URL, "POST", input);
    },
    onSuccess: (newDelivery: DeliveryEntity) => {
      addInfiniteEntityQueryData([INVENTORY_DELIVERABLES_QUERY_KEY, inventoryId], queryClient, [newDelivery]);
    },
  });
}
