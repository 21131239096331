import { useMemo } from "react";
import { Table } from "antd";

import { DynamicBreakdowns } from "../../../../queries/insights/types";
import { useCurrentMetrics } from "../../../../queries/metrics.queries";

import { ValuesByName } from "./matrix.query";

const ComparisonTable = ({
  selectedFilters,
  insights,
  loading,
}: {
  insights: ValuesByName | undefined;
  selectedFilters: DynamicBreakdowns;
  loading: boolean;
}) => {
  const currentMetrics = useCurrentMetrics();

  const kpiMetricLabel = currentMetrics.data?.kpiMetricLabel;
  const confidenceMetricLabel = currentMetrics.data?.confidenceMetricLabel;

  const { tableColumns, tableData } = useMemo(() => {
    if (!insights || !currentMetrics.data || !kpiMetricLabel || !confidenceMetricLabel) {
      return { tableColumns: [], tableData: [] };
    }

    const initialAcc = {
      columns: [
        {
          title: "Kpi name",
          dataIndex: "label",
          key: "label",
        },
      ],
      kpiRow: { label: kpiMetricLabel, key: "kpi" },
      confidenceRow: { label: confidenceMetricLabel, key: "confidence" },
    };

    const { columns, kpiRow, confidenceRow } = Object.entries(selectedFilters).reduce<{
      columns: { title: string; dataIndex: string; key: string }[];
      kpiRow: Record<string, string>;
      confidenceRow: Record<string, string>;
    }>((acc, [breakdown, values]) => {
      acc.columns = [
        ...acc.columns,
        ...values.map((value) => {
          if (insights.kpiValues[value] !== undefined) {
            acc.kpiRow[value] = insights.kpiValues[value];
          } else {
            acc.kpiRow[value] = "N/A";
          }

          if (insights.confidenceValues[value] !== undefined) {
            acc.confidenceRow[value] = insights.confidenceValues[value];
          } else {
            acc.confidenceRow[value] = "N/A";
          }

          return {
            title: value,
            dataIndex: value,
            key: `${breakdown}-${value}`,
          };
        }),
      ];

      return acc;
    }, initialAcc);

    return {
      tableColumns: columns,
      tableData: [kpiRow, confidenceRow],
    };
  }, [insights, currentMetrics.data, selectedFilters, kpiMetricLabel, confidenceMetricLabel]);

  return (
    <Table
      columns={tableColumns}
      pagination={false}
      loading={loading}
      dataSource={tableData}
      className="custom-kpi-metric-table"
    />
  );
};

export default ComparisonTable;
