import { InsightsData, RawInsightsCreativeGroup } from "../../types/creativeInteligence";
import { InsightsEndpoint } from "../../constants/creativeInteligence";

import { GetSegmentLengthInsightsOptions, GetSegmentPositionInsightsOptions, InsightGroup } from "./types";
import { useInsights } from "./insights.queries";

export interface SegmentGroups extends InsightsData {
  "0-2": RawInsightsCreativeGroup;
  "0-5": RawInsightsCreativeGroup;
  "0-200": RawInsightsCreativeGroup;
}

interface SegmentGroupsResponse {
  groups: SegmentGroups;
}

export const SEGMENT_GROUPS_OPTIONS = {
  "0-2": 2,
  "0-5": 5,
  "0-200": 200,
} as const;

export const SEGMENT_GROUPS = Object.entries(SEGMENT_GROUPS_OPTIONS).map(
  ([key, value]) => ({ name: key, maxValue: value } as InsightGroup)
);

const appearanceKey = "appearanceKey";
const durationKey = "durationKey";

function select(data?: SegmentGroupsResponse) {
  return data?.groups;
}

export function useAppearanceInsights(advancedSearch: string) {
  const insights = useInsights<SegmentGroupsResponse, GetSegmentPositionInsightsOptions>({
    queryKey: appearanceKey,
    endpoint: InsightsEndpoint.SegmentPosition,
    extraOptions: {
      advancedSearch,
      groups: SEGMENT_GROUPS,
    },
  });

  return {
    ...insights,
    data: select(insights.data),
  };
}

export function useDurationInsights(advancedSearch: string) {
  const insights = useInsights<SegmentGroupsResponse, GetSegmentLengthInsightsOptions>({
    queryKey: durationKey,
    endpoint: InsightsEndpoint.SegmentLength,
    extraOptions: {
      advancedSearch,
      groups: SEGMENT_GROUPS,
    },
  });

  return {
    ...insights,
    data: select(insights.data),
  };
}
