import { numberToShortKM } from "../../../utils";

export type CreativeIntelligenceMetric =
  | "add_to_cart_value"
  | "add_to_cart"
  | "add_to_cart_rate"
  | "add_to_cart_value_rate"
  | "clicks_rate"
  | "clicks"
  | "conversion_rate"
  | "impressions"
  | "purchase"
  | "purchase_rate"
  | "purchase_value"
  | "purchase_value_rate"
  | "video_p100"
  | "video_p100_rate"
  | "video_p25"
  | "video_p25_rate"
  | "video_p50"
  | "video_p50_rate"
  | "video_p75"
  | "video_p75_rate"
  | "video_p95"
  | "video_p95_rate"
  | "video_started"
  | "video_started_rate";

export const CreativeIntelligenceMetrics = {
  AddToCartValue: "add_to_cart_value",
  AddToCart: "add_to_cart",
  AddToCartRate: "add_to_cart_rate",
  AddToCartValueRate: "add_to_cart_value_rate",
  ClickRate: "clicks_rate",
  Clicks: "clicks",
  ConversionRate: "conversion_rate",
  Impressions: "impressions",
  Purchase: "purchase",
  PurchaseRate: "purchase_rate",
  PurchaseValue: "purchase_value",
  PurchaseValueRate: "purchase_value_rate",
  VideoP100: "video_p100",
  VideoP100Rate: "video_p100_rate",
  VideoP25: "video_p25",
  VideoP25Rate: "video_p25_rate",
  VideoP50: "video_p50",
  VideoP50Rate: "video_p50_rate",
  VideoP75: "video_p75",
  VideoP75Rate: "video_p75_rate",
  VideoP95: "video_p95",
  VideoP95Rate: "video_p95_rate",
  VideoStarted: "video_started",
  VideoStartedRate: "video_started_rate",
} as const;

export type CreativeIntelligenceMetricLabel =
  | "Add to Cart Value"
  | "Add to Cart"
  | "Add to cart rate"
  | "Add to cart value rate"
  | "Clickthrough Rate"
  | "Clicks"
  | "Conversion Rate"
  | "Impressions"
  | "Purchase"
  | "Purchase Rate"
  | "Purchase Value"
  | "Purchase Value Rate"
  | "100% Viewthrough"
  | "100% Viewthrough Rate"
  | "25% Viewthrough"
  | "25% Viewthrough Rate"
  | "50% Viewthrough"
  | "50% Viewthrough Rate"
  | "75% Viewthrough"
  | "75% Viewthrough Rate"
  | "95% Viewthrough"
  | "95% Viewthrough Rate"
  | "Views"
  | "View Rate";

export const CreativeIntelligenceMetricLabels = {
  [CreativeIntelligenceMetrics.AddToCartValue]: "Add to Cart Value",
  [CreativeIntelligenceMetrics.AddToCart]: "Add to Cart",
  [CreativeIntelligenceMetrics.AddToCartRate]: "Add to cart rate",
  [CreativeIntelligenceMetrics.AddToCartValueRate]: "Add to cart value rate",
  [CreativeIntelligenceMetrics.ClickRate]: "Clickthrough Rate",
  [CreativeIntelligenceMetrics.Clicks]: "Clicks",
  [CreativeIntelligenceMetrics.ConversionRate]: "Conversion Rate",
  [CreativeIntelligenceMetrics.Impressions]: "Impressions",
  [CreativeIntelligenceMetrics.Purchase]: "Purchase",
  [CreativeIntelligenceMetrics.PurchaseRate]: "Purchase Rate",
  [CreativeIntelligenceMetrics.PurchaseValue]: "Purchase Value",
  [CreativeIntelligenceMetrics.PurchaseValueRate]: "Purchase Value Rate",
  [CreativeIntelligenceMetrics.VideoP100Rate]: "100% Viewthrough Rate",
  [CreativeIntelligenceMetrics.VideoP25Rate]: "25% Viewthrough Rate",
  [CreativeIntelligenceMetrics.VideoP50Rate]: "50% Viewthrough Rate",
  [CreativeIntelligenceMetrics.VideoP75Rate]: "75% Viewthrough Rate",
  [CreativeIntelligenceMetrics.VideoP95Rate]: "95% Viewthrough Rate",
  [CreativeIntelligenceMetrics.VideoStartedRate]: "View Rate",
  [CreativeIntelligenceMetrics.VideoStarted]: "Views",
  [CreativeIntelligenceMetrics.VideoP25]: "25% Viewthrough",
  [CreativeIntelligenceMetrics.VideoP50]: "50% Viewthrough",
  [CreativeIntelligenceMetrics.VideoP75]: "75% Viewthrough",
  [CreativeIntelligenceMetrics.VideoP95]: "95% Viewthrough",
  [CreativeIntelligenceMetrics.VideoP100]: "100% Viewthrough",
} as const;

export const getMetricsLabel = (metric: string) => {
  if (CreativeIntelligenceMetricLabels[metric as CreativeIntelligenceMetric]) {
    return CreativeIntelligenceMetricLabels[metric as CreativeIntelligenceMetric];
  }

  const result = metric.replace(/([A-Z])/g, " $1");
  const label = result.charAt(0).toUpperCase() + result.slice(1);

  return label.replace("_rate", " Rate").replace(/_/g, " ") as CreativeIntelligenceMetricLabel;
};

export const metricValueFormatter = (statValue: number) => {
  if (statValue < 1) {
    return statValue.toFixed(3);
  }

  const number = numberToShortKM(statValue);

  if (!number) {
    return statValue.toFixed(3);
  }

  return number.replace("B", "000000000").replace("M", "000000").replace("K", "000");
};
