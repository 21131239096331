const BackendAttributeTypes = {
  // This type should no longer be used. It has been split into more specific types
  EDITABLE_VALUE: "PlaceholderValue",
  IFRAME_VALUE: "IframeValue",
  IMAGE_VALUE: "ImageValue",
  IMAGE_TAG: "ImageTag",
  LANGUAGE: "Language",
  METADATA: "Metadata",
  TEMPLATE_SIZE: "Size",
  TEXT_SENTIMENT: "TextSentiment",
  TEXT_VALUE: "TextValue",
  KEYWORD: "Keyword",
  WORD_COUNT: "WordCount",
  WORD_COUNT_BRACKET: "WordCountBracket",
  KEY_PHRASE: "KeyPhrase",
  ENTITY: "TextEntity",
  VIDEO_VALUE: "VideoValue",
  COLOR_THEORY: "ColorTheory",
  COLOR_THEORY_COLOR: "ColorTheory-Color",
  COLOR_THEORY_COLOR_GROUP: "ColorTheory-ColorGroup",
  COLOR_THEORY_PRIMARY_COLOR_GROUP: "ColorTheory-PrimaryColorGroup",
  COLOR_THEORY_PANTONE_COLOR_GROUP: "ColorTheory-PantoneColorGroup",
  COLOR_THEORY_TEMPERATURE: "ColorTheory-Temperature",
  COLOR_THEORY_TEMPERATURE_GROUP: "ColorTheory-TemperatureGroup",
};

const COLOR_THEORY_TYPES = {
  COLOR: "Color",
  COLOR_GROUP: "ColorGroup",
  PANTONE_COLOR_GROUP: "PantoneColorGroup",
  PRIMARY_COLOR_GROUP: "PrimaryColorGroup",
  TEMPERATURE: "ColorTemperature",
  TEMPERATURE_GROUP: "ColorTemperatureGroup",
};

const TextEntityTypes = {
  ORGANIZATION: "Organization",
  TITLE: "Title",
  LOCATION: "Location",
  DATE: "Date",
  PERSON: "Person",
  QUANTITY: "Quantity",
  OTHER: "Other",
  COMMERCIAL_ITEM: "Commercial_item",
  EVENT: "Event",
};

const ImageTypeAttributeSubtype = {
  AGE: "Age",
  COLOR_THEORY: "ColorTheory",
  DOMINANT_COLOUR: "DominantColour",
  GENDER: "Gender",
  LABEL: "Label",
  OBJECT: "Object",
  LANDMARK: "Landmark",
  LOGO: "Logo",
  EMOTION: "Emotion",
  FACIAL_FEATURE: "FacialFeature",
  TEXT: "Text",
  TEXT_WORD: "TextWord",
  TEXT_LINE: "TextLine",
  TEXT_BLOCK: "TextBlock",
};

export const AttributeTypes = {
  COLOR_THEORY_COLOR: `${BackendAttributeTypes.COLOR_THEORY}-${COLOR_THEORY_TYPES.COLOR}`,
  COLOR_THEORY_COLOR_GROUP: `${BackendAttributeTypes.COLOR_THEORY}-${COLOR_THEORY_TYPES.COLOR_GROUP}`,
  COLOR_THEORY_PANTONE_COLOR_GROUP: `${BackendAttributeTypes.COLOR_THEORY}-${COLOR_THEORY_TYPES.PANTONE_COLOR_GROUP}`,
  COLOR_THEORY_PRIMARY_COLOR_GROUP: `${BackendAttributeTypes.COLOR_THEORY}-${COLOR_THEORY_TYPES.PRIMARY_COLOR_GROUP}`,
  COLOR_THEORY_TEMPERATURE: `${BackendAttributeTypes.COLOR_THEORY}-${COLOR_THEORY_TYPES.TEMPERATURE}`,
  COLOR_THEORY_TEMPERATURE_GROUP: `${BackendAttributeTypes.COLOR_THEORY}-${COLOR_THEORY_TYPES.TEMPERATURE_GROUP}`,
  ENTITY_COMMERCIAL_ITEM: `${BackendAttributeTypes.ENTITY}-${TextEntityTypes.COMMERCIAL_ITEM}`,
  ENTITY_DATE: `${BackendAttributeTypes.ENTITY}-${TextEntityTypes.DATE}`,
  ENTITY_LOCATION: `${BackendAttributeTypes.ENTITY}-${TextEntityTypes.LOCATION}`,
  ENTITY_ORGANIZATION: `${BackendAttributeTypes.ENTITY}-${TextEntityTypes.ORGANIZATION}`,
  ENTITY_OTHER: `${BackendAttributeTypes.ENTITY}-${TextEntityTypes.OTHER}`,
  ENTITY_PERSON: `${BackendAttributeTypes.ENTITY}-${TextEntityTypes.PERSON}`,
  ENTITY_QUANTITY: `${BackendAttributeTypes.ENTITY}-${TextEntityTypes.QUANTITY}`,
  ENTITY_TITLE: `${BackendAttributeTypes.ENTITY}-${TextEntityTypes.TITLE}`,
  ENTITY_EVENT: `${BackendAttributeTypes.ENTITY}-${TextEntityTypes.EVENT}`,
  IMAGE_TAG_AGE: `${BackendAttributeTypes.IMAGE_TAG}-${ImageTypeAttributeSubtype.AGE}`,
  IMAGE_TAG_EMOTION: `${BackendAttributeTypes.IMAGE_TAG}-${ImageTypeAttributeSubtype.EMOTION}`,
  IMAGE_TAG_FACIAL_FEATURE: `${BackendAttributeTypes.IMAGE_TAG}-${ImageTypeAttributeSubtype.FACIAL_FEATURE}`,
  IMAGE_TAG_GENDER: `${BackendAttributeTypes.IMAGE_TAG}-${ImageTypeAttributeSubtype.GENDER}`,
  IMAGE_TAG_LABEL: `${BackendAttributeTypes.IMAGE_TAG}-${ImageTypeAttributeSubtype.LABEL}`,
  IMAGE_TAG_OBJECT: `${BackendAttributeTypes.IMAGE_TAG}-${ImageTypeAttributeSubtype.OBJECT}`,
  IMAGE_TAG_LOGO: `${BackendAttributeTypes.IMAGE_TAG}-${ImageTypeAttributeSubtype.LOGO}`,
  IMAGE_TAG_DOMINANT_COLOR: `${BackendAttributeTypes.IMAGE_TAG}-${ImageTypeAttributeSubtype.DOMINANT_COLOUR}`,
  REPORTING_LABEL: "ReportingLabel",
  TEXT_VALUE: BackendAttributeTypes.TEXT_VALUE,
  TEXT_OVERLAY: "TextOverlay",
  WORD_COUNT: "WordCount",
  WORD_COUNT_GROUP: "WordCountGroup",
  IMAGE_VALUE: BackendAttributeTypes.IMAGE_VALUE,
};

export const AttributeTypeLabels = {
  // [AttributeTypes.EDITABLE_VALUE]: "Placeholder Value",
  // [AttributeTypes.IMAGE_TAG]: "Image Tag",
  // [AttributeTypes.IMAGE_VALUE]: "Images",
  // [AttributeTypes.LANGUAGE]: "Language",
  // [AttributeTypes.METADATA]: "Metadata",
  // [AttributeTypes.TEMPLATE_SIZE]: "Ad Size",
  // [AttributeTypes.TEXT_SENTIMENT]: "Text Sentiment",
  [AttributeTypes.TEXT_VALUE]: "Text",
  // [AttributeTypes.VIDEO_VALUE]: "Video",
  [AttributeTypes.IMAGE_TAG_AGE]: "Age",
  [AttributeTypes.IMAGE_TAG_EMOTION]: "Facial Expression",
  [AttributeTypes.IMAGE_TAG_FACIAL_FEATURE]: "Facial Features",
  [AttributeTypes.IMAGE_TAG_GENDER]: "Gender",
  [AttributeTypes.IMAGE_TAG_LABEL]: "Other",
  [AttributeTypes.IMAGE_TAG_OBJECT]: "Objects",
  [AttributeTypes.IMAGE_TAG_LOGO]: "Logos",
  [AttributeTypes.REPORTING_LABEL]: "Reporting Label",
  // [AttributeTypes.KEYWORD]: "Key Word",
  [AttributeTypes.WORD_COUNT]: "Word Count",
  // [AttributeTypes.WORD_COUNT_BRACKET]: "Word Count Groups",
  // [AttributeTypes.KEY_PHRASE]: "Key Phrase",
  // [AttributeTypes.ENTITY]: "Text Entity",
  [AttributeTypes.ENTITY_COMMERCIAL_ITEM]: "Commercial Item",
  [AttributeTypes.ENTITY_DATE]: "Date",
  [AttributeTypes.ENTITY_LOCATION]: "Location",
  [AttributeTypes.ENTITY_ORGANIZATION]: "Brand",
  [AttributeTypes.ENTITY_OTHER]: "Other",
  [AttributeTypes.ENTITY_PERSON]: "Celebrity",
  [AttributeTypes.ENTITY_QUANTITY]: "Price & Offers",
  [AttributeTypes.ENTITY_TITLE]: "Title",
  [AttributeTypes.COLOR_THEORY_COLOR]: "Color",
  [AttributeTypes.COLOR_THEORY_COLOR_GROUP]: "Color Group",
  [AttributeTypes.COLOR_THEORY_PANTONE_COLOR_GROUP]: "Pantone Color Group",
  [AttributeTypes.COLOR_THEORY_PRIMARY_COLOR_GROUP]: "Primary Color Group",
  [AttributeTypes.COLOR_THEORY_TEMPERATURE]: "Color Temperature",
  [AttributeTypes.COLOR_THEORY_TEMPERATURE_GROUP]: "Color Temperature Group",
  [AttributeTypes.IMAGE_TAG_DOMINANT_COLOR]: "Image Tag Dominant Color",
  [AttributeTypes.ENTITY_EVENT]: "Event",
};

export const excludedFromTimelineChart = new Set([
  AttributeTypes.WORD_COUNT,
  AttributeTypes.WORD_COUNT_GROUP,
  AttributeTypes.TEXT_OVERLAY,
  AttributeTypes.COLOR_THEORY_TEMPERATURE,
  AttributeTypes.COLOR_THEORY_TEMPERATURE_GROUP,
  AttributeTypes.IMAGE_VALUE,
]);
