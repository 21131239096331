import React, { useCallback, useMemo } from "react";
import { DatePicker, TimeRangePickerProps } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { RangePickerProps } from "antd/es/date-picker";

import { DATE_FORMAT } from "../../../constants/creativeInteligence";

export interface IInventoryDatePickerProps {
  maxDuration: number; /// in years
  allowUndefinedEndDate?: boolean;
  showExtendedDateList?: boolean; // flag to show or hide the date buttons more than 1 year
}

const InventoryDatePicker = ({
  maxDuration,
  allowUndefinedEndDate = false,
  showExtendedDateList,
  ...pickerProps
}: IInventoryDatePickerProps) => {
  const rangePresets: TimeRangePickerProps["presets"] = useMemo(() => {
    const presets: TimeRangePickerProps["presets"] = [
      { label: "Last 7 Days", value: [dayjs().add(-7, "d"), dayjs()] },
      { label: "Last 30 days", value: [dayjs().add(-30, "d"), dayjs()] },
      { label: "Last Year", value: [dayjs().add(-365, "d").add(1, "d"), dayjs()] },
    ];

    if (showExtendedDateList) {
      presets.push({
        label: `Last ${maxDuration} Years`,
        value: [dayjs().add(-maxDuration, "y"), dayjs()],
      });
    }

    return presets;
  }, [maxDuration, showExtendedDateList]);

  const disabledDate: RangePickerProps["disabledDate"] = useCallback(
    (current: Dayjs) => current && current < dayjs().add(-maxDuration, "y"),
    [maxDuration]
  );

  return (
    <DatePicker.RangePicker
      presets={rangePresets}
      format={DATE_FORMAT}
      {...pickerProps}
      disabledDate={disabledDate}
      style={{ width: "100%" }}
      allowEmpty={allowUndefinedEndDate ? [false, true] : [false, false]}
    />
  );
};

export default InventoryDatePicker;
