import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

import { DomainConfig } from "./types";
import { getDeliveryConfig } from "./DeliveryConfigService";

interface ConfigState {
  storageBucket: string | null;
  config: DomainConfig | null;
  initialized: boolean;
  init: () => Promise<DomainConfig | null>;
}

export const useConfigStore = create<ConfigState>()(
  devtools(
    immer((set, get) => {
      return {
        storageBucket: null,
        config: null,
        initialized: false,

        init: async () => {
          const { initialized, config } = get();

          if (initialized && config) {
            return config;
          }

          try {
            const domainConfig = await getDeliveryConfig();

            if (!domainConfig) {
              return null;
            }

            set((state) => {
              state.storageBucket = domainConfig.storageInfo.s3Bucket;
              state.config = domainConfig;
              state.initialized = true;
            });

            return domainConfig;
          } catch {
            return null;
          }
        },
      };
    })
  )
);
