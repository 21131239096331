import { Typography, Flex } from "antd";

import { ReactComponent as NoDataSvg } from "../../../../assets/no-data.svg";

import "./UniqueCreativeDeliverable.scss";

const { Text } = Typography;

const UniqueCreativeDeliverableNoData = () => {
  return (
    <Flex vertical gap="middle" align="center">
      <NoDataSvg />
      <Text>No data</Text>
    </Flex>
  );
};

export default UniqueCreativeDeliverableNoData;
