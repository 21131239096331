import { Brand, DashboardEntity, RELATIONS } from "../../types/entities";

import { BRAND_API_URL } from "./brand.query";
import { useAllPagesEntityQuery } from "./useInfiniteEntityQuery";

export const BRAND_DASHBOARDS_QUERY_KEY = "brandDashboards";

export function useBrandDashboards(brandId: string | undefined) {
  return useAllPagesEntityQuery<Brand, DashboardEntity>({
    queryKey: [BRAND_DASHBOARDS_QUERY_KEY, brandId ?? ""],
    urlPrefix: BRAND_API_URL,
    relationName: RELATIONS.BRAND_DASHBOARDS,
    objectId: brandId,
  });
}
