import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";

import { InsightsEndpoint } from "../../constants/creativeInteligence";
import type { MetricStats } from "../../types/creativeInteligence";

import { useCurrentDashboardTags } from "../entities/dashboard.query";
import { useBaseInsightsOptions, useDateRangeInsightsOptions, fetchInsightData } from "../insights/insights.queries";
import { TagPayload, ComputeTagsMetricStatsOptions } from "../insights/types";

export interface TagStats {
  kpiMetricStats: MetricStats;
  confidenceMetricStats: MetricStats;
  utilisation: number;
}

export const useComputeTags = ({ queryKey, tags }: { queryKey: string; tags: TagPayload[] | undefined }) => {
  const baseParams = useBaseInsightsOptions();
  const dateRangeBreakdowns = useDateRangeInsightsOptions();

  let options: ComputeTagsMetricStatsOptions | null = null;

  if (baseParams && dateRangeBreakdowns && tags) {
    options = {
      ...baseParams,
      dynamicBreakdowns: dateRangeBreakdowns,
      tags,
    };
  }

  return useQuery({
    queryKey: [queryKey, options],
    queryFn: () => {
      return fetchInsightData<Record<string, TagStats>, ComputeTagsMetricStatsOptions>(
        InsightsEndpoint.ComputeTags,
        options!
      );
    },
    select: (response) => response.data,
    enabled: !!options && !!tags?.length,
    staleTime: 1000 * 60, // 1 minute
  });
};

const computeTagsKey = "computeTags";

export const useComputeReportingTags = () => {
  const tags = useCurrentDashboardTags();

  const pseudoTags = useMemo(() => {
    return tags.data?.map((tag) => {
      return {
        tagId: tag.tagId,
        advancedSearch: tag?.advancedSearch,
      };
    });
  }, [tags]);

  return useComputeTags({
    queryKey: computeTagsKey,
    tags: pseudoTags,
  });
};
