import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { SelfServiceExtractor } from "../types/entities";

export const ROUTES = {
  INDEX: "/",
  LOGIN: "/login",
  BRAND: "/brand",
  DASHBOARD: "/dashboard",
  TAG: "/tag",
  INVENTORIES: "/inventories",
  DELIVERIES: "/deliveries",
  ADD_INVENTORY: "/add-inventory",
  ADD_DCM_INVENTORY: "/add-inventory/" + SelfServiceExtractor.EXTDCM_INVENTORY,
  ADD_FB_INVENTORY: "/add-inventory/" + SelfServiceExtractor.EXTFB_INVENTORY,
  ADD_DV360_INVENTORY: "/add-inventory/" + SelfServiceExtractor.EXTDV360_INVENTORY,
  ADD_TIKTOK_INVENTORY: "/add-inventory/" + SelfServiceExtractor.EXTTIKTOK_INVENTORY,
  ADD_LINKEDIN_INVENTORY: "/add-inventory/" + SelfServiceExtractor.EXTLINKEDIN_INVENTORY,
  ADD_DELIVERY: "/add-delivery",
} as const;

export function useRouterPaths() {
  const { brandId, dashboardId, tagId, inventoryId, deliveryId } = useParams();

  const brandIndexPath = ROUTES.BRAND;
  const brandPath = useMemo(() => brandIndexPath + "/" + brandId, [brandId, brandIndexPath]);
  const dashboardIndexPath = useMemo(() => brandPath + ROUTES.DASHBOARD, [brandPath]);
  const dashboardPath = useMemo(() => dashboardIndexPath + "/" + dashboardId, [dashboardIndexPath, dashboardId]);
  const tagIndexPath = useMemo(() => dashboardPath + ROUTES.TAG, [dashboardPath]);
  const tagPath = useMemo(() => tagIndexPath + "/" + tagId, [tagIndexPath, tagId]);

  const jobIndexPath = useMemo(() => brandPath + ROUTES.INVENTORIES, [brandPath]);
  const jobPath = useMemo(() => jobIndexPath + "/" + inventoryId, [jobIndexPath, inventoryId]);
  const jobDeliveryIndexPath = useMemo(() => jobPath + ROUTES.DELIVERIES, [jobPath]);
  const jobDeliveryPath = useMemo(() => jobDeliveryIndexPath + "/" + deliveryId, [jobDeliveryIndexPath, deliveryId]);

  const addDCMjobIndexPath = useMemo(() => jobIndexPath + ROUTES.ADD_DCM_INVENTORY, [jobIndexPath]);
  const addFBjobIndexPath = useMemo(() => jobIndexPath + ROUTES.ADD_FB_INVENTORY, [jobIndexPath]);
  const addDV360jobIndexPath = useMemo(() => jobIndexPath + ROUTES.ADD_DV360_INVENTORY, [jobIndexPath]);
  const addTiktokJobIndexPath = useMemo(() => jobIndexPath + ROUTES.ADD_TIKTOK_INVENTORY, [jobIndexPath]);
  const addLinkedinJobIndexPath = useMemo(() => jobIndexPath + ROUTES.ADD_LINKEDIN_INVENTORY, [jobIndexPath]);
  const addDeliveryJobIndexPath = useMemo(() => jobPath + ROUTES.ADD_DELIVERY, [jobPath]);

  return {
    brandIndexPath,
    brandPath,
    dashboardIndexPath,
    dashboardPath,
    tagIndexPath,
    tagPath,
    jobIndexPath,
    jobPath,
    jobDeliveryIndexPath,
    jobDeliveryPath,
    addDCMjobIndexPath,
    addFBjobIndexPath,
    addDV360jobIndexPath,
    addTiktokJobIndexPath,
    addLinkedinJobIndexPath,
    addDeliveryJobIndexPath,
  };
}
