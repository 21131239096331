import { type Attribute } from "../../../../../../types/attribute";

import { AttributeTypes, excludedFromTimelineChart } from "./consts";

export function mockImageAttributes(attributes: Attribute[]) {
  return attributes.map((attribute) => ({
    ...attribute,
    timeline: [{ start: 0, end: 1 }],
  }));
}

export function groupAttributes(attributes: Attribute[]) {
  return attributes.reduce(
    (acc, attribute) => {
      if (attribute.type === AttributeTypes.TEXT_OVERLAY) {
        acc.textDensityAttributes.push(attribute);
      } else if (attribute.type === AttributeTypes.WORD_COUNT) {
        acc.wordCountAttributes.push(attribute);
      } else if (attribute.timeline?.length && !excludedFromTimelineChart.has(attribute.type)) {
        acc.timelineAttributes.push(attribute);
      }

      return acc;
    },
    {
      timelineAttributes: [] as Attribute[],
      textDensityAttributes: [] as Attribute[],
      wordCountAttributes: [] as Attribute[],
    }
  );
}

export function filterAttributes(attributes: Attribute[], filters: Set<string>) {
  if (filters.size === 0) {
    return attributes;
  }

  return attributes.filter((attribute) => filters.has(attribute.type));
}
