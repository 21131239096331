import { useMemo } from "react";
import { RuleObject } from "antd/es/form";

export function useUniqueNameValidator<T extends { name: string }>(items: T[], entityName: string) {
  return useMemo(
    () => (_: RuleObject, value: string | undefined) => {
      if (items?.find(({ name }) => name.toLowerCase() === value?.toLowerCase())) {
        return Promise.reject(new Error(`${entityName} with this name already exists. Please choose another one.`));
      }

      return Promise.resolve();
    },
    [items, entityName]
  );
}
