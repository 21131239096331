import { Amplify } from "aws-amplify";
import { Hub } from "aws-amplify/utils";
import { signInWithRedirect, signIn as cognitoSignIn, signOut as cognitoSignOut, fetchAuthSession, JWT } from "aws-amplify/auth";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { DConfOutput } from "dconf-service/src/types";

import { getGroupsFromCognito } from "./utils";

interface AuthState {
  userId: string | null;
  userName: string | null;
  givenName: string | null;
  cognitoGroups: string[] | null;
  isAuthenticated: boolean | null;
  isLoading: boolean;
  errorMessage: string | null | undefined;
  init: (domainConfig: DConfOutput) => void;
  checkSession: () => Promise<boolean>;
  federatedSignIn: (customProvider: string) => Promise<unknown>;
  signIn: (username: string, password: string) => Promise<boolean>;
  signOut: () => void;
}

export const useAuthStore = create<AuthState>()(
  devtools(
    immer((set) => {
      const updateUser = (user: JWT | null) => {
        set((state) => {
          if (!user) {
            state.isAuthenticated = false;
            state.userId = null;
            state.userName = null;
            state.givenName = null;
            state.cognitoGroups = null;

            return;
          }

          state.isAuthenticated = true;
          state.userId = user.payload.sub!;
          state.userName = user.payload.family_name as string;
          state.givenName = user.payload.given_name as string;

          state.cognitoGroups = getGroupsFromCognito(user);
        });

        return;
      };

      const setLoading = (loading: boolean) =>
        set((state) => {
          state.isLoading = loading;
        });

      const setErrorMessage = (message: string | null | undefined) =>
        set((state) => {
          state.errorMessage = message;
        });

      const setSuccess = () => {
        setLoading(false);
        setErrorMessage(null);
      };

      const setFailure = (message: string | null | undefined) => {
        setLoading(false);
        setErrorMessage(message);
      };

      return {
        userId: null,
        userName: null,
        givenName: null,
        cognitoGroups: null,
        isAuthenticated: null,
        isLoading: false,
        errorMessage: null,

        init: (domainConfig: DConfOutput) => {
          Amplify.configure({
            Auth: {
              Cognito: {
                userPoolId: domainConfig.auth.cognito.userPoolId,
                userPoolClientId: domainConfig.auth.cognito.userPoolWebClientId,
                loginWith: {
                  oauth: {
                    ...domainConfig.auth.cognito.oauth,
                    redirectSignIn: [domainConfig.auth.cognito.oauth.redirectSignIn],
                    redirectSignOut: [domainConfig.auth.cognito.oauth.redirectSignOut],
                    scopes: domainConfig.auth.cognito.oauth.scope,
                    responseType: domainConfig.auth.cognito.oauth.responseType as "code" | "token",
                  },
                },
              },
            },
          });
          Hub.listen("auth", async ({ payload: { event, message } }) => {
            switch (event) {
              case "signedIn":
              case "signInWithRedirect":
                updateUser((await fetchAuthSession()).tokens?.idToken ?? null);
                setSuccess();
                break;
              case "signedOut":
                updateUser(null);
                setSuccess();
                break;
              case "signInWithRedirect_failure": {
                if (message === "User+is+not+assigned+to+the+client+application.%3B+error%3Daccess_denied") {
                  console.error("You need the tile to be assigned!");
                }

                setFailure(message);
                break;
              }

              case "tokenRefresh":
                updateUser((await fetchAuthSession()).tokens?.idToken ?? null);
                setSuccess();
                break;
              case "tokenRefresh_failure": {
                setFailure(message);
                updateUser(null);
                break;
              }
            }
          });
        },

        checkSession: async () => {
          setLoading(true);

          try {
            const { tokens } = await fetchAuthSession();

            updateUser(tokens?.idToken ?? null);

            return true;
          } catch {
            updateUser(null);

            return false;
          } finally {
            setLoading(false);
          }
        },

        federatedSignIn: async (customProvider = "ICDev") => {
          setLoading(true);

          try {
            await signInWithRedirect({ provider: {
              custom: customProvider,
            } });
          } catch (error) {
            console.error("federate login error", error);

            throw error;
          } finally {
            setLoading(false);
          }
        },

        signIn: async (username: string, password: string) => {
          try {
            await cognitoSignIn({ username, password });

            return true;
          } catch {
            return false;
          }
        },

        signOut: async () => {
          await cognitoSignOut();
        },
      };
    })
  )
);

const { init, checkSession, federatedSignIn, signIn, signOut } = useAuthStore.getState();

export const authActions = {
  init,
  checkSession,
  federatedSignIn,
  signIn,
  signOut,
};
