import { useMemo, useState } from "react";
import { Flex, UploadProps, message } from "antd";
import Dragger from "antd/es/upload/Dragger";
import { CloudUploadOutlined, LoadingOutlined } from "@ant-design/icons";

import { getAuthHeader } from "../../utils/jwt";
import { getDefaultHeaders, request } from "../../utils/api";

import "./FileUploader.scss";

export interface TemporaryFileLocation {
  bucket: string;
  key: string;
}

interface UploadConfig {
  signedUrl: string;
  temporaryFileLocation: TemporaryFileLocation;
}

export const uploadImage = async (file: File) => {
  const authHeader = await getAuthHeader();

  // no api key is needed for this endpoint
  const headers = getDefaultHeaders(null);

  const { data: config } = await request<UploadConfig>(
    `/v1/transfer/upload-config/single/put?contentType=${file.type}`,
    {
      ...authHeader,
      ...headers,
    }
  );

  await fetch(config.signedUrl, { method: "PUT", body: file });

  return config.temporaryFileLocation;
};

export async function dataOrObjectUrlToFile(dataUrl: string, name = "file") {
  const response = await fetch(dataUrl);
  const blob = await response.blob();

  return new File([blob], name, { type: blob.type });
}

interface Props {
  logoUrl?: string;
  isUploading: boolean;
  setIsUploading: (isUploading: boolean) => void;
  onSuccess: (fileLocation: TemporaryFileLocation) => void;
  testid?: string;
}

export const FileUploader = ({ logoUrl, onSuccess, isUploading, setIsUploading, testid = "file-uploader" }: Props) => {
  const [blobUrl, setBlobUrl] = useState<string | null>(null);

  const uploadProps: UploadProps = useMemo(
    () => ({
      accept: "image/png, image/jpeg",
      showUploadList: false,
      beforeUpload: () => false,
      multiple: false,
      onChange: async (info) => {
        setIsUploading(true);

        try {
          const imgUrl = URL.createObjectURL(info.file as unknown as File);

          setBlobUrl(imgUrl);

          const file = await dataOrObjectUrlToFile(imgUrl);
          const temporaryFileLocation = await uploadImage(file);

          onSuccess(temporaryFileLocation);
        } catch {
          message.error("Failed to upload file");
        } finally {
          setIsUploading(false);
        }
      },
    }),
    [onSuccess, setIsUploading]
  );

  const imgUrl = blobUrl ?? logoUrl;

  return (
    <Flex vertical gap="small" data-testid={`${testid}-wrapper`}>
      {!!imgUrl && <img className="file-uploader__img" src={imgUrl} data-testid={`${testid}-preview-image`} />}

      <Dragger {...uploadProps} data-testid={`${testid}-input`}>
        {isUploading ? <LoadingOutlined /> : <CloudUploadOutlined />}
        <p className="ant-upload-drag-icon">Drag and drop or click to upload an image</p>
      </Dragger>
    </Flex>
  );
};
