import { FormListFieldData } from "antd";

import { ReportKpis } from "../../../../../../pages/DashboardPage/queries/reportKPIs.query";
import { getMetricsLabel } from "../../../../../../pages/DashboardPage/queries/utils";
import { DiscardMetricThreshold } from "../../../../../../types/creativeInteligence";
import { AIServices } from "../../../../../../types/entities";

export const aiServiceMetricName = "aiService";
const confidenceMetricName = "confidence";

export function filterDiscardOptions(
  formDiscardThresholds: DiscardMetricThreshold[] | undefined,
  field: FormListFieldData,
  option: ReturnType<typeof mapMetricsToDiscardMetricsOptions>[number]
) {
  return !!formDiscardThresholds?.find((d) => {
    if (d.metric === formDiscardThresholds[field.name]?.metric) {
      return false;
    }

    if (d.metric === "aiService" && option.value === "confidence") {
      return true;
    }

    if (d.metric === "confidence" && option.value === "aiService") {
      return true;
    }

    return d.metric === option.value;
  });
}

export function mapMetricsToDiscardMetricsOptions(reportKpis: ReportKpis | undefined) {
  if (!reportKpis) {
    return [];
  }

  return [
    {
      label: getMetricsLabel(aiServiceMetricName),
      value: aiServiceMetricName,
      metricValue: AIServices.AWS,
    },
    {
      label: getMetricsLabel(confidenceMetricName),
      value: confidenceMetricName,
      metricValue: 0,
    },
    ...Object.entries(reportKpis).map(([metricName, { label, avg }]) => ({
      label,
      value: metricName,
      metricValue: avg,
    })),
  ];
}
