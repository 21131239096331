import React from "react";
import { ConfigProvider, Flex, Layout } from "antd";
import { useRouteError } from "react-router-dom";

import { imagineTheme } from "../utils/imagine";
import MainHeader from "../components/Layout/MainHeader";

const ErrorPage: React.FunctionComponent = () => {
  const { message: errorMessage = "" } = useRouteError() as Error;

  return (
    <ConfigProvider theme={imagineTheme}>
      <Layout className="main-layout">
        <Layout.Header className="main-layout__header">
          <MainHeader />
        </Layout.Header>
        <Layout.Content>
          <Flex vertical justify="space-between" align="center" style={{ padding: "var(--default-page-padding" }}>
            <h3>Something went wrong</h3>
            {errorMessage && <h4>{errorMessage}</h4>}
          </Flex>
        </Layout.Content>
      </Layout>
    </ConfigProvider>
  );
};

export default ErrorPage;
