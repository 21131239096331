import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Button, Form, Input, Modal, Select, Typography, message } from "antd";

import { CreateDashboard } from "../../../types/entities";
import { useCreateDashboard } from "../../../queries/entities/dashboard.mutations";
import { useBrandDashboards } from "../../../queries/entities/brandDashboards.query";
import { useSuccessfulBrandDeliveries } from "../../../queries/entities/brandDeliveries.query";
import { useUniqueNameValidator } from "../../../utilityHooks/useUniqueNameValidator";
import { getInventoryAndDeliveryIds, getResourceGroupIds } from "../../../utils/deliveriesUtils";
import { ANALYSIS_TYPES, AnalysisTypeValue } from "../../../utils/dashboards";

import BrandDeliveriesFormSelect from "./BrandDeliveriesFormSelect";

const { Title } = Typography;

interface FormData {
  dashboardType: AnalysisTypeValue;
  domoDashboardUrl?: string;
  name: string;
  resourceGroupIds?: string[];
  sourceDashboardId?: string;
  sourceEnvironment?: string;
  sourceStack?: string;
}

export const CreateDashboardModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const { brandId } = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm<FormData>();
  const sourceDashboardId = Form.useWatch("sourceDashboardId", form);
  const sourceStack = Form.useWatch("sourceStack", form);
  const sourceEnvironment = Form.useWatch("sourceEnvironment", form);
  const dashboardType = Form.useWatch("dashboardType", form);

  const dashboards = useBrandDashboards(brandId);
  const successfulBrandDeliveries = useSuccessfulBrandDeliveries(brandId);
  const createDashboard = useCreateDashboard();

  const brandResourceGroupIds = useMemo(() => {
    return getResourceGroupIds(successfulBrandDeliveries.data?.items ?? []);
  }, [successfulBrandDeliveries.data?.items]);

  const validator = useUniqueNameValidator(dashboards.data?.items ?? [], "Dashboard");

  const handleOnClose = useCallback(() => {
    form.resetFields();
    onClose();
  }, [form, onClose]);

  const handleOnSave = useCallback(
    async (values: FormData) => {
      try {
        if (!successfulBrandDeliveries.data) {
          messageApi.error({ content: "Failed to load brand deliveries", duration: 3 });

          return;
        }

        let syncData: CreateDashboard["syncData"] | undefined = undefined;

        if (values.sourceDashboardId && values.sourceStack && values.sourceEnvironment) {
          syncData = {
            source: {
              dashboardId: values.sourceDashboardId,
              stack: values.sourceStack,
              environment: values.sourceEnvironment,
            },
            targetBrandId: brandId,
          };
        }

        const { deliveryIds, inventoryIds } = getInventoryAndDeliveryIds(
          values.resourceGroupIds ?? [],
          successfulBrandDeliveries.data.items
        );

        const newDash = await createDashboard.mutateAsync({
          dashboardType: values.dashboardType,
          brandId,
          deliveryIds,
          domoDashboardUrl: values.domoDashboardUrl,
          inventoryIds,
          name: values.name,
          resourceGroupIds: values.resourceGroupIds,
          syncData,
        });

        messageApi.success({ content: `"${newDash.name}" dashboard created successfully`, duration: 3 });
        handleOnClose();
      } catch {
        messageApi.error({ content: createDashboard.error?.message ?? "Something went wrong", duration: 3 });
      }
    },
    [successfulBrandDeliveries, brandId, createDashboard, handleOnClose, messageApi]
  );

  const handleResourcesPaste = useCallback(async () => {
    try {
      if (!navigator.clipboard.readText) {
        messageApi.error({ content: "Clipboard API is not supported in this browser", duration: 3 });

        return;
      }

      const text = await navigator.clipboard.readText();
      const data = (JSON.parse(text) as { dashboardId: string; stack: string; environment: string }) ?? {};

      form.setFieldValue("sourceDashboardId", data.dashboardId);
      form.setFieldValue("sourceStack", data.stack);
      form.setFieldValue("sourceEnvironment", data.environment);
    } catch {
      messageApi.error({ content: "Invalid data in clipboard", duration: 3 });
    }
  }, [form, messageApi]);

  const hasSyncData = !!((sourceDashboardId ?? sourceStack) ?? sourceEnvironment);

  const modalFooter = [
    <Button
      key="confirm"
      type="primary"
      onClick={form.submit}
      loading={createDashboard.isPending}
      data-testid="create-dashboard-modal-confirm-button"
    >
      Create
    </Button>,
  ];

  return (
    <>
      {contextHolder}

      <Modal
        title={<Title level={4}>Create dashboard</Title>}
        open={isOpen}
        footer={modalFooter}
        onCancel={handleOnClose}
        cancelButtonProps={{ ["data-testid"]: "create-dashboard-modal-close-button" }}
      >
        <Form form={form} layout="vertical" onFinish={handleOnSave} initialValues={{ dashboardType: AnalysisTypeValue.postFlightIC }}>
          <Form.Item
            label="Analysis Type"
            name="dashboardType"
            rules={[{ required: true, message: "Please input the analysis type!" }]}
          >
            <Select data-testid="create-dashboard-modal-analysis-type-input" options={ANALYSIS_TYPES} />
          </Form.Item>

          <Form.Item
            label="Dashboard name"
            name="name"
            rules={[{ required: true, message: "Please input the dashboard name!" }, { validator }]}
          >
            <Input data-testid="create-dashboard-modal-name-input" />
          </Form.Item>

          {dashboardType === AnalysisTypeValue.postFlightDomo ? (
            <Form.Item
              label="Domo dashboard URL"
              name="domoDashboardUrl"
              rules={[{ required: true, message: "Please input the domo dashboard url!" }, {
                type: "url",
                message: "Domo dashboard mult be a valid url.",
              }]}
            >
              <Input data-testid="create-dashboard-modal-domo-dashboard-url-input" />
            </Form.Item>
          )
            : (
                <>
                  <BrandDeliveriesFormSelect
                    brandResourceGroupIds={brandResourceGroupIds}
                    loading={successfulBrandDeliveries.isPending}
                  />

                  <Form.Item label="Enrich resources">
                    <Button data-testid="edit-dashboard-modal-enriched-resources-copy-button" onClick={handleResourcesPaste}>
                      Paste resources
                    </Button>
                  </Form.Item>

                  <Form.Item
                    label="Source dashboard ID"
                    name="sourceDashboardId"
                    rules={[{ required: hasSyncData, message: "All sync fields are required if using a source dashboard" }]}
                  >
                    <Input data-testid="create-dashboard-modal-source-dashboard-id-input" />
                  </Form.Item>

                  <Form.Item
                    label="Source stack"
                    name="sourceStack"
                    rules={[{ required: hasSyncData, message: "All sync fields are required if using a source dashboard" }]}
                  >
                    <Input data-testid="create-dashboard-modal-source-stack-input" />
                  </Form.Item>

                  <Form.Item
                    label="Source environment"
                    name="sourceEnvironment"
                    rules={[{ required: hasSyncData, message: "All sync fields are required if using a source dashboard" }]}
                  >
                    <Input data-testid="create-dashboard-modal-source-environment-input" />
                  </Form.Item>
                </>
              )}
        </Form>
      </Modal>
    </>
  );
};
