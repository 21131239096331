import { ChartOptions } from "chart.js";

import { numberToShortKM, utilisationToValue } from "../../../../../utils";
import { CurrentMetrics } from "../../../../../queries/metrics.queries";
import { SummaryStats } from "../../../../../types/creativeInteligence";
import { getMetricStat } from "../../../../../utils/analytics";

import { getUtilizationTickValue, getTickValue } from "./getTickValues";

function getXTickValue(v: string | number) {
  return numberToShortKM(+v);
}

interface ScatterScalesInput {
  isUtilization: boolean;
  isLogScale?: boolean;
  currentMetricsData: CurrentMetrics | undefined;
  multiDataset: boolean;
  summaryStats: SummaryStats[] | undefined;
}

export function getScatterScales({
  isLogScale,
  isUtilization,
  currentMetricsData,
  multiDataset,
  summaryStats,
}: ScatterScalesInput): ChartOptions<"scatter">["scales"] {
  const kpiMetricName = currentMetricsData?.kpiMetric.metric;
  const kpiMetricValue = currentMetricsData?.kpiMetric.value;

  const confidenceMetricName = currentMetricsData?.confidenceMetric.metric;
  const confidenceMetricValue = currentMetricsData?.confidenceMetric.value;

  let kpiScaleMax;
  let confidenceScaleMax;

  if (
    !multiDataset
    && kpiMetricName !== undefined
    && confidenceMetricName !== undefined
    && kpiMetricValue !== undefined
    && confidenceMetricValue !== undefined
    && summaryStats?.length
  ) {
    let kpiMetricOverallMax = undefined;

    if (isUtilization) {
      kpiMetricOverallMax = Math.max(...summaryStats.map((stats) => +utilisationToValue(stats.utilisation)));
    } else {
      const kpiStat = getMetricStat(kpiMetricName);

      kpiMetricOverallMax = Math.max(...summaryStats.map((stats) => stats.kpiMetric[kpiStat]));
    }

    kpiScaleMax = Math.max(kpiMetricValue * 2, (kpiMetricOverallMax ?? 0) * 1.05);

    const confidenceStat = getMetricStat(confidenceMetricName);
    const confidenceMetricOverallMax = Math.max(...summaryStats.map((stats) => stats.confidenceMetric[confidenceStat]));

    confidenceScaleMax = Math.max(confidenceMetricValue * 2, (confidenceMetricOverallMax ?? 0) * 1.05);
  }

  return {
    y: {
      type: isLogScale ? "logarithmic" : "linear",
      beginAtZero: true,
      min: 0,
      max: kpiScaleMax,
      ticks: {
        display: true,
        callback: (value: number | string) => (isUtilization ? getUtilizationTickValue(value) : getTickValue(value)),
      },
      grid: {
        drawOnChartArea: false,
      },
    },
    x: {
      type: isLogScale ? "logarithmic" : "linear",
      beginAtZero: true,
      min: 0,
      max: confidenceScaleMax,
      ticks: {
        display: true,
        callback: getXTickValue,
      },
      grid: {
        drawOnChartArea: false,
      },
    },
  };
}
