import { useMemo } from "react";
import { Typography } from "antd";

import { useCurrentMetrics } from "../../../../queries/metrics.queries";

import { useKeyTakeawayInsight } from "./keyTakeaway.query";

const { Text } = Typography;

interface KeyTakeawaySubtitleProps {
  name: string;
  advancedSearch: string;
  notMatchQuery?: boolean;
}

const KeyTakeawaySubtitle = ({ name, advancedSearch, notMatchQuery = false }: KeyTakeawaySubtitleProps) => {
  const insights = useKeyTakeawayInsight(advancedSearch, notMatchQuery);
  const currentMetrics = useCurrentMetrics();

  const { countText, averagingText, aboveText } = useMemo(() => {
    if (!insights.data || !currentMetrics.data) {
      return {};
    }

    const { adsAboveKPI, count, totalCount, kpiMetric } = insights.data.summaryStats;
    const percentAbove = Math.round((adsAboveKPI / count) * 100);
    const kpiMetricAvg = (kpiMetric?.avg > 10 ? Math.round(kpiMetric?.avg) : kpiMetric?.avg?.toFixed(3)) ?? 0;

    const kpiMetricLabel = currentMetrics.data.kpiMetricLabel;
    const kpiMetricValue = currentMetrics.data.kpiMetric.value;

    return {
      countText: `${count} out of ${totalCount} ads${notMatchQuery ? " does not" : ""} contain ${name}`,
      averagingText: `, averaging ${kpiMetricAvg} ${kpiMetricLabel.toLocaleLowerCase()}.`,
      aboveText: kpiMetricValue
        ? ` ${percentAbove || 0}% of these ads are above your benchmark of ${kpiMetricValue}`
        : "",
    };
  }, [currentMetrics.data, insights.data, name, notMatchQuery]);

  if (!insights.data?.items.length) {
    return "-";
  }

  return (
    <Text strong>
      {countText}
      {averagingText}
      {aboveText}
    </Text>
  );
};

export default KeyTakeawaySubtitle;
