import React, { useState, useCallback } from "react";
import { SearchOutlined, SettingFilled } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";

import MainPageContentHeader from "../../../../components/Layout/MainPageContentHeader";
import { EditDashboardModal } from "../../../../pages/DashboardListPage/components/EditDashbordModal";
import { useCurrentDashboard } from "../../../../queries/entities/dashboard.query";
import { useRouterPaths } from "../../../../hooks/useRouterPaths";
import CopyResourcesButton from "../../../../pages/DashboardListPage/components/CopyResourcesButton";

import Benchmarks from "./components/Benchmarks/Benchmarks";
import Filters from "./components/Filters/Filters";

import "./SecondaryHeader.scss";

interface SecondaryHeaderProps {
  searchComponent: React.ReactNode;
}

const SecondaryHeader = ({ searchComponent }: SecondaryHeaderProps) => {
  const navigate = useNavigate();
  const { brandId, dashboardId } = useParams();
  const currentDashboard = useCurrentDashboard();
  const { brandPath } = useRouterPaths();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = useCallback(() => setIsModalOpen(true), []);
  const handleModalClose = useCallback(() => setIsModalOpen(false), []);

  const handleModalDelete = useCallback(() => {
    setIsModalOpen(false);
    navigate(brandPath);
  }, [brandPath, navigate]);

  return (
    <>
      <MainPageContentHeader>
        <SearchOutlined />

        {searchComponent}
        <Filters />
        <Benchmarks />
        <CopyResourcesButton dashboardId={dashboardId} />
        <SettingFilled className="secondary-header-dash-settings" onClick={handleModalOpen} />
      </MainPageContentHeader>

      <EditDashboardModal
        isOpen={isModalOpen}
        brandId={brandId}
        dashboard={currentDashboard.data}
        onClose={handleModalClose}
        onDelete={handleModalDelete}
      />
    </>
  );
};

export default SecondaryHeader;
