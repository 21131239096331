import { RELATIONS, Brand, Delivery, DeliveryWithId } from "../../types/entities";

import { useAllPagesEntityQuery } from "./useInfiniteEntityQuery";
import { BRAND_API_URL } from "./brand.query";

export const BRAND_DELIVERIES_QUERY_KEY = "brandDeliveries";

export function useBrandDeliveries<T = DeliveryWithId[]>(
  brandId: string | undefined,
  select?: (resp: DeliveryWithId[]) => T
) {
  return useAllPagesEntityQuery<Brand, Delivery, T>({
    queryKey: [BRAND_DELIVERIES_QUERY_KEY, brandId ?? ""],
    urlPrefix: BRAND_API_URL,
    relationName: RELATIONS.BRAND_DELIVERIES,
    objectId: brandId,
    select,
  });
}

export function useSuccessfulBrandDeliveries(brandId: string | undefined) {
  return useBrandDeliveries(brandId, (deliveries) =>
    deliveries.filter((delivery) => !!delivery?.resourceOutput?.output?.success)
  );
}
