import { type ChartDataset, ChartOptions } from "chart.js";

import { type Metrics } from "../../../../../../../../types/deliverable";
import { COLORS_HEX } from "../../../../../Charts/config";
import { type MetricStats } from "../../../../../../../../types/creativeInteligence";

function tickCallback(value: number | string) {
  const date = new Date(0);

  date.setSeconds(Number(value));

  return date.toISOString().substring(15, 19);
}

export const getDropoffChartOptions = ({ xMax }: { xMax: number }): Partial<ChartOptions<"line">> => ({
  animation: false,
  maintainAspectRatio: false,
  responsive: true,

  plugins: {
    tooltip: {
      enabled: true,
    },
    legend: {
      display: false,
    },
  },

  scales: {
    x: {
      type: "linear",
      position: "bottom",
      min: 0,
      suggestedMax: xMax,
      ticks: {
        callback: tickCallback,
      },
      border: {
        display: false,
      },
      grid: {
        display: true,
      },
    },
    y: {
      min: 0,
      max: 1,
      border: {
        display: false,
      },
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
  },
});

const getDropoffPoints = (data: number[], duration: number) => {
  if (!data?.length || !data[0] || !duration) {
    return [];
  }

  if (data.length === 5) {
    return [
      { y: 1, x: 0 },
      { y: data[1] / data[0], x: duration * 0.25 },
      { y: data[2] / data[0], x: duration * 0.5 },
      { y: data[3] / data[0], x: duration * 0.75 },
      { y: data[4] / data[0], x: duration },
    ];
  }

  return [
    { y: 1, x: 0 },
    { y: data[1] / data[0], x: duration * 0.25 },
    { y: data[2] / data[0], x: duration * 0.5 },
    { y: data[3] / data[0], x: duration * 0.75 },
    { y: data[4] / data[0], x: duration * 0.95 },
    { y: data[5] / data[0], x: duration },
  ];
};

const mapMetricsNameToIndex: Record<keyof Metrics, number> = {
  video_started: 0,
  video_play_actions: 0,
  "video_play_actions|video_view": 0,
  "video_play_actions|video_view_time": 0,
  activeViewPercentAudibleAndVisibleAtStart: 0,

  video_p25: 1,
  video_p25_watched_actions: 1,
  "video_p25_watched_actions|video_view": 1,
  activeViewPercentAudibleAndVisibleAtFirstQuartile: 1,

  video_p50: 2,
  video_p50_watched_actions: 2,
  "video_p50_watched_actions|video_view": 2,
  activeViewPercentAudibleAndVisibleAtMidpoint: 2,

  video_p75: 3,
  video_p75_watched_actions: 3,
  "video_p75_watched_actions|video_view": 3,
  activeViewPercentAudibleAndVisibleAtThirdQuartile: 3,

  video_p95: 4,
  video_p95_watched_actions: 4,
  "video_p95_watched_actions|video_view": 4,
  activeViewPercentAudibleAndVisibleAtCompletion: 4,

  video_p100: 5,
  video_p100_watched_actions: 5,
  "video_p100_watched_actions|video_view": 5,
};

const getChartData = (metrics: Metrics, duration: number) => {
  const data = Object.keys(mapMetricsNameToIndex).reduce((acc, metricName) => {
    if (metrics[metricName] !== null && metrics[metricName] !== undefined) {
      acc[mapMetricsNameToIndex[metricName]] = Number(metrics[metricName]);
    }

    return acc;
  }, [] as number[]);

  return getDropoffPoints(data, duration);
};

const getAvgData = (metricStats: Record<string, MetricStats>, duration: number) => {
  const data = Object.keys(mapMetricsNameToIndex).reduce((acc, metric) => {
    if (metricStats[metric]) {
      acc.push(metricStats[metric]?.avg);
    }

    return acc;
  }, [] as number[]);

  return getDropoffPoints(data, duration);
};

export interface DataPoint {
  x: number;
  y: number;
}

export interface DropoffDataset extends ChartDataset<"line", DataPoint[]> {
  label: string;
  backgroundColor: string;
  borderColor: string;
  data: DataPoint[];
  fill: boolean;
  pointRadius: number;
  type: "line";
  borderWidth: number;
  borderDash?: number[];
  tension: number;
  order: number;
}

export const getDropoffDatasets = (
  metrics: Metrics | undefined,
  metricStats: Record<string, MetricStats> | undefined,
  duration: number
): DropoffDataset[] => {
  if (!metrics || !metricStats || !duration) {
    return [];
  }

  const data = getChartData(metrics, duration);
  const avgData = getAvgData(metricStats, duration);

  return [
    {
      label: "View-through rate",
      backgroundColor: COLORS_HEX.red,
      borderColor: COLORS_HEX.red,
      data,
      fill: false,
      pointRadius: 0,
      type: "line",
      borderWidth: 2,
      tension: 0.2,
      order: 0,
    },
    {
      label: "Average View-through rate",
      backgroundColor: COLORS_HEX.red,
      borderColor: `${COLORS_HEX.red}aa`,
      data: avgData,
      fill: false,
      pointRadius: 0,
      borderDash: [8, 2],
      borderWidth: 1,
      type: "line",
      order: 1,
      tension: 0.2,
    },
  ];
};
