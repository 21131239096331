import type { RouteObject } from "react-router-dom";
import { createBrowserRouter, Outlet } from "react-router-dom";

import RootLayout from "../components/Layout/RootLayout/RootLayout";

import ErrorPage from "../pages/ErrorPage";
import LoginPage from "../pages/Auth/LoginPage";
import DashboardListPage from "../pages/DashboardListPage/DashboardListPage";
import BrandListPage from "../pages/BrandPage/BrandListPage";
import AccessDeniedPage from "../pages/Auth/AccessDeniedPage";
import DashboardPage from "../pages/DashboardPage/DashboardPage";
import { CurrentTagReportPage, SearchTagReportPage } from "../pages/TagReportPage/TagReportPage";
import { jobsRoutes } from "../pages/Inventory/router";

import ProtectedRoute from "./ProtectedRoute";
import rootLoader from "./rootLoader";

const routes: RouteObject[] = [
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    loader: rootLoader,
    children: [
      {
        path: "/",
        element: (
          <ProtectedRoute>
            <Outlet />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: <BrandListPage />,
          },
          {
            path: "/brand/:brandId",
            element: <DashboardListPage />,
          },
          {
            path: "/brand/:brandId/dashboard/:dashboardId",
            element: <DashboardPage />,
          },
          {
            path: "/brand/:brandId/dashboard/:dashboardId/search",
            element: <SearchTagReportPage />,
          },
          {
            path: "/brand/:brandId/dashboard/:dashboardId/tag/:tagId",
            element: <CurrentTagReportPage />,
          },
          {
            path: "/brand/:brandId/inventories/",
            ...jobsRoutes,
          },
        ],
      },
      {
        path: "login",
        element: <LoginPage />,
      },
      {
        path: "access-denied",
        element: <AccessDeniedPage />,
      },
    ],
  },
];

const router = createBrowserRouter(routes);

export default router;
