import { Chart as ChartJS } from "chart.js";
import { Chart } from "react-chartjs-2";
import { useEffect, useMemo, useRef } from "react";

import { type Attribute } from "../../../../../../../../types/attribute";

import {
  getChartStyle,
  getTimelineChartOptions,
  getLabelsAndTypes,
  getTimelineDatasets,
  sortAttributes,
  TimelineDatapoint,
} from "./utils";

import "./GanttChart";

interface TimelineChartProps {
  attributes: Attribute[];
  progress: number;
  duration: number;
  setScrubbarPosition: (position: number) => void;
}

const TimelineChart = ({ attributes, progress, duration, setScrubbarPosition }: TimelineChartProps) => {
  const chartRef = useRef<ChartJS<"gantt", TimelineDatapoint[], string> | null>(null);

  const sortedAttributes = useMemo(() => {
    return attributes.toSorted(sortAttributes);
  }, [attributes]);

  const { displayLabels, attributeTypes } = useMemo(() => {
    return getLabelsAndTypes(sortedAttributes);
  }, [sortedAttributes]);

  const datasets = useMemo(() => {
    return getTimelineDatasets(attributeTypes, sortedAttributes);
  }, [attributeTypes, sortedAttributes]);

  const options = useMemo(() => getTimelineChartOptions({ xMax: duration }), [duration]);

  useEffect(() => {
    if (!chartRef.current?.scales.x) {
      return;
    }

    setScrubbarPosition(chartRef.current.scales.x.getPixelForValue((progress / 100) * duration));
  }, [duration, progress, setScrubbarPosition]);

  const chartStyle = useMemo(() => getChartStyle(displayLabels.length), [displayLabels]);

  return (
    <div style={{ ...chartStyle, position: "relative" }}>
      <Chart ref={chartRef} type="gantt" data={{ labels: displayLabels, datasets }} options={options} />
    </div>
  );
};

export default TimelineChart;
