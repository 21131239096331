import { Flex, Switch } from "antd";

import { useCreativeIntelligenceStore } from "../../../../../../../store/creativeIntelligence.store";
import { Deliverable } from "../../../../../../../types/deliverable";
import { useCampaignInfo } from "../../../../../../../queries/insights/campaignInfo.queries";

import "./TimelinePreview.scss";

interface Props {
  kpi?: number;
  avgKpi?: number;
  deliverable?: Deliverable;
  videoRef?: (node: HTMLVideoElement) => void;
}

const TimelinePreview = ({ kpi = 0, avgKpi = 0, deliverable, videoRef }: Props) => {
  const showAttentionScore = useCreativeIntelligenceStore((state) => state.showAttentionScore);
  const toggleAttentionScore = useCreativeIntelligenceStore((state) => state.toggleAttentionScore);
  const campaignInfo = useCampaignInfo();
  const attentionScoreButtonEnabled = campaignInfo.data?.metadata?.dragonfly;

  const videoSrc =
    showAttentionScore && deliverable?.signedVideoHeatMapUrl
      ? deliverable.signedVideoHeatMapUrl
      : deliverable?.signedVideoUrl;

  const imgSrc =
    showAttentionScore && deliverable?.signedImageHeatmapUrl
      ? deliverable.signedImageHeatmapUrl
      : deliverable?.signedImageUrl;

  const disableAttentionScore = videoSrc ? !deliverable?.signedVideoHeatMapUrl : !deliverable?.signedImageHeatmapUrl;

  return (
    <Flex vertical align="stretch">
      {videoSrc ? (
        <video
          ref={videoRef}
          key={videoSrc}
          className="timeline-deliverable__video-player"
          controls
          muted
          playsInline
          autoPlay
        >
          <source src={videoSrc} type="video/mp4" />
        </video>
      ) : (
        imgSrc && <img src={imgSrc} alt="deliverable preview image" className="timeline-deliverable__image" />
      )}

      <Flex className="timeline-deliverable__preview-footer" align="stretch">
        <div className="timeline-deliverable__heatmap">
          {attentionScoreButtonEnabled && (
            <Switch onChange={toggleAttentionScore} checked={showAttentionScore} disabled={disableAttentionScore} />
          )}
        </div>

        <div className="timeline-deliverable__item">
          <div className="timeline-deliverable__item__value">{kpi.toFixed(3)}</div>
          <div className="timeline-deliverable__item__label">KPI</div>
        </div>

        <div className="timeline-deliverable__item">
          <div className="timeline-deliverable__item__value">{avgKpi.toFixed(3)}</div>
          <div className="timeline-deliverable__item__label">AVG KPI</div>
        </div>
      </Flex>
    </Flex>
  );
};

export default TimelinePreview;
