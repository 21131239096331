import { getAuthHeader } from "../../utils/jwt";

import { DomainConfig } from "./types";

export async function getDeliveryConfig() {
  const authHeader = await getAuthHeader();
  const response = await fetch("/v1/delivery/okta/configuration", {
    method: "GET",
    headers: { ...authHeader },
  });

  if (response.ok) {
    const config = await response.json() as { data: DomainConfig };

    return config.data;
  } else {
    throw new Error("Failed to fetch the configuration");
  }
}
