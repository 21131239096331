import { SummaryStats } from "../../../../../types/creativeInteligence";
import { utilisationToValue } from "../../../../../utils";
import { getMetricStat } from "../../../../../utils/analytics";
import { CurrentMetrics } from "../../../../../queries/metrics.queries";
import { ChartTypes, CHARTS } from "../config";

function getDefaultPointValue({
  summaryStatsMetricValue,
  summaryStatUtilisation,
  isUtilization,
}: {
  summaryStatsMetricValue: number;
  summaryStatUtilisation: number;
  isUtilization: boolean;
}) {
  if (isUtilization) {
    return Number(utilisationToValue(summaryStatUtilisation));
  }

  return summaryStatsMetricValue;
}

function getWordCloudPointValue({
  summaryStats,
  maxValue,
  isUtilization,
  metric,
  metricStat,
}: {
  summaryStats: SummaryStats;
  maxValue: number;
  isUtilization: boolean;
  metric: "kpiMetric" | "confidenceMetric";
  metricStat: "avg" | "sum";
}) {
  if (isUtilization) {
    return Math.round(summaryStats.utilisation * 50) + 10;
  }

  return Math.round((summaryStats[metric][metricStat] / (maxValue || 1)) * 10) + 10;
}

function getScatterPointValue({
  summaryStats,
  isUtilization,
  kpiStat,
  confidenceStat,
}: {
  summaryStats: SummaryStats;
  isUtilization: boolean;
  kpiStat: "avg" | "sum";
  confidenceStat: "avg" | "sum";
}) {
  if (isUtilization) {
    return {
      x: summaryStats.confidenceMetric[confidenceStat],
      y: +utilisationToValue(summaryStats.utilisation),
    };
  }

  return {
    x: summaryStats.confidenceMetric[confidenceStat],
    y: summaryStats.kpiMetric[kpiStat],
  };
}

export function genericGetPointValue(
  type: ChartTypes,
  currentMetricsData: CurrentMetrics | undefined,
  isUtilization: boolean,
  summaryStats: SummaryStats,
  metric: "kpiMetric" | "confidenceMetric",
  datasetSummaryStats?: SummaryStats[]
) {
  const metricLabel =
    metric === "kpiMetric" ? currentMetricsData?.kpiMetricLabel : currentMetricsData?.confidenceMetricLabel;

  const metricStat = getMetricStat(metricLabel ?? "");

  if (type === CHARTS.WORDCLOUD) {
    const maxValue =
      datasetSummaryStats?.reduce((max, stats) => {
        return Math.max(stats[metric][metricStat], max);
      }, 0) ?? 0;

    return getWordCloudPointValue({ summaryStats, isUtilization, metricStat, metric, maxValue });
  }

  if (type === CHARTS.SCATTER) {
    const kpiMetricName = currentMetricsData?.kpiMetric.metric;
    const confidenceMetricName = currentMetricsData?.confidenceMetric.metric;

    const kpiStat = getMetricStat(kpiMetricName ?? "");
    const confidenceStat = getMetricStat(confidenceMetricName ?? "");

    return getScatterPointValue({
      summaryStats,
      isUtilization,
      kpiStat,
      confidenceStat,
    });
  }

  return getDefaultPointValue({
    summaryStatUtilisation: summaryStats.utilisation,
    summaryStatsMetricValue: summaryStats[metric][metricStat],
    isUtilization,
  });
}
