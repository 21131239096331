import TimelineDrawer from "../../pages/DashboardPage/components/TimelineDrawer/TimelineDrawer";

import MainPageContent, { IMainPageContentProps } from "./MainPageContent";

const PageWithTimelineDrawer = ({ children, ...props }: IMainPageContentProps) => {
  return (
    <MainPageContent {...props}>
      {children}
      <TimelineDrawer />
    </MainPageContent>
  );
};

export default PageWithTimelineDrawer;
