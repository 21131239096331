import React from "react";
import { Button } from "antd";

import MainPageContent from "../../../components/Layout/MainPageContent";

const Message = () => (
  <>
    You haven&apos;t been assigned access in Okta yet.
    <br />
    We are rolling out access to everyone this summer.
  </>
);

const ErrorPage: React.FunctionComponent = () => {
  return (
    <MainPageContent className="main-content">
      <div>
        <h3>Access restricted</h3>
        <h4>
          <Message />
        </h4>
        <Button type="primary" size="large" href="/">
          Back to main page
        </Button>
      </div>
    </MainPageContent>
  );
};

export default ErrorPage;
