import { InsightsEndpoint } from "../../../../constants/creativeInteligence";
import { useInsights } from "../../../../queries/insights/insights.queries";
import { GetMatrixOptions } from "../../../../queries/insights/types";
import { MetricStats } from "../../../../types/creativeInteligence";
import { numberToShortKM } from "../../../../utils";

interface ComparisonMatrixResponse {
  confidenceMetricStats: MetricStats;
  kpiMetricStats: MetricStats;
  name: string;
}

export interface ValuesByName {
  kpiValues: Record<string, string>;
  confidenceValues: Record<string, string>;
}

function select(data: ComparisonMatrixResponse[] | undefined) {
  return data?.reduce(
    (acc, { kpiMetricStats, confidenceMetricStats, name }) => {
      const breakdownValue = name.split("#$#")[1];

      acc.kpiValues[breakdownValue] = numberToShortKM(kpiMetricStats?.avg ?? 0);
      acc.confidenceValues[breakdownValue] = numberToShortKM(confidenceMetricStats?.avg ?? 0);

      return acc;
    },
    { kpiValues: {}, confidenceValues: {} } as ValuesByName
  );
}

const matrixComparisonKey = "matrixComparisonKey";

export function useMatrixInsights(advancedSearch: string) {
  const resp = useInsights<ComparisonMatrixResponse[], GetMatrixOptions>({
    queryKey: matrixComparisonKey,
    endpoint: InsightsEndpoint.Matrix,
    extraOptions: {
      advancedSearch,
    },
  });

  return {
    ...resp,
    data: select(resp?.data),
  };
}
