import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";

import { RawTag, Tag, TagOptions } from "../../types/creativeInteligence";
import { analyticsApiRequest } from "../../utils/analytics.api";
import { InsightType } from "../../constants/creativeInteligence";
import { CHARTS } from "../../pages/DashboardPage/components/Charts/config";
import { useAuthStore } from "../../store/auth/auth.store";

export const defaultTagOptions: TagOptions = {
  defaultPreview: CHARTS.BAR,
  endpoint: InsightType.AttributeValuesAnalysis,
};

async function fetchTags(dashboardId: string) {
  const url = `/tag/okta/${dashboardId}`;

  const { data } = await analyticsApiRequest<RawTag[]>(url);

  return data.map((tag) => {
    tag.tagOptions = tag.tagOptions ?? defaultTagOptions;

    return tag as Tag;
  });
}

const DefaultTagCategory = "Other";

export interface TagsByCategory {
  [category: string]: Tag[];
}

export const TAGS_QUERY_KEY = "tags";

export function useTags<T = Tag[]>(dashboardId: string, select?: (tags: Tag[]) => T) {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  return useQuery({
    queryKey: [TAGS_QUERY_KEY, dashboardId],
    queryFn: () => fetchTags(dashboardId),
    select,
    enabled: !!isAuthenticated && !!dashboardId,
  });
}

export function useCurrentTag() {
  const { tagId, dashboardId } = useParams();

  return useTags(
    dashboardId,
    useCallback((tags: Tag[]) => tags.find((tag) => tag.tagId === tagId), [tagId])
  );
}

export function useCurrentTagsByCategory() {
  const { dashboardId } = useParams();

  return useTags(
    dashboardId,
    useCallback((tags: Tag[]) => {
      return tags.reduce((acc, tag) => {
        const tagCategory = tag.category ?? DefaultTagCategory;

        if (!acc[tagCategory]?.length) {
          acc[tagCategory] = [];
        }

        acc[tagCategory].push(tag);

        return acc;
      }, {} as TagsByCategory);
    }, [])
  );
}
