import { useState, useMemo, useCallback, useEffect } from "react";
import { SortableContext, arrayMove, rectSortingStrategy, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { Row, Card, message } from "antd";
import { useParams } from "react-router-dom";
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";

import InsightsMarketPlace from "../../../../components/DashboardView/components/InsightsMarketplace/InsightsMarketPlace";
import { useUpdateCurrentDashboardTagOrder } from "../../../../queries/entities/dashboard.actions";
import { useCurrentDashboard, useCurrentDashboardTags } from "../../../../queries/entities/dashboard.query";
import { Tag } from "../../../../types/creativeInteligence";

import { SortableTagPreview } from "../TagPreview/TagPreview";

const TagList = () => {
  const { brandId, dashboardId } = useParams();
  const dashboard = useCurrentDashboard();
  const tags = useCurrentDashboardTags();

  const updateTagOrder = useUpdateCurrentDashboardTagOrder();

  const [sortableTags, setSortableTags] = useState<(Tag & { id: string })[]>([]);
  const tagIds = useMemo(() => sortableTags?.map((tag) => tag.tagId) ?? [], [sortableTags]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  const handleDragEnd = useCallback(
    async (event: DragEndEvent) => {
      const { active, over } = event;

      if (!over || !active || active.id === over.id || !dashboard.data?.id) {
        return;
      }

      const oldIndex = tagIds.findIndex((id) => id === active.id);
      const newIndex = tagIds.findIndex((id) => id === over.id);

      if (tagIds && oldIndex !== undefined && newIndex !== undefined) {
        const newTagIdsOrder = arrayMove(tagIds, oldIndex, newIndex);
        const newTagOrder = arrayMove(sortableTags, oldIndex, newIndex);

        setSortableTags(newTagOrder);

        try {
          await updateTagOrder.update(newTagIdsOrder);
        } catch {
          const oldTagOrder = arrayMove(sortableTags, newIndex, oldIndex);

          setSortableTags(oldTagOrder);

          message.error("Failed to update tag order");
        }
      }
    },
    [dashboard.data, sortableTags, tagIds, updateTagOrder]
  );

  useEffect(() => {
    if (tags.data) {
      setSortableTags(tags.data?.map((tag) => ({ ...tag, id: tag.tagId })));
    }
  }, [tags.data]);

  return (
    <>
      <Row gutter={[16, 16]}>
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext id="tagId" items={sortableTags} strategy={rectSortingStrategy}>
            {sortableTags.map((tag) => (
              <SortableTagPreview key={tag.tagId} brandId={brandId} dashboardId={dashboardId} tag={tag} />
            ))}
          </SortableContext>
        </DndContext>
      </Row>

      <Card>
        <InsightsMarketPlace brandId={brandId} dashboardId={dashboardId} />
      </Card>
    </>
  );
};

export default TagList;
