import { useCallback, useState, useMemo } from "react";
import { Button, Flex, Table, Typography, Space, Select } from "antd";
import { ColumnType } from "antd/es/table";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import MainPageContent from "../../components/Layout/MainPageContent";
import MainPageContentHeader from "../../components/Layout/MainPageContentHeader";

import { useRouterPaths } from "../../hooks/useRouterPaths";
import { InventoryWityId } from "../../types/entities";
import { getResourceTextStatus } from "../../utils/inventoryJob";
import { useAllBrandInventories } from "../../queries/entities/inventory.queries";
import { useBrandDashboards } from "../../queries/entities/brandDashboards.query";

import DeliveryJobStatusCell from "./components/DeliveryJobStatusCell";
import AddInventoryJobDropdown from "./components/AddInventoryJobDropdown";

import "./JobsList.scss";

const { Text } = Typography;

const PAGE_SIZE = 15;

const Pagination = ({
  page,
  totalPages,
  setPage,
}: {
  page: number;
  totalPages: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const handlePrev = useCallback(() => setPage((prev) => prev - 1), [setPage]);
  const handleNext = useCallback(() => setPage((prev) => prev + 1), [setPage]);

  if (!totalPages || totalPages === 1) {
    return null;
  }

  return (
    <Space>
      <Button type="text" disabled={page === 1} onClick={handlePrev}>
        <LeftOutlined />
      </Button>
      <Text>
        Page {page} / {totalPages}
      </Text>
      <Button type="text" disabled={page === totalPages} onClick={handleNext}>
        <RightOutlined />
      </Button>
    </Space>
  );
};

function getColumns(page: number) {
  return [
    {
      title: "No.",
      render: (_, __, index) => ((page - 1) * PAGE_SIZE) + index + 1,
    },
    {
      title: "Job Name",
      dataIndex: "jobName",
      key: "jobName",
      align: "center",
      render: (_, job) => {
        return job.extractorInput.jobName || job.extractorInput.resourceGroupId;
      },
    },
    {
      title: "Extractor Type",
      dataIndex: "resourceType",
      key: "resourceType",
      align: "center",
    },
    {
      title: "Resource Group Id",
      dataIndex: "resourceGroupId",
      key: "resourceGroupId",
      align: "center",
      render: (_, job) => {
        return job.resourceGroupId;
      },
    },
    {
      title: "Inventory Status",
      dataIndex: "inventoryStatus",
      key: "inventoryStatus",
      align: "center",
      render: (_, job) => {
        const status = getResourceTextStatus(job);

        return <Text className={`job-table-success-${status}`}>{status}</Text>;
      },
    },
    {
      title: "Percent",
      dataIndex: "percentage",
      key: "percentage",
      align: "center",
      render: (_, job) => {
        return job?.resourceOutput ? `${job.resourceOutput.percentage}%` : "";
      },
    },
    {
      title: "Delivery Status",
      dataIndex: "deliveryStatus",
      key: "deliveryStatus",
      align: "center",
      render: (_, job) => {
        if (getResourceTextStatus(job) === "failed") {
          return "n/a";
        }

        return <DeliveryJobStatusCell inventoryId={job.id} />;
      },
    },
  ] as ColumnType<InventoryWityId>[];
}

const JobsList = () => {
  const navigate = useNavigate();
  const { brandId } = useParams();
  const { jobIndexPath } = useRouterPaths();
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(1);

  const dashboards = useBrandDashboards(brandId);
  const inventoryList = useAllBrandInventories(brandId);

  const dashboardId = searchParams.get("dashboardId") ?? undefined;
  const showUnassigned = searchParams.get("unassigned") === "true";

  const dashboard = useMemo(
    () => dashboards.data?.items.find(({ id }) => id === dashboardId),
    [dashboardId, dashboards.data]
  );

  const filteredInventoryList = useMemo(() => {
    if (showUnassigned) {
      return inventoryList.data?.items.filter((item) => {
        return !dashboards.data?.items.find(({ inventoryIds }) => inventoryIds?.includes(item.id));
      }) ?? [];
    }

    if (dashboardId) {
      return inventoryList.data?.items.filter((item) => dashboard?.inventoryIds?.includes(item.id)) ?? [];
    }

    return inventoryList.data?.items;
  }, [dashboard?.inventoryIds, dashboardId, dashboards.data?.items, inventoryList.data?.items, showUnassigned]);

  const dashboardOptions = useMemo(
    () => ([
      {
        label: "*Unassigned*",
        value: "unassigned",
      },
      ...(dashboards.data?.items.map((d) => ({
        label: d.name,
        value: d.id,
      })) ?? []),
    ]),
    [dashboards.data]
  );

  const columns = useMemo(() => {
    return getColumns(page);
  }, [page]);

  const setDashboardIdFilter = useCallback(
    (id: string | undefined) => {
      if (!id) {
        setSearchParams({});

        return;
      }

      if (id === "unassigned") {
        setSearchParams({ unassigned: "true" });

        return;
      }

      setSearchParams({ dashboardId: id });
    },
    [setSearchParams]
  );

  const onRow = useCallback(
    (job: InventoryWityId) => ({ onClick: () => navigate(`${jobIndexPath}/${job.id}`) }),
    [jobIndexPath, navigate]
  );

  if (inventoryList.isError) {
    return <div className="jobs-table__error">{inventoryList.error.message}</div>;
  }

  return (
    <>
      <Flex justify="space-between" align="center" gap="middle">
        <Space>
          Filter by dashboard:
          <Select
            defaultValue={dashboardId ?? (showUnassigned ? "unassigned" : null)}
            placeholder="Select a dashboard"
            onChange={setDashboardIdFilter}
            options={dashboardOptions}
            allowClear
            popupMatchSelectWidth={false}
          />
        </Space>

        <Pagination
          page={page}
          totalPages={Math.ceil((filteredInventoryList?.length ?? 0) / PAGE_SIZE)}
          setPage={setPage}
        />
      </Flex>
      <Table
        className="jobs-table"
        rowClassName="jobs-table__row"
        loading={inventoryList.isFetching || dashboards.isLoading}
        dataSource={filteredInventoryList}
        columns={columns}
        onRow={onRow}
        rowKey="resourceId"
        size="middle"
        bordered
        pagination={{ position: ["none"], current: page, pageSize: PAGE_SIZE }}
      />
    </>
  );
};

const JobsListPage = () => (
  <MainPageContent
    header={
      <MainPageContentHeader>
        <Text strong>Inventory jobs</Text>
        <Space>
          <AddInventoryJobDropdown />
        </Space>
      </MainPageContentHeader>
    }
  >
    <JobsList />
  </MainPageContent>
);

export default JobsListPage;
