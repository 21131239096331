import { CollapseProps, Collapse } from "antd";
import { useMemo } from "react";

import UsageContent from "./Usage/UsageContent";
import UsageTitle from "./Usage/UsageTitle";
import AppearanceContent from "./Appearance/AppearanceContent";
import AppearanceTitle from "./Appearance/AppearanceTitle";
import DurationContent from "./Duration/DurationContent";
import DurationTitle from "./Duration/DurationTitle";
import KeyTakeawayContent from "./KeyTakeaway/KeyTakeawayContent";
import KeyTakeawaySubtitle from "./KeyTakeaway/KeyTakeawaySubtitle";
import KeyTakeawayTitle from "./KeyTakeaway/KeyTakeawayTitle";
import PositionContent from "./Position/PositionContent";
import PositionTitle from "./Position/PositionTitle";
import SizeContent from "./Size/SizeContent";
import SizeTitle from "./Size/SizeTitle";

const InsightsReport = ({ name, advancedSearch }: { name: string; advancedSearch: string }) => {
  const items = useMemo<CollapseProps["items"]>(() => {
    return [
      {
        key: "1",
        label: <KeyTakeawayTitle name={name} advancedSearch={advancedSearch} />,
        children: (
          <Collapse
            ghost
            defaultActiveKey={["1"]}
            items={[
              {
                key: "1",
                label: <KeyTakeawaySubtitle name={name} advancedSearch={advancedSearch} notMatchQuery={false} />,
                children: <KeyTakeawayContent advancedSearch={advancedSearch} notMatchQuery={false} />,
              },
              {
                key: "2",
                label: <KeyTakeawaySubtitle name={name} advancedSearch={advancedSearch} notMatchQuery={true} />,
                children: <KeyTakeawayContent advancedSearch={advancedSearch} notMatchQuery={true} />,
              },
            ]}
          />
        ),
      },
      {
        key: "2",
        label: <UsageTitle advancedSearch={advancedSearch} />,
        children: <UsageContent advancedSearch={advancedSearch} />,
      },
      {
        key: "3",
        label: <PositionTitle name={name} advancedSearch={advancedSearch} />,
        children: <PositionContent advancedSearch={advancedSearch} />,
      },
      {
        key: "4",
        label: <SizeTitle name={name} advancedSearch={advancedSearch} />,
        children: <SizeContent advancedSearch={advancedSearch} />,
      },
      {
        key: "5",
        label: <DurationTitle name={name} advancedSearch={advancedSearch} />,
        children: <DurationContent advancedSearch={advancedSearch} />,
      },
      {
        key: "6",
        label: <AppearanceTitle name={name} advancedSearch={advancedSearch} />,
        children: <AppearanceContent advancedSearch={advancedSearch} />,
      },
    ];
  }, [advancedSearch, name]);

  return <Collapse items={items} />;
};

export default InsightsReport;
