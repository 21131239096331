import { InfiniteData } from "@tanstack/react-query";

import { InsightsEndpoint } from "../../../../constants/creativeInteligence";
import { useInfiniteInsights } from "../../../../queries/insights/insights.queries";
import { GetKeyTakeawayOptions } from "../../../../queries/insights/types";
import { SummaryStats, UniqueCreativesGroups } from "../../../../types/creativeInteligence";
import { ApiResponse } from "../../../../utils/api";

interface KeyTakeAwaySummaryStats extends SummaryStats {
  adsAboveKPI: number;
}

interface KeyTakeawayResponse {
  creativeHash: string;
  summaryStats: KeyTakeAwaySummaryStats;
  uniqueCreativesGroups: UniqueCreativesGroups[];
}

function select(response: InfiniteData<ApiResponse<KeyTakeawayResponse>>) {
  const pages = response?.pages;
  const items = pages?.length ? pages : [];

  return {
    items: items.map((item) => item.data.uniqueCreativesGroups).flat(),
    summaryStats: pages[0].data.summaryStats,
  };
}

const keyTakeawayKey = "keyTakeawayKey";

export function useKeyTakeawayInsight(advancedSearch: string, notMatchQuery = false) {
  const resp = useInfiniteInsights<KeyTakeawayResponse, GetKeyTakeawayOptions>({
    queryKey: keyTakeawayKey,
    endpoint: InsightsEndpoint.KeyTakeaway,
    extraOptions: {
      advancedSearch,
      topUniqueCreativesNumber: 4,
      notMatchQuery,
    },
  });

  return {
    ...resp,
    data: resp.data ? select(resp.data) : undefined,
  };
}
