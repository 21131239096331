import { useMutation, useQueryClient } from "@tanstack/react-query";

import { analyticsApiRequest } from "../../utils/analytics.api";
import { Tag } from "../../types/creativeInteligence";

import { TAGS_QUERY_KEY } from "./tags.query";

interface UpdateTagResponse {
  message: string;
  data: {
    tagId: string;
    tagGroup: string;
    data: UpdateTagPayload;
  };
}

export type UpdateTagPayload = Omit<Tag, "tagGroup" | "tagId">;

async function postTag({ tag, tagId, dashboardId }: { tag: UpdateTagPayload; tagId?: string; dashboardId: string }) {
  const url = `/tag/okta/${dashboardId}${tagId ? "/" + tagId : ""}`;
  const { data } = await analyticsApiRequest<UpdateTagResponse, UpdateTagPayload>(url, "POST", tag);

  return data;
}

/* DO NOT USE THIS DIRECTLY, IT WILL MESS UP THE DASHBOARD */
export function useAddTag() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      tag,
      sourceTagId,
      dashboardId,
    }: {
      tag: UpdateTagPayload;
      sourceTagId?: string;
      dashboardId: string;
    }) => {
      const newTag: UpdateTagPayload = {
        advancedSearch: tag.advancedSearch,
        name: tag.name,
        tagOptions: tag.tagOptions,
      };

      if (sourceTagId) {
        newTag.sourceTag = sourceTagId;
      }

      if (tag.category) {
        newTag.category = tag.category;
      }

      return postTag({ dashboardId, tag: newTag });
    },

    onSuccess: ({ data }, { dashboardId }) => {
      const newTag = {
        ...data.data,
        tagId: data.tagId,
        tagGroup: data.tagGroup,
      };

      queryClient.setQueryData([TAGS_QUERY_KEY, dashboardId], (previous: Tag[] = []) => {
        return [...previous, newTag];
      });
    },
  });
}

export function useUpdateTag() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postTag,

    onSuccess: ({ data: { data, tagId, tagGroup } }, { dashboardId }) => {
      const updatedTag = {
        ...data,
        tagId,
        tagGroup,
      };

      queryClient.setQueryData([TAGS_QUERY_KEY, dashboardId], (previous: Tag[] = []) => {
        return previous.map((item) => updatedTag.tagId === item.tagId ? { ...item, ...updatedTag } : item);
      });
    },
  });
}

interface TagResponse {
  message: string;
  data: {
    tagId: string;
    tagGroup: string;
  };
}

/* DO NOT USE THIS DIRECTLY, IT WILL MESS UP THE DASHBOARD */
async function deleteTag({ tagId, dashboardId }: { tagId: string; dashboardId: string }) {
  const url = `/tag/okta/${dashboardId}/${tagId}`;
  const { data } = await analyticsApiRequest<TagResponse>(url, "DELETE");

  return data;
}

export function useDeleteTag() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteTag,

    onSuccess: (_, { tagId, dashboardId }) => {
      queryClient.setQueryData([TAGS_QUERY_KEY, dashboardId], (previous: Tag[] = []) => {
        return previous.filter((item) => tagId !== item.tagId);
      });
    },
  });
}
