import { useState, useEffect } from "react";
import { Button, Popover } from "antd";

import { useCampaignInfo } from "../../../../queries/insights/campaignInfo.queries";
import FiltersTree from "../../../../components/DashboardView/components/FiltersTree/FiltersTree";
import { DynamicBreakdowns } from "../../../../queries/insights/types";
import { useCreativeIntelligenceStore } from "../../../../store/creativeIntelligence.store";

import { useMatrixInsights } from "./matrix.query";
import ComparisonTable from "./ComparisonTable";

import "./ComparisonMatrix.scss";

const ComparisonMatrix = ({ advancedSearch }: { advancedSearch: string }) => {
  const campaignInfo = useCampaignInfo();
  const matrixInsights = useMatrixInsights(advancedSearch);
  const dashboardSelectedFilters = useCreativeIntelligenceStore((state) => state.selectedFilters);
  const [selectedFilters, setSelectedFilters] = useState<DynamicBreakdowns | undefined>(dashboardSelectedFilters);

  useEffect(() => {
    setSelectedFilters(dashboardSelectedFilters);
  }, [dashboardSelectedFilters]);

  return (
    <div>
      <Popover
        placement="bottom"
        content={
          <FiltersTree
            campaignFilters={campaignInfo.data?.campaignFilters}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
        }
        trigger="click"
      >
        <Button>Select kpi comparison</Button>
      </Popover>

      {selectedFilters && Object.entries(selectedFilters).length > 0 && (
        <ComparisonTable
          insights={matrixInsights.data}
          loading={matrixInsights.isPending}
          selectedFilters={selectedFilters}
        />
      )}
    </div>
  );
};

export default ComparisonMatrix;
