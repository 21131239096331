import { useMemo } from "react";
import { Chart as ChartJS, ChartType, TooltipModel, ChartOptions, ScriptableContext } from "chart.js";
import { TreemapDataPoint } from "chartjs-chart-treemap";

import { CHARTS, BaseDataPoint, ChartTypes } from "../config";
import { getOnChartElementClick } from "../utils/getOnChartElementClick";

export function useChartOptions({
  type,
  tooltipCallback,
  onElementClick,
  multiDataset = false,
}: {
  type: ChartTypes;
  tooltipCallback?: (args: { chart: ChartJS; tooltip: TooltipModel<ChartType> }) => void;
  onElementClick?: (reportIds: string[]) => void;
  multiDataset: boolean;
}) {
  return useMemo(() => {
    const options = {
      maintainAspectRatio: false,
      responsive: true,
      borderWidth: 0,

      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        tooltip: {
          enabled: false,
          external: tooltipCallback,
        },
      },
    } as ChartOptions;

    if (onElementClick) {
      options.onClick = getOnChartElementClick(onElementClick);
    }

    switch (type) {
      case CHARTS.TREEMAP:
        options.animation = false;
        options.elements = {
          treemap: {
            labels: {
              display: true,
              formatter: (ctx: ScriptableContext<"treemap"> & { raw: TreemapDataPoint & { _data: BaseDataPoint } }) => {
                return ctx.raw._data.label;
              },
            },
          },
        };

        break;

      case CHARTS.WORDCLOUD:
        options.parsing = {
          xAxisKey: "label",
          yAxisKey: "value",
        };

        options.elements = {
          word: {
            maxRotation: 0,
            minRotation: 0,
          },
        };

        break;

      case CHARTS.SCATTER:
        options.parsing = {
          xAxisKey: "value.x",
          yAxisKey: "value.y",
        };

        options.elements = {
          point: {
            radius: 6,
            hoverRadius: 7,
          },
        };

        options.plugins = {
          ...options.plugins,
          legend: {
            display: multiDataset,
            align: "center",
            position: "bottom",
            labels: {
              font: {
                size: 14,
              },
            },
          },
          // title: {
          //   display: true,
          //   align: "start",
          //   text: chartTitle,
          // },
        };

        break;

      case CHARTS.HORIZONTAL_BAR:
        options.indexAxis = "y";

        options.parsing = {
          xAxisKey: "value",
          yAxisKey: "label",
        };

        break;

      case CHARTS.BAR:
        options.parsing = {
          xAxisKey: "label",
          yAxisKey: "value",
        };

        options.plugins = {
          ...options.plugins,
          legend: {
            display: multiDataset,
            align: "end",
            position: "top",
            labels: {
              usePointStyle: true,
              pointStyle: "circle",
              font: {
                size: 14,
              },
            },
          },
        };

        break;

      case CHARTS.POLAR:
        options.parsing = {
          key: "value",
        };

        options.plugins = {
          ...options.plugins,
          legend: {
            display: true,
            align: "center",
            position: "right",
            labels: {
              usePointStyle: true,
              boxWidth: 12,
            },
          },
        };

        break;

      case CHARTS.DOUGHNUT:
        options.plugins = {
          ...options.plugins,
          legend: {
            display: true,
            align: "center",
            position: "right",
            labels: {
              usePointStyle: true,
              boxWidth: 12,
            },
          },
        };

        break;

      default:
        break;
    }

    return options;
  }, [type, tooltipCallback, onElementClick, multiDataset]);
}
