import {
  Chart as ChartJS,
  ChartTypeRegistry,
  BarElement,
  BarController,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  LogarithmicScale,
  Title,
  Tooltip,
  PolarAreaController,
  ArcElement,
  LineElement,
  BubbleController,
  LineController,
  PieController,
  PointElement,
  ScatterController,
  Legend,
  SubTitle,
  ChartType,
  ScriptableContext,
} from "chart.js";

import { WordCloudController, WordElement } from "chartjs-chart-wordcloud";
import { TreemapController, TreemapElement } from "chartjs-chart-treemap";

import { InsightsData, SummaryStats, UniqueCreativesGroups } from "../../../../types/creativeInteligence";

ChartJS.register(
  ArcElement,
  BarController,
  BarElement,
  BubbleController,
  CategoryScale,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  LogarithmicScale,
  PieController,
  PointElement,
  PolarAreaController,
  RadialLinearScale,
  ScatterController,
  SubTitle,
  Title,
  Tooltip,
  TreemapController,
  TreemapElement,
  WordCloudController,
  WordElement
);

ChartJS.defaults.font.family = "WPP Regular";

// todo switch to wpp data visualisation
export const COLORS_HEX = {
  deviationUp: "#41b883",
  deviationDown: "#f9444e",
  orange: "#ee825e",
  blue: "#477ef6",
  black: "#000000",
  cyan: "#5ad8a6",
  forAdam: "#faa804",
  gray: "#5d7092",
  green: "#1e9493",
  lightgray: "#cccccc",
  pink: "#dc5a83",
  purple: "#945fb9",
  red: "#e86452",
  transparent: "#FFFFFF00",
  yellow: "#f6bd16",
  textDensityBlue: "#1890ff",
} as const;

export type StandardChartTypes =
  | typeof CHARTS.BAR
  | typeof CHARTS.DOUGHNUT
  | typeof CHARTS.HORIZONTAL_BAR
  | typeof CHARTS.POLAR;
export type ChartTypes = StandardChartTypes | typeof CHARTS.SCATTER | typeof CHARTS.TREEMAP | typeof CHARTS.WORDCLOUD;

export const CHARTS = {
  BAR: "barChart",
  DOUGHNUT: "doughnutChart",
  HORIZONTAL_BAR: "horizontalBarChart",
  POLAR: "polarChart",
  SCATTER: "scatterChart",
  TREEMAP: "treemapChart",
  WORDCLOUD: "wordCloudChart",
} as const;

export const typeToChartType: Record<ChartTypes, keyof ChartTypeRegistry> = {
  [CHARTS.BAR]: "bar",
  [CHARTS.DOUGHNUT]: "doughnut",
  [CHARTS.HORIZONTAL_BAR]: "bar",
  [CHARTS.POLAR]: "polarArea",
  [CHARTS.SCATTER]: "scatter",
  [CHARTS.TREEMAP]: "treemap",
  [CHARTS.WORDCLOUD]: "wordCloud",
};

export const ChartTypeLabel: Record<ChartTypes, string> = {
  [CHARTS.BAR]: "Bar Chart",
  [CHARTS.DOUGHNUT]: "Doughnut Chart",
  [CHARTS.HORIZONTAL_BAR]: "Horizontal Bar Chart",
  [CHARTS.POLAR]: "Polar Chart",
  [CHARTS.SCATTER]: "Scatter Chart",
  [CHARTS.TREEMAP]: "Treemap Chart",
  [CHARTS.WORDCLOUD]: "Word Cloud Chart",
};

export enum ChartDisplayValue {
  KpiStat = "kpiStat",
  Utilisation = "utilisation",
}

export const CHART_COLORS = [
  "#58AFFF",
  "#0074D9",
  "#7FDBFF",
  "#39CCCC",
  "#3D9970",
  "#2ECC40",
  "#01FF70",
  "#FFDC00",
  "#FF851B",
  "#FF4136",
  "#F012BE",
  "#B10DC9",
  "#85144B",
  "#BBBBBB",
  "#AAAAAA",
  "#333333",
] as const;

export interface BaseDataPoint<TData = number> {
  label: string;
  value: TData;
}

export interface BaseChartDataset<TDataPoints = BaseDataPoint[]> {
  data: TDataPoints;
  backgroundColors: string[];
  hoverBackgroundColors: string[];
  creatives: UniqueCreativesGroups[][];
  summaryStats: SummaryStats[];
  key: "value";
  backgroundColor?: string | ((ctx: ScriptableContext<ChartType>) => string);
  label: string | undefined;
  metric: "kpiMetric" | "confidenceMetric" | undefined;
  yAxisID: string | undefined;
}

export interface TreemapDataset extends BaseChartDataset<[]> {
  tree: BaseDataPoint[];
}

export interface BaseChartDatasetConfig {
  data: InsightsData;
  label?: string;
  backgroundColor?: string;
  metric: "kpiMetric" | "confidenceMetric";
  yAxisID?: string;
}
