import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { DConfOutput } from "dconf-service/src/types";

import { domainService } from "./domainService";

interface DconfState {
  dconf: DConfOutput | null;
  // types will come from @wppOpen/core once we have a the real data to pull
  // demo app: https://github.com/WPPOpen/os-react-app-boilerplate/tree/penpal-iframe-app-example
  wppContext: unknown;
  wppApi: unknown;
  initialized: boolean;
  init: () => Promise<DConfOutput | null>;
  setWppContext: (wppContext: unknown) => void;
  setWppApi: (wppApi: unknown) => void;
  clearWpp: () => void;
}
export const useDconfStore = create<DconfState>()(
  devtools(
    immer((set, get) => {
      return {
        dconf: null,
        initialized: false,
        wppApi: null,
        wppContext: null,
        init: async () => {
          const { initialized, dconf } = get();

          if (initialized && dconf) {
            return dconf;
          }

          const domainConfig = await domainService.getConfig();

          if (domainConfig) {
            set((state) => {
              state.dconf = domainConfig;
              state.initialized = true;
            });

            return domainConfig;
          }

          return null;
        },
        setWppContext: (wppContext: unknown) => {
          set(
            (state) => {
              state.wppContext = wppContext;
            },
            false,
            "setWppContext"
          );
        },
        setWppApi: (wppApi: unknown) => {
          set(
            (state) => {
              state.wppApi = wppApi;
            },
            false,
            "setWppApi"
          );
        },
        clearWpp: () => {
          set(
            (state) => {
              state.wppApi = null;
              state.wppContext = null;
            },
            false,
            "clearWpp"
          );
        },
      };
    })
  )
);

const { init, setWppContext, setWppApi, clearWpp } = useDconfStore.getState();

export const dconfStoreActions = {
  init,
  setWppApi,
  setWppContext,
  clearWpp,
};
