import { useCallback, useEffect } from "react";
import { Button, Form, Input, Modal, Typography, message } from "antd";

import { useUniqueNameValidator } from "../../../utilityHooks/useUniqueNameValidator";
import { useCreateBrand } from "../../../queries/entities/brand.mutations";
import { useBrands } from "../../../queries/entities/brand.query";

const { Title } = Typography;

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

interface FormData {
  name: string;
}

export const CreateBrandModal = ({ isOpen, onClose }: Props) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm<FormData>();

  const { data: brands } = useBrands();
  const createBrand = useCreateBrand();

  const validator = useUniqueNameValidator(brands ?? [], "Brand");

  const handleOnClose = useCallback(() => {
    form.resetFields();
    onClose();
  }, [form, onClose]);

  useEffect(() => {
    if (!createBrand.isSuccess) {
      return;
    }

    handleOnClose();

    messageApi.success({ content: `"${createBrand.data.name}" brand created successfully`, duration: 3 });
  }, [createBrand.data, createBrand.isSuccess, handleOnClose, messageApi]);

  useEffect(() => {
    if (!createBrand.error) {
      return;
    }

    messageApi.error({ content: createBrand.error?.message ?? "Something went wrong", duration: 3 });
  }, [createBrand.error, messageApi]);

  const handleFinish = useCallback(
    (values: FormData) => {
      if (createBrand.isPending) {
        return;
      }

      createBrand.mutate(values);
    },
    [createBrand]
  );

  const modalFooter = [
    <Button key="cancel" onClick={handleOnClose} data-testid="create-brand-modal-cancel-button">
      Cancel
    </Button>,

    <Button
      key="confirm"
      type="primary"
      onClick={form.submit}
      loading={createBrand.isPending}
      data-testid="create-brand-modal-confirm-button"
    >
      Create
    </Button>,
  ];

  return (
    <>
      {contextHolder}

      <Modal
        title={<Title level={4}>Brand Details</Title>}
        open={isOpen}
        footer={modalFooter}
        onCancel={handleOnClose}
        cancelButtonProps={{
          ["data-testid"]: "create-brand-modal-close-button",
        }}
      >
        <Form form={form} layout="vertical" onFinish={handleFinish}>
          <Form.Item label="Brand name" name="name" rules={[{ required: true }, { validator }]}>
            <Input data-testid="create-brand-modal-name-input" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
