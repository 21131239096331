import { useMemo } from "react";
import { Chart } from "react-chartjs-2";

import { type Attribute } from "../../../../../../../../types/attribute";

import {
  type TextDataset,
  SCREENSHOT_INTERVAL,
  getChartDataMap,
  getTextStatsDatasets,
  useTextStatsChartOptions,
} from "./utils";

import "./TextStatsChart.scss";

interface Props {
  color: string;
  title: string;
  duration: number;
  attributes: Attribute[];
  showPercentLabel?: boolean;
}

const TextStatsChart = ({ attributes, duration = 10, color, title, showPercentLabel }: Props) => {
  const ticksCount = useMemo(() => {
    if (!duration) {
      return 0;
    }

    return Math.ceil(duration * (1 / SCREENSHOT_INTERVAL)) + 1;
  }, [duration]);

  const attributesData = useMemo(() => getChartDataMap(attributes, ticksCount), [attributes, ticksCount]);

  const attributesMaxValue = useMemo(() => {
    return Math.max(...attributesData.map(({ y }) => y));
  }, [attributesData]);

  const datasets: TextDataset[] = useMemo(() => {
    if (!duration) {
      return [];
    }

    return getTextStatsDatasets(attributesData, color, attributesMaxValue);
  }, [attributesData, attributesMaxValue, color, duration]);

  const options = useTextStatsChartOptions({
    xMax: duration,
    yMax: attributesMaxValue,
  });

  return (
    <div>
      <h4 className="text-stats-chart__title">
        {title} (Max: {attributesMaxValue.toFixed(2)}{showPercentLabel ? "%" : ""})
      </h4>

      <div className="text-stats-chart__container">
        <Chart type="bar" data={{ datasets }} options={options} />
      </div>
    </div>
  );
};

export default TextStatsChart;
