import React, { ReactNode, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { authActions, useAuthStore } from "../store/auth/auth.store";

export interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const location = useLocation();

  useEffect(() => {
    void authActions.checkSession();
  }, []);

  if (isAuthenticated === null) {
    return <div>Not authenticated</div>;
  } else if (!isAuthenticated) {
    // Okta will include an ?iss querystring when a user clicks on a tile, we
    // maintain this querystring when navigating the user to /login page.
    const query = new URLSearchParams(window.location.search);
    const iss = query.get("iss");
    const to = iss ? `/login?iss=${encodeURIComponent(iss)}` : "/login";

    return <Navigate to={to} replace state={{ from: location }} />;
  }

  return children;
};

export default ProtectedRoute;
