import { useState, useCallback, ChangeEvent, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Select, Input, Button, message } from "antd";

import { useRouterPaths } from "../../../../hooks/useRouterPaths";
import { useCurrentDashboard } from "../../../../queries/entities/dashboard.query";
import { Tag } from "../../../../types/creativeInteligence";

import SaveTagModal from "./components/SaveTagModal";
import EditTagModal from "./components/EditTagModal";

import "./AdvancedSearch.scss";

export const SearchTypes = {
  Everything: "all=",
  Text: "text=",
  Objects: "object=",
  Colors: "color=",
  Advanced: "",
} as const;

export type SearchTypeKey = keyof typeof SearchTypes;

export const searchTypeKeys = Object.keys(SearchTypes);

const SearchHeader = ({
  search,
  searchTypeKey,
  onSearchChange,
  onSearchTypeChange,
  buttonText,
  onButtonClick,
}: {
  search?: string;
  searchTypeKey?: SearchTypeKey;
  onSearchChange: (search: string) => void;
  onSearchTypeChange: (searchTypeKey: SearchTypeKey) => void;
  buttonText: string;
  onButtonClick: () => void;
}) => {
  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => onSearchChange(event.target.value),
    [onSearchChange]
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Enter") {
        onButtonClick();
      }
    },
    [onButtonClick]
  );

  return (
    <>
      <Select value={searchTypeKey} data-testid="advanced-search-select" onChange={onSearchTypeChange}>
        {searchTypeKeys.map((key) => (
          <Select.Option key={key} value={key} data-testid={`advanced-search-option-${key}`}>
            {key}
          </Select.Option>
        ))}
      </Select>

      <Input
        placeholder="Search"
        data-testid="advanced-search-input"
        value={search}
        onChange={handleSearchChange}
        onKeyDown={handleKeyDown}
      />

      <Button type="primary" onClick={onButtonClick} data-testid="advanced-search-button">
        {buttonText}
      </Button>
    </>
  );
};

export interface SearchProps {
  initialSearch?: string;
  initialSearchTypeKey?: SearchTypeKey;
  tag?: Tag;
}

const AdvancedSearch = ({ initialSearch = "", initialSearchTypeKey = "Everything", tag }: SearchProps) => {
  const navigate = useNavigate();
  const { brandId } = useParams();
  const { dashboardPath } = useRouterPaths();
  const dashboard = useCurrentDashboard();
  const dashboardId = dashboard.data?.id ?? "";
  const [messageApi, contextHolder] = message.useMessage();

  const [search, setSearch] = useState(initialSearch);
  const [searchTypeKey, setSearchTypeKey] = useState<SearchTypeKey>(initialSearchTypeKey);

  const [modalOpen, setModalOpen] = useState(false);

  const showModal = useCallback(() => setModalOpen(true), []);
  const hideModal = useCallback(() => setModalOpen(false), []);

  const updateSearch = useCallback(() => {
    navigate(`${dashboardPath}/search?term=${search}&query=${SearchTypes[searchTypeKey]}${search}`);
  }, [navigate, dashboardPath, search, searchTypeKey]);

  const isSearch = search !== initialSearch || searchTypeKey !== initialSearchTypeKey;

  const nonSearchText = tag ? "Edit" : "Save";
  const buttonText = isSearch ? "Search" : nonSearchText;

  useEffect(() => {
    setSearch(initialSearch);
    setSearchTypeKey(initialSearchTypeKey);
  }, [initialSearch, initialSearchTypeKey]);

  return (
    <>
      {contextHolder}
      <SearchHeader
        search={search}
        searchTypeKey={searchTypeKey}
        onSearchChange={setSearch}
        onSearchTypeChange={setSearchTypeKey}
        buttonText={buttonText}
        onButtonClick={isSearch ? updateSearch : showModal}
      />
      {tag ? (
        <EditTagModal
          tag={tag}
          brandId={brandId}
          dashboardId={dashboardId}
          isModalOpen={modalOpen}
          onClose={hideModal}
          messageApi={messageApi}
        />
      ) : (
        <SaveTagModal
          search={search}
          queryPrefix={SearchTypes[searchTypeKey]}
          dashboardId={dashboardId}
          brandId={brandId}
          isModalOpen={modalOpen}
          onClose={hideModal}
        />
      )}
    </>
  );
};

export default AdvancedSearch;
