import { useCallback, useEffect, useMemo } from "react";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "antd";

import { useCampaignInfo } from "../../../../../../queries/insights/campaignInfo.queries";
import { DATE_FORMAT } from "../../../../../../constants/creativeInteligence";
import { useCreativeIntelligenceStore } from "../../../../../../store/creativeIntelligence.store";

const { RangePicker } = DatePicker;

const ReportDatePicker = () => {
  const campaignInfo = useCampaignInfo();

  const selectedDateRange = useCreativeIntelligenceStore((state) => state.selectedDateRange);
  const setSelectedDateRange = useCreativeIntelligenceStore((state) => state.setSelectedDateRange);

  const { minDate, maxDate } = useMemo(() => {
    if (!campaignInfo.data) {
      return {
        minDate: null,
        maxDate: null,
      };
    }

    return {
      minDate: dayjs(campaignInfo.data.dateRange.minDate),
      maxDate: dayjs(campaignInfo.data.dateRange.maxDate),
    };
  }, [campaignInfo.data]);

  const value = useMemo(() => {
    if (!selectedDateRange) {
      return null;
    }

    return [dayjs(selectedDateRange.startDate, DATE_FORMAT), dayjs(selectedDateRange.endDate, DATE_FORMAT)] as [
      Dayjs,
      Dayjs
    ];
  }, [selectedDateRange]);

  const checkDisabledDate = useCallback(
    (current: Dayjs) => {
      if (!minDate || !maxDate) {
        return false;
      }

      return current.valueOf() > maxDate.valueOf() || current.valueOf() < minDate.valueOf();
    },
    [maxDate, minDate]
  );

  const onChange = useCallback(
    (newDates: [Dayjs | null, Dayjs | null] | null) => {
      const [startDate, endDate] = newDates ?? [];

      if (!minDate || !maxDate || !startDate || !endDate || startDate.isBefore(minDate) || endDate.isAfter(maxDate)) {
        return;
      }

      setSelectedDateRange({
        startDate: startDate.format(DATE_FORMAT),
        endDate: endDate.format(DATE_FORMAT),
      });
    },
    [maxDate, minDate, setSelectedDateRange]
  );

  useEffect(() => {
    if (!campaignInfo.data) {
      return;
    }

    setSelectedDateRange({
      startDate: campaignInfo.data.dateRange.minDate,
      endDate: campaignInfo.data.dateRange.maxDate,
    });
  }, [campaignInfo.data, setSelectedDateRange]);

  return (
    <RangePicker
      placement="bottomRight"
      placeholder={["Report Start", "Report End"]}
      disabledDate={checkDisabledDate}
      onChange={onChange}
      value={value}
      data-testid="report-date-picker"
      allowClear={true}
      allowEmpty={[true, true]}
    />
  );
};

export default ReportDatePicker;
