import { InsightsEndpoint } from "../../../../constants/creativeInteligence";
import { useInsights } from "../../../../queries/insights/insights.queries";
import { InsightGroup, GetSizeInsightsOptions } from "../../../../queries/insights/types";
import { InsightsData, RawInsightsCreativeGroup } from "../../../../types/creativeInteligence";

interface SizeGroups extends InsightsData {
  Large: RawInsightsCreativeGroup;
  Medium: RawInsightsCreativeGroup;
  OTHER: RawInsightsCreativeGroup;
  Small: RawInsightsCreativeGroup;
}

interface SizeResponse {
  groups: SizeGroups;
}

export const SIZE_GROUPS_OPTIONS = {
  Small: 0.1,
  Medium: 0.4,
  Large: 0.6,
} as const;

export const SIZE_GROUPS = Object.entries(SIZE_GROUPS_OPTIONS).map(
  ([key, value]) => ({ name: key, maxValue: value } as InsightGroup)
);

const sizeReportKey = "sizeReportKey";

function select(data?: SizeResponse) {
  return data?.groups;
}

export function useSizeInsights(advancedSearch: string) {
  const insights = useInsights<SizeResponse, GetSizeInsightsOptions>({
    queryKey: sizeReportKey,
    endpoint: InsightsEndpoint.Size,
    extraOptions: {
      advancedSearch,
      groups: SIZE_GROUPS,
    },
  });

  return {
    ...insights,
    data: select(insights.data),
  };
}
