import { Flex, Typography } from "antd";
import { useMemo } from "react";

import { ReactComponent as NoContentSvg } from "../../../../assets/no-content.svg";
import { useCreativeIntelligenceStore } from "../../../../store/creativeIntelligence.store";
import { useReportIdsSearchParams } from "../../../../hooks/useReportIdsParam";
import { DashboardView } from "../../../../constants/creativeInteligence";
import { InsightsData, RawInsightsCreativeGroup } from "../../../../types/creativeInteligence";

import ChartWithTooltip from "../Charts/Chart";
import { ChartTypes } from "../Charts/config";

const { Title } = Typography;

interface TagPreviewChartProps {
  type: ChartTypes;
  data: InsightsData<RawInsightsCreativeGroup> | undefined;
}

const TagPreviewChart = ({ type, data }: TagPreviewChartProps) => {
  const { setReportIds } = useReportIdsSearchParams();
  const displayValue = useCreativeIntelligenceStore((state) => state.dashboardView);

  const dataSeries = useMemo(() => (data ? [{ data, metric: "kpiMetric" as const }] : []), [data]);

  const showChart = useMemo(() => {
    if (!data) {
      return false;
    }

    return Object.values(data).some((item) => item.uniqueCreativesGroups?.length);
  }, [data]);

  if (!data || !showChart) {
    return (
      <Flex className="tag-placeholder-no-content" justify="center" align="center" vertical>
        <Title level={4}>This query has no data</Title>
        <div className="tag-placeholder-no-content-image">
          <NoContentSvg />
        </div>
      </Flex>
    );
  }

  return (
    <ChartWithTooltip
      className="tag-preview-chart"
      type={type}
      onElementClick={setReportIds}
      isUtilization={displayValue === DashboardView.Utilisation}
      dataSeries={dataSeries}
    />
  );
};

export default TagPreviewChart;
