import { useState, useMemo, useEffect } from "react";
import { Typography, Flex, Space, Select, Switch, Button, Spin } from "antd";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";

import PageWrapper from "../../components/DashboardView/DashboardPageWrapper";
import {
  SearchTypes,
  SearchTypeKey,
  SearchProps,
} from "../../components/DashboardView/components/AdvancedSearch/AdvancedSearch";
import { useRouterPaths } from "../../hooks/useRouterPaths";
import { useCampaignInfo } from "../../queries/insights/campaignInfo.queries";
import { useCurrentTag } from "../../queries/tags/tags.query";
import { useCreativeIntelligenceStore } from "../../store/creativeIntelligence.store";
import { CHARTS } from "../DashboardPage/components/Charts/config";

import ComparisonMatrix from "./components/ComparisonMatrix/ComparisonMatrix";
import ChartReport from "./components/ChartReport";
import InsightsReport from "./components/InsightsReport";

import "./TagReportPage.scss";

const { Text } = Typography;

const REPORTS = {
  InsightsReport: "insights-report",
  QuadrantReport: "quadrant-report",
  BarReport: "bar-report",
} as const;

const REPORT_OPTIONS = [
  { value: REPORTS.InsightsReport, label: "Insights Report" },
  { value: REPORTS.QuadrantReport, label: "Quadrant Report" },
  { value: REPORTS.BarReport, label: "Bar Report" },
];

const TagReportPage = ({
  name,
  advancedSearch,
  searchProps,
}: {
  name: string;
  advancedSearch: string;
  searchProps: SearchProps;
}) => {
  const { dashboardPath } = useRouterPaths();

  const showAttentionScore = useCreativeIntelligenceStore((state) => state.showAttentionScore);
  const toggleAttentionScore = useCreativeIntelligenceStore((state) => state.toggleAttentionScore);

  const campaignInfo = useCampaignInfo();
  const attentionScoreButtonEnabled = campaignInfo.data?.metadata?.dragonfly;

  const [reportView, setReportView] = useState<(typeof REPORTS)[keyof typeof REPORTS]>(REPORTS.InsightsReport);

  return (
    <PageWrapper searchProps={searchProps}>
      <Flex vertical gap="middle" className="tag-report">
        <ComparisonMatrix advancedSearch={advancedSearch} />
        <Flex className="tag-report-menu" justify="space-between" align="center" gap="middle">
          <Link to={dashboardPath} data-testid="back-button">
            <Space>
              <LeftOutlined />
              <Text strong ellipsis>
                Return to dashboard
              </Text>
            </Space>
          </Link>
          <Space>
            Show me:
            <Select
              value={reportView}
              variant="borderless"
              popupMatchSelectWidth={false}
              options={REPORT_OPTIONS}
              onChange={setReportView}
            />
          </Space>
          <Space>
            {attentionScoreButtonEnabled && (
              <Space>
                Attention score
                <Switch
                  onChange={toggleAttentionScore}
                  checked={showAttentionScore}
                  data-testid="attention-score-switch"
                />
              </Space>
            )}
            <Button disabled>Download {name} Report</Button>
          </Space>
        </Flex>

        {reportView === REPORTS.InsightsReport ? (
          <InsightsReport name={name} advancedSearch={advancedSearch} />
        ) : (
          <ChartReport
            advancedSearch={advancedSearch}
            type={reportView === REPORTS.BarReport ? CHARTS.BAR : CHARTS.SCATTER}
          />
        )}
      </Flex>
    </PageWrapper>
  );
};

export const SearchTagReportPage = () => {
  const { dashboardPath } = useRouterPaths();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const searchTerm = searchParams.get("term") ?? "";
  const query = searchParams.get("query") ?? "";

  const initialSearchTypeKey = useMemo(() => {
    return (
      (Object.entries(SearchTypes).find(([, value]) => query.startsWith(value))?.[0] as SearchTypeKey) ?? "Everything"
    );
  }, [query]);

  useEffect(() => {
    if (!searchParams.get("term")) {
      navigate(dashboardPath);
    }
  }, [dashboardPath, navigate, searchParams]);

  return (
    <TagReportPage
      name={searchTerm}
      advancedSearch={query}
      searchProps={{ initialSearch: searchTerm, initialSearchTypeKey }}
    />
  );
};

export const CurrentTagReportPage = () => {
  const currentTag = useCurrentTag();

  const initialSearchTypeKey = useMemo(() => {
    return (
      (Object.entries(SearchTypes).find(([, value]) =>
        currentTag.data?.advancedSearch.startsWith(value)
      )?.[0] as SearchTypeKey) ?? "Everything"
    );
  }, [currentTag.data?.advancedSearch]);

  if (currentTag.isPending) {
    return <Spin />;
  }

  if (!currentTag.data) {
    return <Text>Tag not found</Text>;
  }

  return (
    <TagReportPage
      name={currentTag.data.name}
      advancedSearch={currentTag.data.advancedSearch}
      searchProps={{ initialSearch: currentTag.data.name, initialSearchTypeKey, tag: currentTag.data }}
    />
  );
};
