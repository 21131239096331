import { useMemo } from "react";

import { getBestAttributeValue } from "../../utils";

import { InsightsEndpoint } from "../../../../constants/creativeInteligence";
import { useKpiMetricName } from "../../../../queries/metrics.queries";

import InsightContent, { InsightContentProps } from "../InsightContent/InsightContent";

import { useSizeInsights, SIZE_GROUPS_OPTIONS, SIZE_GROUPS } from "./size.query";

interface SizeProps {
  advancedSearch: string;
}

const SizeContent = ({ advancedSearch }: SizeProps) => {
  const insights = useSizeInsights(advancedSearch);
  const kpiMetricName = useKpiMetricName();

  const bestAttribute = useMemo(() => {
    if (insights.data && kpiMetricName) {
      return getBestAttributeValue(insights.data, kpiMetricName);
    }
  }, [kpiMetricName, insights.data]);

  const entries: InsightContentProps[] = useMemo(() => {
    if (!insights.data || !bestAttribute) {
      return [];
    }

    return [
      {
        creativeHash: insights.data.Small.uniqueCreativesGroups?.[0]?.creativeHash,
        label: "Small",
        advancedSearch,
        summaryStats: insights.data.Small.summaryStats,
        deliverable: insights.data.Small.uniqueCreativesGroups?.[0]?.deliverables[0],
        filter: {
          bucketFilters: {
            type: InsightsEndpoint.Size,
            minValue: 0,
            maxValue: SIZE_GROUPS_OPTIONS.Small,
          },
          groups: SIZE_GROUPS,
        },
      },
      {
        creativeHash: insights.data.Medium.uniqueCreativesGroups?.[0]?.creativeHash,
        label: "Medium",
        advancedSearch,
        summaryStats: insights.data.Medium.summaryStats,
        deliverable: insights.data.Medium.uniqueCreativesGroups?.[0]?.deliverables[0],
        filter: {
          bucketFilters: {
            type: InsightsEndpoint.Size,
            minValue: SIZE_GROUPS_OPTIONS.Small,
            maxValue: SIZE_GROUPS_OPTIONS.Medium,
          },
          groups: SIZE_GROUPS,
        },
      },
      {
        creativeHash: insights.data.Large.uniqueCreativesGroups?.[0]?.creativeHash,
        label: "Large",
        advancedSearch,
        summaryStats: insights.data.Large.summaryStats,
        deliverable: insights.data.Large.uniqueCreativesGroups?.[0]?.deliverables[0],
        filter: {
          bucketFilters: {
            type: InsightsEndpoint.Size,
            minValue: SIZE_GROUPS_OPTIONS.Medium,
            maxValue: SIZE_GROUPS_OPTIONS.Large,
          },
          groups: SIZE_GROUPS,
        },
      },
    ];
  }, [insights.data, bestAttribute, advancedSearch]);

  return <InsightContent loading={insights.isPending} bestAttribute={bestAttribute} entries={entries} />;
};

export default SizeContent;
