import { InsightsEndpoint } from "../../../../constants/creativeInteligence";
import { useInfiniteInsights } from "../../../../queries/insights/insights.queries";
import { BucketFilters } from "../../../../queries/insights/types";
import { RawInsightsCreativeGroup } from "../../../../types/creativeInteligence";

interface BucketFilterAttributesType extends BucketFilters {
  value: string;
  type: InsightsEndpoint.AttributeValues;
}

interface BucketFilterPositionType extends BucketFilters {
  value: string;
  type: InsightsEndpoint.Position;
}

interface BucketFilterSizeType extends BucketFilters {
  minValue: number;
  maxValue: number;
  type: InsightsEndpoint.Size;
}

interface BucketFilterTimeOnScreenType extends BucketFilters {
  minValue: number;
  maxValue: number;
  type: InsightsEndpoint.SegmentLength;
}

interface BucketFilterAppearanceOnScreenType extends BucketFilters {
  minValue: number;
  maxValue: number;
  type: InsightsEndpoint.SegmentPosition;
}

export type BucketFilter =
  | BucketFilterAttributesType
  | BucketFilterPositionType
  | BucketFilterSizeType
  | BucketFilterAppearanceOnScreenType
  | BucketFilterTimeOnScreenType;

interface BucketFiltersProps {
  advancedSearch: string;
  enabled: boolean;
  filter: {
    bucketFilters: BucketFilter;
    groups?: { name: string; maxValue: number }[];
  };
}

const bucketValuesKey = "bucketValuesKey";

export function useBucketValues({ filter, advancedSearch }: BucketFiltersProps) {
  return useInfiniteInsights<RawInsightsCreativeGroup>({
    queryKey: bucketValuesKey,
    endpoint: InsightsEndpoint.BucketValues,
    extraOptions: {
      advancedSearch,
      topUniqueCreativesNumber: 20,
      ...filter,
    },
  });
}
