import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import { Tag } from "../../types/creativeInteligence";
import { TAGS_QUERY_KEY } from "../tags/tags.query";
import { UpdateTagPayload, useAddTag, useDeleteTag } from "../tags/tag.mutations";

import { useUpdateDashboard } from "./dashboard.mutations";
import { useDashboard } from "./dashboard.query";

export function useAddDashboardTag(brandId: string, dashboardId: string) {
  const dashboard = useDashboard(dashboardId);
  const updateDashboard = useUpdateDashboard();
  const addTag = useAddTag();

  const [isPending, setIsPending] = useState(false);

  const add = useCallback(
    async function ({ tag, sourceTagId }: { tag: UpdateTagPayload; sourceTagId?: string }) {
      try {
        if (!dashboard.data) {
          throw new Error();
        }

        setIsPending(true);

        const resp = await addTag.mutateAsync({ tag, sourceTagId, dashboardId });

        await updateDashboard.mutateAsync({
          brandId,
          dashboardId,
          dashboardData: {
            tagsIds: [resp.data.tagId, ...(dashboard.data?.tagsIds ?? [])],
          },
        });

        setIsPending(false);

        return resp.data;
      } catch {
        setIsPending(false);

        throw new Error("Failed to add tag");
      }
    },
    [addTag, brandId, dashboard.data, dashboardId, updateDashboard]
  );

  return {
    add,
    isPending,
  };
}

export function useDeleteDashboardTag(brandId: string, dashboardId: string) {
  const dashboard = useDashboard(dashboardId);
  const updateDashboard = useUpdateDashboard();
  const deleteTag = useDeleteTag();

  const [isPending, setIsPending] = useState(false);

  const remove = useCallback(
    async function (tagId: string) {
      try {
        if (!dashboard.data) {
          throw new Error();
        }

        setIsPending(true);

        await deleteTag.mutateAsync({ tagId, dashboardId });

        await updateDashboard.mutateAsync({
          brandId,
          dashboardId,
          dashboardData: { tagsIds: dashboard.data.tagsIds?.filter((id) => id !== tagId) ?? [] },
        });

        setIsPending(false);
      } catch {
        setIsPending(false);

        throw new Error("Failed to remove tag");
      }
    },
    [brandId, dashboard.data, dashboardId, deleteTag, updateDashboard]
  );

  return {
    remove,
    isPending,
  };
}

function useUpdateDashboardTagOrder(brandId: string, dashboardId: string) {
  const queryClient = useQueryClient();
  const dashboard = useDashboard(dashboardId);
  const updateDashboard = useUpdateDashboard();

  const [isPending, setIsPending] = useState(false);

  const update = useCallback(
    async function (newTagIdsOrder: string[]) {
      try {
        setIsPending(true);

        queryClient.setQueryData([TAGS_QUERY_KEY, dashboardId], (previous: Tag[] = []) => {
          return newTagIdsOrder.map((tagId) => previous.find((tag) => tag.tagId === tagId)!);
        });

        await updateDashboard.mutateAsync({
          brandId,
          dashboardId,
          dashboardData: {
            tagsIds: newTagIdsOrder,
          },
        });

        setIsPending(false);
      } catch {
        queryClient.setQueryData([TAGS_QUERY_KEY, dashboardId], (previous: Tag[] = []) => {
          return dashboard.data?.tagsIds?.map((tagId) => previous.find((tag) => tag.tagId === tagId)!);
        });

        setIsPending(false);

        throw new Error("Failed to update tag order");
      }
    },
    [brandId, dashboard.data?.tagsIds, dashboardId, queryClient, updateDashboard]
  );

  return {
    update,
    isPending,
  };
}

export function useUpdateCurrentDashboardTagOrder() {
  const { brandId, dashboardId } = useParams();

  return useUpdateDashboardTagOrder(brandId, dashboardId);
}
