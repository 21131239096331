import React from "react";
import { Outlet } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { App, ConfigProvider, Layout, Spin } from "antd";

import { imagineTheme } from "../../../utils/imagine";
import { useDconfStore } from "../../../store/dconf/dconf.store";

import MainHeader from "../MainHeader";

import "./RootLayout.scss";

const RootLayout = () => {
  const initialized = useDconfStore((s) => s.initialized);

  return (
    <ConfigProvider theme={imagineTheme}>
      <App className="ci-app">
        <Layout>
          <Layout.Header className="main-layout__header">
            <MainHeader />
          </Layout.Header>

          {initialized ? (
            <AnimatePresence mode="sync">
              <Outlet />
            </AnimatePresence>
          ) : (
            <Spin />
          )}
        </Layout>
      </App>
    </ConfigProvider>
  );
};

export default RootLayout;
