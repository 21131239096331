import { json } from "react-router-dom";

import { useConfigStore } from "../store/config/config.store";
import { authActions } from "../store/auth/auth.store";
import { dconfStoreActions } from "../store/dconf/dconf.store";

const rootLoader = async () => {
  try {
    const domainConfig = await dconfStoreActions.init();

    if (domainConfig) {
      authActions.init(domainConfig);
      await useConfigStore.getState().init();
    }

    return json(domainConfig);
  } catch (error) {
    console.error(error);

    return json(null);
  }
};

export default rootLoader;
