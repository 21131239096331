import { CHART_COLORS } from "../config";

export function getPointColors(label: string, index: number) {
  const labelWithHexRegEx = new RegExp(/^.*-#?([0-9a-f]{6})$/, "i");
  const [, matchedLabelColor] = labelWithHexRegEx.exec(label) ?? [];

  if (matchedLabelColor) {
    return {
      backgroundColor: `#${matchedLabelColor}`,
      hoverBackgroundColor: `#${matchedLabelColor}AA`,
    };
  }

  return {
    backgroundColor: CHART_COLORS[index % CHART_COLORS.length],
    hoverBackgroundColor: `${CHART_COLORS[index % CHART_COLORS.length]}AA`,
  };
}
