export const enum InsightType {
  AppearanceDurationAnalysis = "AppearanceDurationAnalysis",
  AppearancePositionAnalysis = "AppearancePositionAnalysis",
  AttributeMetadata = "AttributeMetadata",
  AttributeAdMetadata = "AttributeAdMetadata",
  AttributeValuesAnalysis = "AttributeValuesAnalysis",
  BucketValues = "BucketValues",
  CampaignInfo = "CampaignInfo",
  // ColorAnalysis = "ColorAnalysis",
  ContextAnalysis = "ContextAnalysis",
  KeyTakeaway = "KeyTakeaway",
  KeyTakeawayNotMatching = "KeyTakeawayNotMatching",
  Keywords = "Keywords",
  Matrix = "Matrix",
  PositionAnalysis = "PositionAnalysis",
  SizeAnalysis = "SizeAnalysis",
}

export const enum InsightsEndpoint {
  AttributeAdMetadata = "attribute-ad-metadata",
  AttributeMetadata = "attribute-metadata",
  AttributeValues = "attribute-values",
  BucketValues = "bucket-values",
  CampaignInfo = "campaign-info",
  Color = "color",
  Context = "context",
  KeyTakeaway = "key-takeaway",
  Matrix = "matrix",
  Position = "position",
  SegmentLength = "segment-length",
  SegmentPosition = "segment-position",
  Size = "size",
  ComputeTags = "compute-tags-metric-stats",
}

export const InsightTypeToServiceName = {
  [InsightType.AppearanceDurationAnalysis]: InsightsEndpoint.SegmentLength,
  [InsightType.AppearancePositionAnalysis]: InsightsEndpoint.SegmentPosition,
  [InsightType.AttributeMetadata]: InsightsEndpoint.AttributeMetadata,
  [InsightType.AttributeAdMetadata]: InsightsEndpoint.AttributeAdMetadata,
  [InsightType.ContextAnalysis]: InsightsEndpoint.Context,
  [InsightType.KeyTakeaway]: InsightsEndpoint.KeyTakeaway,
  [InsightType.KeyTakeawayNotMatching]: InsightsEndpoint.KeyTakeaway,
  [InsightType.Matrix]: InsightsEndpoint.Matrix,
  [InsightType.PositionAnalysis]: InsightsEndpoint.Position,
  [InsightType.SizeAnalysis]: InsightsEndpoint.Size,
  [InsightType.AttributeValuesAnalysis]: InsightsEndpoint.AttributeValues,
  [InsightType.BucketValues]: InsightsEndpoint.BucketValues,
  [InsightType.CampaignInfo]: InsightsEndpoint.CampaignInfo,
  [InsightType.Keywords]: InsightsEndpoint.AttributeMetadata,
} as const;

export const InsightsMetadata = {
  POSITION: "position",
  AREA: "size",
  WORD_COUNT: "word-count",
  ATTENTION_SCORE: "attention-score",
  KEY_PHRASES: "key-phrases",
  KEYWORDS: "keywords",
  WORD_COUNT_GROUP: "word-count-group",
} as const;

export const AdMetadataInsights = {
  AD_ID: "ad-id",
  ADSET_NAME: "adset-name",
  CAMPAIGN_ID: "campaign-id",
  COMPAIGN_NAME: "campaign-name",
  OBJECTIVE: "objective",
  AD_TYPE: "ad-type",
  DURATION: "duration",
  ASPECT_RATIO: "aspect-ratio",
  POST_TYPE: "post-type",
  ATTACHMENT_TYPE: "attachment-type",
} as const;

export const InsightTypeDefaultServiceOptions = {
  [InsightType.AttributeMetadata]: {
    type: InsightsMetadata.KEYWORDS,
  },
  [InsightType.AttributeAdMetadata]: {
    type: AdMetadataInsights.AD_TYPE,
  },
  [InsightType.Keywords]: {
    type: InsightsMetadata.KEYWORDS,
  },
  [InsightType.SizeAnalysis]: {
    groups: [
      { name: "Small", maxValue: 0.1 },
      { name: "Medium", maxValue: 0.4 },
      { name: "Large", maxValue: 0.6 },
    ],
  },
  [InsightType.AppearanceDurationAnalysis]: {
    groups: [
      { name: "0-2", maxValue: 2 },
      { name: "0-5", maxValue: 5 },
      { name: "0-200", maxValue: 200 },
    ],
  },
  [InsightType.AppearancePositionAnalysis]: {
    groups: [
      { name: "0-2", maxValue: 2 },
      { name: "0-5", maxValue: 5 },
      { name: "0-200", maxValue: 200 },
    ],
  },
  [InsightType.AttributeValuesAnalysis]: {},
  [InsightType.BucketValues]: {},
  [InsightType.CampaignInfo]: {},
  [InsightType.ContextAnalysis]: {},
  [InsightType.KeyTakeaway]: {},
  [InsightType.KeyTakeawayNotMatching]: {},
  [InsightType.Matrix]: {},
  [InsightType.PositionAnalysis]: {},
} as const;

export type InsightTypeDefaultServiceType = (typeof InsightTypeDefaultServiceOptions)[InsightType];

export enum DashboardView {
  Top = "top",
  Bottom = "bottom",
  Utilisation = "utilisation",
  DataExplorer = "dataExplorer",
}

export enum Order {
  asc = "asc",
  desc = "desc",
}

export const DATE_FORMAT = "YYYY-MM-DD";
