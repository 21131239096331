import { useMemo } from "react";
import { type MenuProps } from "antd";

import type { DashboardWithId } from "../../../types/entities";
import { Card } from "../../../components/UI/Card";

interface Props {
  dashboard: DashboardWithId;
  onEdit: (dashboardId: string) => void;
}

export const DashboardCard = ({ dashboard, onEdit }: Props) => {
  const menu: MenuProps = useMemo(
    () => ({
      items: [
        {
          key: "1",
          label: <span data-testid="dashboard-card-menu-edit-button">Edit</span>,
          onClick: (info) => {
            info.domEvent.stopPropagation();
            onEdit(dashboard.id);
          },
        },
      ],
    }),
    [dashboard.id, onEdit]
  );

  return (
    <Card
      menu={menu}
      logoUrl={dashboard.logoFileLocation?.signedUrl}
      footerTitle={dashboard.name}
      testIdPrefix="dashboard-card"
    />
  );
};
