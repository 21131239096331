import { useCallback } from "react";
import { Modal, Input, Form, message } from "antd";
import { useNavigate } from "react-router-dom";

import { useRouterPaths } from "../../../../../hooks/useRouterPaths";
import { useAddDashboardTag } from "../../../../../queries/entities/dashboard.actions";
import { defaultTagOptions } from "../../../../../queries/tags/tags.query";

const saveFormId = "save-form";

const SaveTagModal = ({
  search,
  queryPrefix,
  dashboardId,
  brandId,
  isModalOpen,
  onClose,
}: {
  search: string;
  queryPrefix: string;
  dashboardId: string;
  brandId: string;
  isModalOpen: boolean;
  onClose: () => void;
}) => {
  const navigate = useNavigate();
  const addDashboardTag = useAddDashboardTag(brandId, dashboardId);

  const [form] = Form.useForm<{ name: string; advancedSearch: string }>();
  const { tagIndexPath } = useRouterPaths();
  const handleOk = useCallback(async () => {
    try {
      const { name } = await form.validateFields();
      const advancedSearch = form.getFieldValue("advancedSearch") as string;

      const newTag = await addDashboardTag.add({
        tag: { name, advancedSearch, tagOptions: defaultTagOptions },
      });

      message.success("Search query has been saved");
      navigate(`${tagIndexPath}/${newTag.tagId}`);
    } catch {
      message.error("Failed to save the search query");
    }
  }, [form, addDashboardTag, navigate, tagIndexPath]);

  return (
    <Modal
      title="Save search tag"
      centered
      open={isModalOpen}
      okText="Save"
      onOk={handleOk}
      okButtonProps={{
        loading: addDashboardTag.isPending,
        htmlType: "submit",
        form: saveFormId,
        ["data-testid"]: "advanced-search-modal-save-button",
      }}
      cancelButtonProps={{ ["data-testid"]: "advanced-search-modal-cancel-button" }}
      cancelText="Close"
      onCancel={onClose}
      destroyOnClose
    >
      <Form
        id={saveFormId}
        form={form}
        layout="vertical"
        preserve={false}
        initialValues={{
          name: search,
          advancedSearch: `${queryPrefix}${search}`,
        }}
      >
        <Form.Item label="Name" name="name" rules={[{ required: true, message: "Please input the tag name!" }]}>
          <Input data-testid="advanced-search-modal-save-input-name" />
        </Form.Item>
        <Form.Item label="Query" name="advancedSearch" rules={[{ required: true, message: "Please input the query!" }]}>
          <Input data-testid="advanced-search-modal-save-input-query" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SaveTagModal;
