import { useCallback, useEffect, useState } from "react";
import { RuleObject } from "antd/es/form";
import { Typography, Flex, Button, Space, Select, Input, Collapse, Form, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import { Tag } from "../../../../types/creativeInteligence";
import { useUpdateTag } from "../../../../queries/tags/tag.mutations";
import { InsightType, InsightTypeDefaultServiceOptions } from "../../../../constants/creativeInteligence";

import { ChartTypes, ChartTypeLabel } from "../Charts/config";
import DeleteTagModal from "../../../../components/DashboardView/components/DeleteTagModal";

import "./TagSettings.scss";

const { Text } = Typography;
const { TextArea } = Input;

function advancedOptionsValidator(_: RuleObject, value: string) {
  try {
    JSON.parse(value);

    return Promise.resolve();
  } catch {
    return Promise.reject(new Error("Invalid JSON format."));
  }
}

const chartTypeOptions = Object.keys(ChartTypeLabel).map((chartType) => ({
  label: ChartTypeLabel[chartType as ChartTypes],
  value: chartType,
}));

const endpointTypes = [
  { value: InsightType.AppearanceDurationAnalysis, label: InsightType.AppearanceDurationAnalysis },
  { value: InsightType.AppearancePositionAnalysis, label: InsightType.AppearancePositionAnalysis },
  { value: InsightType.AttributeMetadata, label: InsightType.AttributeMetadata },
  { value: InsightType.AttributeAdMetadata, label: InsightType.AttributeAdMetadata },
  { value: InsightType.AttributeValuesAnalysis, label: InsightType.AttributeValuesAnalysis },
  { value: InsightType.PositionAnalysis, label: InsightType.PositionAnalysis },
  { value: InsightType.SizeAnalysis, label: InsightType.SizeAnalysis },
  { value: InsightType.Keywords, label: InsightType.Keywords },
];

interface TagSettingsProps {
  tag: Tag;
  brandId: string;
  dashboardId: string;
  handleHideSettings: () => void;
}

interface FormData {
  chartType: ChartTypes;
  selectedEndpoint: (typeof InsightType)[keyof typeof InsightType];
  advancedOptions?: string;
}

const TagSettings = ({ brandId, dashboardId, tag, handleHideSettings }: TagSettingsProps) => {
  const tagUpdateMutation = useUpdateTag();
  const [form] = Form.useForm<FormData>();
  const selectedEndpoint = Form.useWatch("selectedEndpoint", form);

  const { endpoint, defaultPreview, advancedOptions } = tag.tagOptions;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = useCallback(() => setIsModalOpen(true), []);
  const closeModal = useCallback(() => setIsModalOpen(false), []);

  const closeSettings = useCallback(() => {
    handleHideSettings();
    form.resetFields();
  }, [form, handleHideSettings]);

  const saveTagOptions = useCallback(async () => {
    const newValues = await form.validateFields();

    try {
      await tagUpdateMutation.mutateAsync({
        tag: {
          ...tag,
          tagOptions: {
            endpoint: newValues.selectedEndpoint,
            defaultPreview: newValues.chartType,
            advancedOptions: newValues.advancedOptions,
          },
        },
        tagId: tag.tagId,
        dashboardId,
      });

      handleHideSettings();
    } catch {
      message.error("Failed to update tag settings");
    }
  }, [dashboardId, form, handleHideSettings, tag, tagUpdateMutation]);

  useEffect(() => {
    if (selectedEndpoint === endpoint) {
      form.setFieldValue("advancedOptions", advancedOptions);

      return;
    }

    const defaultAdvancedOptions = JSON.stringify(InsightTypeDefaultServiceOptions[selectedEndpoint], null, 2);

    form.setFieldValue("advancedOptions", defaultAdvancedOptions);
  }, [selectedEndpoint, form, advancedOptions, endpoint]);

  return (
    <Flex className="tag-preview-settings-wrapper" vertical justify="space-between">
      <Flex justify="flex-end" align="center">
        <Button
          icon={<CloseOutlined />}
          type="link"
          onClick={closeSettings}
          data-testid={`tag-preview-settings-close-btn-icon-${tag.tagId}`}
        />
      </Flex>

      <Flex vertical align="center">
        <Form
          className="tag-settings-form"
          form={form}
          initialValues={{
            selectedEndpoint: endpoint,
            chartType: defaultPreview,
            advancedOptions,
          }}
        >
          <Form.Item name="chartType" label="Chart type:">
            <Select
              variant="borderless"
              options={chartTypeOptions}
              className="tag-settings-custom-select"
              data-testid={`tag-preview-settings-chart-type-select-${tag.tagId}`}
            >
              {chartTypeOptions.map((chartTypeOption) => (
                <Select.Option
                  key={chartTypeOption.value}
                  value={chartTypeOption.value}
                  data-testid={`tag-preview-settings-chart-type-option-${tag.tagId}`}
                >
                  {chartTypeOption.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="selectedEndpoint" label="Endpoint:">
            <Select
              className="tag-settings-custom-select"
              popupMatchSelectWidth={false}
              variant="borderless"
              options={endpointTypes}
              data-testid={`tag-preview-settings-endpoint-select-${tag.tagId}`}
            >
              {endpointTypes.map((endpointType) => (
                <Select.Option
                  key={endpointType.value}
                  value={endpointType.value}
                  data-testid={`tag-preview-settings-endpoint-option-${tag.tagId}`}
                >
                  {endpointType.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {
            InsightTypeDefaultServiceOptions[selectedEndpoint]
            && ![InsightType.AttributeValuesAnalysis, InsightType.PositionAnalysis].includes(selectedEndpoint)
            && (
              <Collapse
                ghost
                className="tag-settings-custom-collapse"
                defaultActiveKey={["1"]}
                items={[
                  {
                    key: "1",
                    label: (
                      <span data-testid={`tag-preview-settings-advanced-options-collapse-${tag.tagId}`}>
                        Advanced Options
                      </span>
                    ),
                    children: (
                      <>
                        <Text>Endpoint Options</Text>
                        <Form.Item name="advancedOptions" rules={[{ validator: advancedOptionsValidator }]}>
                          <TextArea
                            autoSize
                            minLength={3}
                            className="tag-settings-textarea"
                            data-testid={`tag-preview-settings-advanced-options-textarea-${tag.tagId}`}
                          />
                        </Form.Item>
                      </>
                    ),
                  },
                ]}
              />
            )
          }
        </Form>
      </Flex>

      <Flex justify="space-between" className="tag-preview-footer">
        <Button key="delete" danger onClick={openModal} data-testid="advanced-search-open-delete-modal-button">
          Delete
        </Button>

        <Space>
          <Button onClick={closeSettings} data-testid={`tag-preview-settings-close-btn-${tag.tagId}`}>
            Close
          </Button>

          <Button
            type="primary"
            onClick={saveTagOptions}
            data-testid={`tag-preview-settings-save-btn-${tag.tagId}`}
            loading={tagUpdateMutation.isPending}
          >
            Save
          </Button>
        </Space>
      </Flex>

      <DeleteTagModal
        isOpen={isModalOpen}
        tagId={tag.tagId}
        brandId={brandId}
        dashboardId={dashboardId}
        onClose={closeModal}
      />
    </Flex>
  );
};

export default TagSettings;
