import { useCallback } from "react";
import { Tag } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

import { useCreativeIntelligenceStore } from "../../../store/creativeIntelligence.store";

const DashboardFilters = () => {
  const selectedFilters = useCreativeIntelligenceStore((state) => state.selectedFilters);
  const setSelectedFilters = useCreativeIntelligenceStore((state) => state.setSelectedFilters);

  const clearFilter = useCallback(
    (breakdown: string, value: string) => () => {
      if (!selectedFilters) {
        return;
      }

      const newBreakdown = selectedFilters[breakdown].filter((v) => v !== value);
      const newFilters = { ...selectedFilters };

      if (newBreakdown.length === 0) {
        delete newFilters[breakdown];
      } else {
        newFilters[breakdown] = newBreakdown;
      }

      setSelectedFilters(newFilters);
    },
    [selectedFilters, setSelectedFilters]
  );

  if (!selectedFilters) {
    return null;
  }

  return (
    <section>
      {Object.entries(selectedFilters).map(([breakdown, values]) => {
        return values.map((value) => (
          <Tag key={value} closeIcon={<CloseCircleOutlined />} onClose={clearFilter(breakdown, value)}>
            {value}
          </Tag>
        ));
      })}
    </section>
  );
};

export default DashboardFilters;
