import { useState, useMemo, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Button, Card, Collapse, CollapseProps, Flex, Form, Spin, Steps, Typography, message } from "antd";
import { useForm } from "antd/es/form/Form";

import MainPageContent from "../../components/Layout/MainPageContent";
import { useRouterPaths } from "../../hooks/useRouterPaths";
import { useConfigStore } from "../../store/config/config.store";
import { DomainConfig } from "../../store/config/types";
import { AIServices, InventoryWityId, DeliveryConfig, AIConfigs } from "../../types/entities";
import { useInventory } from "../../queries/entities/inventory.queries";
import { useAddDelivery } from "../../queries/entities/delivery.mutations";
import { useInventoryDeliveries } from "../../queries/entities/inventoryDeliveries.query";

import { MainDivider } from "./JobDetails";
import DeliveryForm from "./components/DeliveryFormSection";
import EnrichForm from "./components/EnrichFormSection";
import ExtractorForm from "./components/ExtractorFormSection";
import { getInitialFormValues, getDeliveryConfigFromForm } from "./utils";

export interface DeliveryJobFormValues {
  metricNames: string[];
  breakdowns: string[];
  uploadType: string;
  gcsPath: string;
  gcsBucket: string;
  normalizationType: string;
  enrichServices: (typeof AIServices)[keyof typeof AIServices][];
  enrichAIConfig: AIConfigs;
  sataliaIngestion: boolean;
  domoIngestion: boolean;
}

const steps = ["Extractor", "Enrich", "Delivery"].map((step) => ({ key: step, title: step }));

const DeliveryJobForm = ({
  brandId,
  inventory,
  config,
}: {
  brandId: string;
  inventory: InventoryWityId;
  config: DomainConfig;
}) => {
  const navigate = useNavigate();
  const { jobDeliveryIndexPath } = useRouterPaths();

  const deliveries = useInventoryDeliveries(inventory.id);
  const addDeliveryJob = useAddDelivery(inventory.id);

  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [form] = useForm<DeliveryJobFormValues>();

  const initialValues = useMemo(() => getInitialFormValues({ config, inventory }), [config, inventory]);

  const onNextStep = useCallback(async () => {
    if (currentStep === 1) {
      try {
        await form.validateFields();
      } catch {
        return;
      }
    }

    setCurrentStep((prev) => prev + 1);
  }, [currentStep, form]);

  const onPrevStep = useCallback(() => setCurrentStep((prev) => prev - 1), []);

  const previousDeliveryId = deliveries.data?.items[deliveries.data.items.length - 1]?.id;

  const onStartJob = useCallback(async () => {
    setIsLoading(true);
    message.loading({
      key: "startDeliveryJob",
      content: "Loading...",
    });

    const deliveryConfig = getDeliveryConfigFromForm({
      previousDeliveryId,
      inventory,
      storageInfo: config.storageInfo,
      brandId,
      formValues: form.getFieldsValue(),
    });

    try {
      await form.validateFields();

      const newDelivery = await addDeliveryJob.mutateAsync(deliveryConfig);

      message.success({
        key: "startDeliveryJob",
        content: "Delivery job started",
      });

      navigate(`${jobDeliveryIndexPath}/${newDelivery.id}`);
    } catch {
      message.error({
        key: "startDeliveryJob",
        content: "Error starting delivery job",
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    previousDeliveryId,
    inventory,
    config.storageInfo,
    brandId,
    form,
    addDeliveryJob,
    navigate,
    jobDeliveryIndexPath,
  ]);

  const [partialConfig, setPartialConfig] = useState<Partial<DeliveryConfig>>({});

  const collapseItems: CollapseProps["items"] = useMemo(
    () => [
      {
        key: "1",
        label: "Details",
        children: <pre>{JSON.stringify(partialConfig, null, 2)}</pre>,
      },
    ],
    [partialConfig]
  );

  useEffect(() => {
    const valuesToSave = getDeliveryConfigFromForm({
      previousDeliveryId,
      inventory,
      storageInfo: config.storageInfo,
      brandId,
      formValues: form.getFieldsValue(),
    });

    setPartialConfig(valuesToSave);
  }, [previousDeliveryId, brandId, config.storageInfo, form, inventory, currentStep]);

  return (
    <Flex vertical gap="middle" className="job-form-wrapper">
      <Typography.Title level={3}>Add delivery job for {inventory.extractorInput.jobName}</Typography.Title>

      <Steps current={currentStep} items={steps} />

      <Card>
        <Form form={form} initialValues={initialValues} layout="vertical">
          <ExtractorForm config={config} inventory={inventory} show={currentStep === 0} />
          <EnrichForm config={config} show={currentStep === 1} />
          <DeliveryForm config={config} show={currentStep === 2} />

          {addDeliveryJob.error && (
            <>
              <Alert description={`Failed to start delivery: ${addDeliveryJob.error.message}`} type="error" showIcon />
              <br />
            </>
          )}

          <Form.Item>
            <Flex justify="space-between">
              <Button onClick={onPrevStep} disabled={currentStep === 0}>
                Back
              </Button>

              {currentStep < steps.length - 1 ? (
                <Button type="primary" onClick={onNextStep}>
                  Continue
                </Button>
              ) : (
                <Button type="primary" onClick={onStartJob} loading={isLoading}>
                  Start delivery Job
                </Button>
              )}
            </Flex>
          </Form.Item>
        </Form>
      </Card>

      <MainDivider>Raw delivery input preview</MainDivider>
      <Collapse items={collapseItems} />
    </Flex>
  );
};

const AddDeliveryJob = () => {
  const { brandId, inventoryId } = useParams();
  const inventory = useInventory(inventoryId);
  const config = useConfigStore((state) => state.config);

  if (inventory.isPending) {
    return <Spin />;
  }

  if (inventory.isError || !inventory.data || !config) {
    return <div>No inventory</div>;
  }

  return <DeliveryJobForm brandId={brandId} inventory={inventory.data} config={config} />;
};

const AddDeliveryJobPage = () => {
  return (
    <MainPageContent>
      <AddDeliveryJob />
    </MainPageContent>
  );
};

export default AddDeliveryJobPage;
