import { useQuery } from "@tanstack/react-query";

import { InventoryEntity, Inventory, RELATIONS, Brand } from "../../types/entities";
import { entityRequest } from "../../utils/entity.api";
import { useAuthStore } from "../../store/auth/auth.store";

import { BRAND_API_URL } from "./brand.query";
import { useAllPagesEntityQuery } from "./useInfiniteEntityQuery";

export const INVENTORY_API_URL = "/inventory";
export const INVENTORY_QUERY_KEY = "inventory";
export const BRAND_INVENTORY_QUERY_KEY = "allBrandInventories";

async function fetchInventory(inventoryId: string | undefined) {
  if (!inventoryId) {
    return;
  }

  return entityRequest<InventoryEntity>(`${INVENTORY_API_URL}/${inventoryId}`);
}

export function useInventory(inventoryId: string | undefined) {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  return useQuery({
    queryKey: [INVENTORY_QUERY_KEY, inventoryId],
    queryFn: () => fetchInventory(inventoryId),
    enabled: !!isAuthenticated && !!inventoryId,
  });
}

export function useAllBrandInventories(brandId: string) {
  return useAllPagesEntityQuery<Brand, Inventory>({
    queryKey: [BRAND_INVENTORY_QUERY_KEY, brandId],
    objectId: brandId,
    urlPrefix: BRAND_API_URL,
    relationName: RELATIONS.BRAND_INVENTORIES,
  });
}
