import { useMemo } from "react";
import { Alert, Typography } from "antd";
import { useSearchParams } from "react-router-dom";

import { useDurationInsights } from "../../../../queries/insights/group.queries";
import { useKpiMetricName } from "../../../../queries/metrics.queries";
import { getBestAttributeValue } from "../../utils";

const { Text, Title } = Typography;

const title = "Time on Screen";

interface Props {
  name: string;
  advancedSearch: string;
}

const DurationTitle = ({ name, advancedSearch }: Props) => {
  const insights = useDurationInsights(advancedSearch);
  const kpiMetricName = useKpiMetricName();
  const [searchParams] = useSearchParams();
  const searchTerm = searchParams.get("term");

  const bestAttribute = useMemo(() => {
    if (insights.data && kpiMetricName) {
      return getBestAttributeValue(insights.data, kpiMetricName);
    }
  }, [insights.data, kpiMetricName]);

  if (insights.isPending) {
    return <Text>Loading {title}...</Text>;
  }

  if (!bestAttribute) {
    return (
      <>
        <Text>{title}</Text>
        <Alert type="info" message="No data for this metric" />
      </>
    );
  }

  const bestGroupDurationText = bestAttribute.replace("0-", "");
  const text = searchTerm
    ? `When ads matching the query are shown for at least ${bestGroupDurationText} seconds, performance improves.`
    : `When ${name} is shown for at least ${bestGroupDurationText} seconds, performance improves.`;

  return (
    <div>
      <Text>{title}</Text>
      <Title level={4} className="insight-report__title">
        {text}
      </Title>
    </div>
  );
};

export default DurationTitle;
