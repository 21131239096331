import { ThemeConfig } from "antd";

export const baseRequestOptions: RequestInit = {
  mode: "cors", // no-cors, *cors, same-origin
  cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
  headers: {
    "Content-Type": "application/json",
    Authorization: "",
  },
  credentials: "same-origin", // include, *same-origin, omit
  referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  method: "POST",
  redirect: "follow",
};

export const imagineTheme: ThemeConfig = {
  token: {
    fontFamily: "Inter",
    colorPrimary: "#5e00b5",
    colorInfo: "#2d3134",
    borderRadius: 8,
    wireframe: false,
    colorSuccess: "#1fbc59",
    colorWarning: "#c2410c",
    colorError: "#b91c1c",
    colorTextBase: "#0e0f10",
    boxShadow: "var(--wpp-box-shadow-m-brand)",
    screenLG: 1024,
    screenLGMin: 1024,
  },
  components: {
    Layout: {
      bodyBg: "var(--wpp-surface-bg-color)",
      headerBg: "var(--wpp-grey-color-000)",
      headerHeight: 56,
      headerPadding: "0 var(--default-page-padding)",
    },
    Tabs: {
      colorPrimary: "",
      colorBgContainer: "yellow",
      colorFillAlter: "#E7EAEE",
      controlItemBgHover: "rgba(14, 15, 16, 0.04)",
    },
    // TODO: change to get colors from the wpp config, will come from wpp theme json
    Alert: {
      /** alert's icon color */
      colorWarning: "#F15619",
      colorError: "#DF2F2F",
      colorInfo: "#8B919A",
      colorSuccess: "#15803D",
      /** alert's bg */
      colorWarningBg: "#F4F5F7",
      colorErrorBg: "#FBE4E4",
      colorInfoBg: "#F4F5F7",
      colorSuccessBg: "#E0FAEA",
      /** alert's border color */
      colorWarningBorder: "transparent",
      colorErrorBorder: "transparent",
      colorInfoBorder: "transparent",
      colorSuccessBorder: "transparent",
      /** alert's font color */
      colorText: "#121619",
    },
    // Required for new forms design
    Divider: {
      colorBorder: "#E7EAEE",
    },
    Button: {
      borderRadius: 6,
      colorText: "#5E00B5",
      fontWeight: 600,
    },
    Form: {
      // Error color
      colorError: "#C2410C",
    },
    Input: {
      // Input Error border
      colorTextPlaceholder: "#697077",
      colorBorder: "#8B919A",
      colorError: "#F15619",
      hoverBorderColor: "#697077",
      activeBorderColor: "#4D5358",
      activeShadow: "none",
      colorBgContainerDisabled: "#F8F9FB",
      colorTextDisabled: "#A2A9B0",
    },
    InputNumber: {
      // Input Error border
      colorTextPlaceholder: "#697077",
      colorBorder: "#8B919A",
      colorError: "#F15619",
      hoverBorderColor: "#697077",
      activeBorderColor: "#4D5358",
      activeShadow: "none",
      colorBgContainerDisabled: "#F8F9FB",
      colorTextDisabled: "#A2A9B0",
    },
    Select: {
      colorTextPlaceholder: "#697077",
      colorBorder: "#8B919A",
      colorError: "#F15619",
      colorPrimaryHover: "#697077",
      colorBgContainerDisabled: "#F8F9FB",
      colorTextDisabled: "#A2A9B0",
    },
    Collapse: {
      contentBg: "#f4f5f7",
    },
  },
};
