import { useState } from "react";

import MainPageContent from "../../components/Layout/MainPageContent";

import DashboardList from "./components/DashboardList";
import DashboardListHeader from "./components/DashboardListHeader";

export enum DashboardsSortType {
  NAME_AZ = "nameaz",
  NAME_ZA = "nameza",
}

const DashboardListPage = () => {
  const [sortType, setSortType] = useState<DashboardsSortType>(DashboardsSortType.NAME_AZ);

  return (
    <MainPageContent className="dashboard-list" header={<DashboardListHeader handleSort={setSortType} />}>
      <DashboardList sortType={sortType} />
    </MainPageContent>
  );
};

export default DashboardListPage;
