import { useEffect, useMemo, useState } from "react";
import { InfiniteData } from "@tanstack/react-query";

import {
  InsightTypeDefaultServiceOptions,
  InsightType,
  InsightTypeDefaultServiceType,
  InsightsEndpoint,
  InsightTypeToServiceName,
} from "../../../../constants/creativeInteligence";
import { InsightsData, Tag } from "../../../../types/creativeInteligence";
import { ApiResponse } from "../../../../utils/api";
import { useInfiniteInsights } from "../../../../queries/insights/insights.queries";
import { GetAttributeValuesInsightsOptions } from "../../../../queries/insights/types";
import { TagInsightsResponse } from "../../../../queries/tags/tagInsight.query";

function select(response: InfiniteData<ApiResponse<TagInsightsResponse>>) {
  return {
    items: response?.pages.reduce((acc, p) => {
      const elements = p.data.attributes ? p.data.attributes : p.data.groups;

      return [...acc, elements];
    }, [] as InsightsData[]),
    totalCount: response?.pages[0].pagination?.total ?? 0,
  };
}

export const dataExplorerKey = "dataExplorerKey";

function useTagDataExplorer(tag: Tag | undefined) {
  const advancedOptions = useMemo(() => {
    if (!tag?.tagOptions) {
      return InsightTypeDefaultServiceOptions[InsightType.AttributeValuesAnalysis];
    }

    if (!tag.tagOptions.advancedOptions) {
      return InsightTypeDefaultServiceOptions[tag.tagOptions.endpoint];
    }

    return JSON.parse(tag.tagOptions.advancedOptions) as InsightTypeDefaultServiceType;
  }, [tag]);

  const endpoint = useMemo(() => {
    if (!tag?.tagOptions) {
      return InsightsEndpoint.AttributeValues;
    }

    return InsightTypeToServiceName[tag.tagOptions.endpoint];
  }, [tag]);

  const insights = useInfiniteInsights<TagInsightsResponse, GetAttributeValuesInsightsOptions>({
    queryKey: dataExplorerKey,
    endpoint,
    extraOptions: {
      topAttributesValuesNumber: 50,
      advancedSearch: tag?.advancedSearch,
      ...advancedOptions,
    },
  });

  return {
    ...insights,
    data: insights.data ? select(insights.data) : undefined,
  };
}

export function useDataExplorer(tags: Tag[] | undefined) {
  const [tagIndex, setTagIndex] = useState(0);
  const [insights, setInsights] = useState<{ [tagid: string]: InsightsData[] }>({});
  const [totalCount, setTotalCount] = useState(0);

  const dataExplorer = useTagDataExplorer(tags?.[tagIndex]);

  const isPending = !dataExplorer.isError && (dataExplorer.isPending || tagIndex < (tags?.length ?? 0) - 1);

  useEffect(() => {
    if (!dataExplorer.isFetched || !dataExplorer.data || !tags?.[tagIndex]?.tagId || insights[tags[tagIndex].tagId]) {
      return;
    }

    setInsights((prev) => {
      if (!dataExplorer.data) {
        return prev;
      }

      return {
        ...prev,
        [tags[tagIndex].tagId]: dataExplorer.data.items,
      };
    });

    setTotalCount((prev) => prev + (dataExplorer.data?.totalCount ?? 0));

    if (tagIndex < tags.length - 1) {
      setTagIndex((prev) => prev + 1);
    }
  }, [dataExplorer, insights, tagIndex, tags]);

  const data = useMemo(() => {
    if (isPending) {
      return;
    }

    return Object.values(insights).reduce((acc, insight) => [...acc, ...insight], [] as InsightsData[]);
  }, [isPending, insights]);

  return {
    ...dataExplorer,
    isPending,
    tagIndex,
    data,
    totalCount,
  };
}
