import { useQuery } from "@tanstack/react-query";

import { type Deliverable } from "../../../types/deliverable";

import { useConfigStore } from "../../../store/config/config.store";
import { analyticsApiRequest } from "../../../utils/analytics.api";
import { useCurrentDashboard } from "../../../queries/entities/dashboard.query";
import { useKpiMetricName } from "../../../queries/metrics.queries";

import { QueryReportParams, getDeliverableQueryUrl } from "./index";

export const fetchDeliverable = async (params: QueryReportParams) => {
  const url = getDeliverableQueryUrl(params);

  return analyticsApiRequest<[Deliverable]>(url);
};

export const useDeliverablesData = (reportId: string) => {
  const currentDashboard = useCurrentDashboard();
  const storageBucket = useConfigStore((state) => state.storageBucket);
  const kpiMetricName = useKpiMetricName();

  const resourceGroupIds = currentDashboard.data?.resourceGroupIds ?? [];
  const dashboardId = currentDashboard.data?.id ?? "";

  return useQuery({
    queryKey: ["deliverables", resourceGroupIds, reportId, kpiMetricName, storageBucket, dashboardId],
    queryFn: () =>
      fetchDeliverable({
        resourceGroupIds,
        reportId,
        kpi: kpiMetricName ?? "",
        storageBucket: storageBucket ?? "",
        dashboardId,
      }),
    select: (response) => response.data?.[0],
    enabled: !!storageBucket && !!resourceGroupIds.length && !!reportId && !!kpiMetricName,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });
};
