import { useCallback } from "react";
import { Checkbox } from "antd";

interface Props {
  filters: Set<string>;
  label: string;
  value: string;
  onChange: (filters: Set<string>) => void;
}

const Filter = ({ filters, label, value, onChange }: Props) => {
  const handleChange = useCallback(() => {
    const newFilters = new Set(filters);

    if (newFilters.has(value)) {
      newFilters.delete(value);
    } else {
      newFilters.add(value);
    }

    onChange(newFilters);
  }, [filters, onChange, value]);

  return (
    <Checkbox checked={filters.has(value)} onChange={handleChange}>
      {label}
    </Checkbox>
  );
};

export default Filter;
